import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_dtable_plan_pricing_component } from './c_dtable_plan_pricing.component';
import { SharedModule } from '../../../../app/directives/shared.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [c_dtable_plan_pricing_component],
  exports: [c_dtable_plan_pricing_component],
})
export class c_dtable_plan_pricing_module { }
