import { HostListener, ViewChild, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SitesService } from '@app/services/sites.service'

@Component({
  templateUrl: "./c_dialog_app_user_copy_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_app_user_copy_component.scss"],
})
export class c_dialog_app_user_copy_component {
  @ViewChildren('actionMenus', {read: ElementRef}) actionMenus: QueryList<any>
  @ViewChild('selectList') selectList: ElementRef;

  siteAppUser: any[] = []
  selectedSite = null
  originalSiteList: any[] = [];
  filteredSiteList: any[] = []
  selectedSites: any[] = [];
  notifications: any[] = []
  selectedCount: number = 0;
  isSelectSiteOpen = false;
  maxZones = 5;
  searchText: string = '';

  constructor(
    public dialogRef: MatDialogRef<c_dialog_app_user_copy_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private sitesService: SitesService,
  ) {}

  @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: MouseEvent): void {
      if (this.selectList && this.isSelectSiteOpen) {
        const clickedInside = this.selectList.nativeElement.contains(event.target);

        if (!clickedInside) {
          this.isSelectSiteOpen = false;
        }
      }

      if (this.actionMenus) {
        const actionMenus = this.actionMenus.toArray();
        actionMenus.forEach(actionMenu => {
          this.siteAppUser.forEach(user=> {
            if (('user-'+user?.user_id) === (actionMenu.nativeElement.id+'')) {
              if (!actionMenu.nativeElement.contains(event.target)) {
                user['isShowMenu'] = false;
              }
            }
          })
        })
      }
    }

  ngOnInit() {
    this.siteAppUser = this.data.selectedAppUsers;
    this.selectedSite = this.data.selectedSite;
    this.originalSiteList = [...this.data.filteredSiteList];
    this.filteredSiteList = this.originalSiteList.map(site => ({
      ...site, 
      isSelected: false
    })).filter((site) => site.site_id !== this.selectedSite.site_id);
    this.fetchNotification()
  }

  getStatus(user_id: number, notificationType: string, method: string): string {
    const notification = this.notifications.find(item => item.user_id === user_id);
    if (notification && notification[notificationType]) {
      return notification[notificationType][method] === 1 ? 'enabled' : 'disabled';
    }
  }

  toggleSelectList() {
    this.isSelectSiteOpen = !this.isSelectSiteOpen;
  }

  toggleMenu(user){
    const targetMenu = user.isShowMenu;
    user.isShowMenu = !targetMenu;
  }

  checkIsSitesSelected(site: any) {
    this.selectedSites = this.filteredSiteList.filter(user => user.isSelected)
    this.selectedCount = this.selectedSites.length;
  }
  
  
  removeSite(index: number) {
    const removedSite = this.selectedSites[index];
    this.selectedSites.splice(index, 1);

    this.selectedCount = this.selectedSites.length;
    
    const target = this.filteredSiteList.find(site => site.site_id === removedSite.site_id);
    if (target) {
      target.isSelected = false
    }
  }

  // search
  search() {
    if (!this.searchText) {
      this.filteredSiteList = [...this.originalSiteList].filter(site => 
        site.site_id !== this.selectedSite.site_id
      );
    } else {
      const searchTermLower = this.searchText.toLowerCase();
      this.filteredSiteList = this.originalSiteList.filter(site => 
        site.name.toLowerCase().includes(searchTermLower) && site.site_id !== this.selectedSite.site_id
      );
    }
  }

  setAppUsersMorePosition(user) {
    const docElem = document.documentElement
    let elem = document.getElementById('user-'+user?.user_id);
    let more = document.getElementById('user-more-'+user?.user_id);
    let rect = elem.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right + 5
    const posY = rect.top + 5;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  computedImgError(appUser){
    return appUser.provider_picture = null;
  }

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  mouseEnter(user) {
    this.toggleMenu(user); 
    this.setAppUsersMorePosition(user)
  }

  mouseLeave(user) {
    this.toggleMenu(user)
  }

  fetchNotification() {
    this.siteAppUser.forEach(user => {
      this.sitesService.getSiteMemberNotificationConfig(
        this.selectedSite.dealer_id,
        this.selectedSite.site_id,
        user.user_id
      ).subscribe(res => {
        if (!res) return;
  
        const parsed = this.parseNotification({
          user_id: user.user_id,
          site_notifications: res.site_notifications
        });
  
        this.notifications.push(parsed);
      }, error => {
        console.error(error);
      });
    });
  }
  
  parseNotification(user) {
    const parsedNotifications = {
      user_id: user.user_id
    };
  
    user.site_notifications.forEach(notification => {
      const eventType = notification.source_event_type;
      const notiType = notification.notification_type;
      const isEnabled = notification.is_enabled;
  
      if (!parsedNotifications[eventType]) {
        parsedNotifications[eventType] = {};
      }
  
      parsedNotifications[eventType][notiType] = isEnabled;
    });
  
    return parsedNotifications;
  }
  
}