import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { c_dtable_module } from '../../dtable/c_dtable_module';
import { c_dialog_site_plan_settings_component } from "./c_dialog_site_plan_settings_component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [CommonModule, FormsModule, MatSlideToggleModule, c_dtable_module],
  declarations: [c_dialog_site_plan_settings_component],
})
export class c_dialog_site_plan_settings_module {}
