import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ApiService } from './api.service';





@Injectable()
export class TosService {
  constructor(private api: ApiService) { }

  get(dealer_id: number, filter = null): Observable<any> {
    let url = `/dealers/${dealer_id}/toss/last/text`
    if(filter) url = `/dealers/${dealer_id}/toss/last/text?type=${filter}`
    return this.api.get(url);
  }

  accept(dealer_id: number, tosId, data?): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/toss/${tosId}/accept`, data);
  }
}
