import { Component, Inject, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from '@angular/router'
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import * as _ from 'lodash';
import { c_components } from "../../../index";
import { Helper } from "../../../../../4services/2helper";

import { CommonService } from "../../../../../app/services/common.service";
import { DealerService } from '../../../../../app/services/dealer.service'
import { PagerService } from '../../../../../app/services/pager.service';

@Component({
  templateUrl: "./c_dialog_dealer_division_add_sites_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_dealer_division_add_sites_component.scss"],
})
export class c_dialog_dealer_division_add_sites_component {
  @ViewChild('searcher') searcher: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('siteFilterBox') siteFilterBox: ElementRef;
  // ------------------------------------------------------------------------

  public dealerId: number;
  public divisionId: number;
  public isLoading: boolean = false;
  public alreadyRegisteredDivisionSiteIds: any[] = [];

  params: any;
  pager: any = {};
  curPage = 1;
  totalSite = 0;
  pageOffset = 10;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;


  // filter
  originSites: any[] = [];
  filteredSites: any[] = [];
  selectedSites: any[] = [];

  timer : any;

  // paged items
  pagedItems: any[];

  searchText = '';
  isSearching = false;
  isFocusSearch = false;
  isShowFilter = false;
  isNewSiteFilter = false;
  isDeactivateFilter = false;
  isSelectList = false;
  isStatusFilter = false;
  isExistSiteFilter = false;
  isActivateGroupFilter = false;
  isActivateSiteFilter = false;
  isExistFilter = false;
  totalFilter = 1;

  _filterGroup = {
    isNewSiteFilter: false,
    isDeactivateFilter: false,
    isSelectList: false,
    isStatusFilter: false,
    isExistSiteFilter: false,
    isActivateGroupFilter: false,
    isActivateSiteFilter:  false,
    isExistFilter:  false,
  }
  filterGroup = _.cloneDeep(this._filterGroup);

  constructor(
    public commonService: CommonService,
    public dealerService: DealerService,
    public pagerService : PagerService,
    private route: ActivatedRoute,

    public dialogRef: MatDialogRef<c_dialog_dealer_division_add_sites_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: number,
    private c_components: c_components,
    private helper: Helper
  ) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if(this.searcher) {
      if (!this.searcher?.nativeElement.contains(event.target)) {
        this.isFocusSearch = false;
      }
    }
    if (this.siteFilterBox) {
      if (!this.siteFilterBox?.nativeElement.contains(event.target)) {
        this.isShowFilter = false;
        this.resetFilter();
      }
    }
  }

  private sites$w: Subscription;
  private searchedSites$w: Subscription;
  private divisions_sites$w: Subscription;
  watch() {
    this.sites$w = this.helper.sites.sites$w().subscribe((v) => this.on_sites_change(v));
    this.searchedSites$w = this.helper.sites.searchedSites$w().subscribe((v) => this.on_searched_sites_change(v));
    this.divisions_sites$w = this.helper.sites.division_sites$w().subscribe((v) => this.on_division_sites_change(v));
  }
  unwatch() {
    this.sites$w?.unsubscribe();
    this.searchedSites$w?.unsubscribe();
    this.divisions_sites$w?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true
    this.divisionId = this.data;
    await this.get_my_dealer_id()
    this.load_sites()
    this.load_division_sites()
    this.watch();
  }

  ngOnDestroy() {
    this.unwatch();
  }

  // -----------------------------------------------------------------------
  checkParams(){
    this.route.paramMap.subscribe((params) => {
      this.params = params.get('id')
    })
  }

  async get_my_dealer_id(){
    const data = await this.helper.me.get_my_dealer_id()
    this.dealerId = data
  }
  
  on_sites_change(value: any){
    if(!Array.isArray(value) || !value?.length) return this.isLoading = false
    
    const result = this.filter_out_registered_sites(value)
    this.originSites = result;
    this.filteredSites = result;

    this.doFilterSite();
    this.isLoading = false
  }

  on_searched_sites_change(value: any){
    if(!Array.isArray(value) || !value?.length) return this.isLoading = false

    this.filteredSites = this.filter_out_registered_sites(value);
    this.doFilterSite();
    this.isLoading = false;
  }

  on_division_sites_change(value: any){
    if(!Array.isArray(value) || !value?.length) return this.isLoading = false

    this.alreadyRegisteredDivisionSiteIds = value?.map(v => v.site_id);
  }

  async load_sites() {
    this.isLoading = true
    await this.helper.sites.load_sites(this.dealerId)
  }

  async load_searched_sites(search_text) {
    await this.helper.sites.load_searched_sites(search_text)
  }

  async load_division_sites() {
    await this.helper.sites.load_division_sites(this.divisionId)
  }

  filter_out_registered_sites(value){
    // 내 사이트만 불러와야 함(shared, sub dealer 사이트는 제외)
    return value.filter(v => v.dealer_id == this.dealerId && !this.alreadyRegisteredDivisionSiteIds.includes(v.site_id))
  }

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  async submit_changes() {
    const siteList = this.selectedSites.map(v => v.site_id)
    if(!siteList || !siteList.length || siteList.length > 100) return

    this.c_components.dialog.open("warning", {
      header: `Add ${siteList?.length} sites to division?`,
      contents: ``,
      submit_btn: "Add",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.add_sites_to_division(siteList)
      },
    });
  }
  
  async add_sites_to_division(siteList){
    this.isLoading = true;
    const data = { site_ids : siteList }
    try {
      await this.helper.dealer_division.add_sites_to_division(this.divisionId, data)
      await this.load_division_sites()
      this.commonService.showSuccessToast('Add users to division', 'Success')
      this.isLoading = false;
      this.close_dialog(true)
    } catch(err) {
      console.debug(err)
      this.openErrorDialog(err, 'Failed add sites to Division')
    }
  }

  disabledButton(){
    const data = this.selectedSites.map(v => v.site_id)
    if(!data || !data.length || data.length > 100) return true
    return false
  }

  // ------------------------------------------------------------------------

  checkIfSelectedSitesExceedLimit(){
    const msg = 'You can select up to 100. After that, please register again'
    const header = 'Only 100 at a time can be registered'
    if(this.selectedSites?.length > 100) this.openConfirmDialog(header, msg, 'warning', 'orange')
  }

  areAllSitesSelected(change){
    change.checked 
      ? this.filteredSites.forEach(v => { 
        if(this.pagedItems.includes(v)) v.isSelected = true
      })
      : this.filteredSites.forEach(v => {
        if(this.pagedItems.includes(v)) v.isSelected = false
      })
    this.selectedSites = this.filteredSites.filter(v => v.isSelected) ?? []
    this.checkIfSelectedSitesExceedLimit()
  }

  checkIsSitesSelected(){
    this.selectedSites = this.filteredSites.filter(v => v.isSelected) ?? []
    this.checkIfSelectedSitesExceedLimit()
  }

  // ------------------------------------------------------------------------
  // FILTER
  toggleShowFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  changeStatusFilter() {
    const fg = this.filterGroup;
    if (fg.isStatusFilter) {
      fg.isNewSiteFilter = true;
    } else {
      fg.isNewSiteFilter = false;
      fg.isExistSiteFilter = false;
    }
  }

  changeActivateStatusFilter() {
    const fg = this.filterGroup;
    if (fg.isActivateGroupFilter) {
      fg.isActivateSiteFilter = true;
    } else {
      fg.isActivateSiteFilter = false;
      fg.isDeactivateFilter = false;
    }
  }


  clearFilter() {
    this.isStatusFilter = false;
    this.isNewSiteFilter = false;
    this.isExistSiteFilter = false;

    this.isActivateGroupFilter = false;
    this.isActivateSiteFilter =false;
    this.isDeactivateFilter = false;

    this.filterGroup = _.cloneDeep(this._filterGroup);
    this.filterGroup.isActivateGroupFilter = false;
    this.filterGroup.isActivateSiteFilter = false;
    this.searchDelay();
    this.checkExistFilter();
  }

  doFilter() {
    this.setFilter();
    this.searchDelay();
    this.isShowFilter = false;
    this.checkExistFilter();
  }

  setFilter() {
    const fg = this.filterGroup;
    console.log(fg);
    this.isStatusFilter = fg.isStatusFilter;
    this.isNewSiteFilter = fg.isNewSiteFilter;
    this.isExistSiteFilter = fg.isExistSiteFilter;

    this.isActivateGroupFilter = fg.isActivateGroupFilter;
    this.isActivateSiteFilter =fg.isActivateSiteFilter;
    this.isDeactivateFilter = fg.isDeactivateFilter;;
  }

  resetFilter() {
    const fg = this.filterGroup;
    fg.isStatusFilter = this.isStatusFilter;
    fg.isNewSiteFilter = this.isNewSiteFilter;
    fg.isExistSiteFilter = this.isExistSiteFilter;

    fg.isActivateGroupFilter = this.isActivateGroupFilter;
    fg.isActivateSiteFilter = this.isActivateSiteFilter;
    fg.isDeactivateFilter = this.isDeactivateFilter;
  }

  checkExistFilter() {
    let res = false;
    let totalFilter = 0;
    if (this.isNewSiteFilter) {
      res = true; 
      totalFilter++;
    }
    if (this.isDeactivateFilter) {
      res = true; 
      totalFilter++;
    }
    if (this.isExistSiteFilter) {
      res = true; 
      totalFilter++;
    }
    if (this.isActivateGroupFilter) {
      res = true; 
      totalFilter++;
    }
    this.isExistFilter = res;
    this.totalFilter = totalFilter;
  }

  // SEARCH
  search() {
    this.searchDelay();
  }

  setSearchFocus() {
    this.isFocusSearch = true;
  }

  initSearchTxt() {
    this.isFocusSearch = false;
    this.searchText = '';
    this.searchDelay();
    if(this.searchInput) this.searchInput?.nativeElement.focus();
  }

  doFilterSite() {
    console.log('doFilterSite');

    // Site Arming status filter
    if (this.isStatusFilter) {
      if (this.isNewSiteFilter && this.isExistSiteFilter) {
        this.filteredSites = this.filteredSites.filter(e => e.is_new || !e.is_new);
      } else {
        if (this.isNewSiteFilter) {
          this.filteredSites = this.filteredSites.filter(e => e.is_new);
        }
        if (this.isExistSiteFilter) {
          this.filteredSites = this.filteredSites.filter(e => !e.is_new);
        }
        if (!this.isNewSiteFilter && !this.isExistSiteFilter) {
          this.filteredSites.filter(e => !e.is_new && e.is_new);
        }
      }
    }

    // Site Activation Status filter
    if (this.isActivateGroupFilter) {
      if (this.isActivateSiteFilter && this.isDeactivateFilter) {
        this.filteredSites = this.filteredSites.filter(e => e.is_activated === 1 || e.is_activated === 0);
      } else {
        if (this.isActivateSiteFilter) {
          this.filteredSites = this.filteredSites.filter(e => e.is_activated === 1);
        }
        if (this.isDeactivateFilter) {
          this.filteredSites = this.filteredSites.filter(e => e.is_activated === 0);
        }
        if (!this.isActivateSiteFilter && !this.isDeactivateFilter) {
          this.filteredSites = this.filteredSites.filter(e => e.is_activated !== 0 && e.is_activated !== 1);
        }
      }
    }

    this.setPage(1);
  }

  searchDelay() {
    this.filteredSites = [];
    this.pagedItems = [];

    if (this.searchText.length != 0 && this.searchText.length < 3) {
      clearTimeout(this.timer);
      return;
    } else if (this.searchText.length == 0) {
      clearTimeout(this.timer);
      this.filteredSites = this.originSites;
      this.isLoading = false;
      this.doFilterSite();
      return;
    }
    this.isLoading = true;
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.searchText.length == 0) return
      this.load_searched_sites(this.searchText)
    }, 300);
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredSites?.length, page, this.pageOffset);
    console.log(this.pager)
    this.totalSite = this.filteredSites?.length;

    // get current page of items
    this.pagedItems = this.filteredSites?.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    let lastIdx = this.pager.totalPages;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalSite) {
      this.pageOffsetEnd =  this.totalSite;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
    for (let i = 0; i < lastIdx; i ++) {
      let isExist = this.filteredSites?.slice(i*this.pageOffset, i*this.pageOffset + this.pageOffset).filter(site => site.site_id == this.params);
      if (isExist?.length != 0) {
        if (this.pager.currentPage !== i+1) {
          this.setPage(i+1);
        }
      }
    }
  }


  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}