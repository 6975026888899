import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SitesService } from '@app/services/sites.service'
import { CommonService } from "@app/services/common.service";
import { c_components } from "../../../index";
import { Helper } from "../../../../../4services/2helper";
import { Modal } from '@app/model/modal'

@Component({
  templateUrl: "./c_dialog_app_user_remove_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_app_user_remove_component.scss"],
})
export class c_dialog_app_user_remove_component {
  selectedSites: any[] = []
  appUser = null;
  dealer_id: number;

  m_warning: Modal = new Modal();

  constructor(
    public dialogRef: MatDialogRef<c_dialog_app_user_remove_component>,
    public commonService: CommonService,
    private sitesService: SitesService,
    private c_components: c_components,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  // ------------------------------------------------------------------------
  ngOnInit() {
    this.selectedSites = this.data.selectedSites;
    this.appUser = this.data.appUser ;
    this.dealer_id = this.data.dealer_id
  }

  async removeUser() {
    const promises = this.selectedSites.map(site =>
      this.sitesService.deleteSiteMember(this.dealer_id, site.site_id, this.appUser.user_id).toPromise()
    );
  
    try {
      await Promise.all(promises);
      this.commonService.showSuccessToast('Remove sites', 'Success')
      // logic
      this.close_dialog(true);
    } catch (err) {
      this.openErrorDialog(err, 'Failed remove sites')
    }
  }
  

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    const submitClass =
      color === "green" ? ["button-primary"] : ["button-danger"];
  
    this.c_components.dialog.open("warning", {
      header,
      contents: `<p>${msg}</p>`,
      submit_btn: "OK",
      submit_class: submitClass,
      icon,
      isConfirm: true,
      color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }
  

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}