import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from '../../../modals/animations';
import { Modal } from '../../../model/modal';

import { UsersService } from '../../../services/users.service';
import { DealerPlanService } from '../../../services/service-plan.service';

import { Helper } from '../../../../4services/2helper';

@Component({
  selector: 'reseller-plan-description',
  templateUrl: './reseller-plan-description.component.pug',
  styleUrls: ['../../../modals/common.scss', './reseller-plan-description.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class ResellerPlanDescriptionComponent implements OnInit {
  @Input() active: boolean;
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  me: any;
  m_warning = new Modal();
  isLoading = false;

  constructor(
    public usersService: UsersService,
    public dealerPlanService: DealerPlanService,
    private helper: Helper,
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.fetchData()
    }, err => {
      this.isLoading = false
    });
  }

  fetchData(){
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).subscribe()
  }

  closeModal() {
    this.onClose.next();
  }

  //////////////////////////////////////
  onClickShowChangingPlanModal(plan){
    this.onUpdate.next(plan)
  }

  goToPricingPlanDescription(){
    this.helper.router.navigate_to_service_plan();
    this.closeModal()
  }

  isHasStandardPlanFee(){
    const data = this.dealerPlanService.mainDealerPlanPriceStandard
    if(!data) return false
    if(typeof data === 'string') {
      return parseInt(data) > 0 ? true : false
    }
  }
}
