import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UsersService } from '@app/services/users.service';
import { SitesService } from '@app/services/sites.service';
import { CommonService } from '@app/services/common.service';
import { Helper } from 'src/4services/2helper';
import { c_components } from '../../index';

@Component({
  selector: 'c_dtable_plan_pricing',
  templateUrl: './c_dtable_plan_pricing.component.pug',
  styleUrls: ['../common.scss', './c_dtable_plan_pricing.component.scss'],
})
export class c_dtable_plan_pricing_component {
  @Input() me: any;
  @Input() currentSitePlan: number;
  @Input() nextMonthSitePlan: number;
  @Input() currentSitePricing: any;
  @Input() isFixedOpen: boolean = false;
  @Input() isShowCollapseButton: boolean = true;
//-----------------------------------------------------------------------------
  site: any;

  isLoading = false;
  isCollapseTable = true;
  isHasEditPermission = false;
  reviewResellCost = false;

  constructor(
    public usersService: UsersService,
    private sitesService: SitesService,
    public commonService: CommonService,
    private helper: Helper,
    private c_components: c_components,
  ) { }

  ngOnInit(){
    this.isHasEditPermission = this.helper.permission.has('site_plan_update');
    this.site = this.sitesService.selSite;
    this.isShowResellerCostLabel();
  }

  ngOnChanges(changes): void {
    if(changes.isFixedOpen){
      this.isCollapseTable = !this.isFixedOpen;
    }
    if(changes.me){
      this.isShowResellerCostLabel();
    }
    this.isHasEditPermission = this.helper.permission.has('site_plan_update')
  }
  // ------------------------------------------

  collapseButton(){
    this.isCollapseTable = !this.isCollapseTable;
  }

  isCurrentPlan(plan){
    return this.currentSitePlan === plan
  }
  isNextMonthPlan(plan){
    return this.currentSitePlan != this.nextMonthSitePlan && this.nextMonthSitePlan === plan
  }

  isShowResellerCostLabel(){
    this.reviewResellCost = this.site?.dealer_type === 3 && this.me?.dealer_id != this.site?.site_id
  }

  goToSitePlanPageInSettings(){
    this.helper.router.navigate_to('/settings/site-plan');
  }
  
  // ------------------------------------------
  isShowUpdateButton(plan, type){
    if(type === 'edit') {
      return this.isHasEditPermission && (this.currentSitePlan != plan)
    }
    if(type === 'keep')  {
      return this.isHasEditPermission && this.currentSitePlan === plan && this.nextMonthSitePlan != 2
    }
  }
}