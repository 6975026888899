import { Component, OnInit, OnDestroy, ViewChild, ElementRef,HostListener } from '@angular/core';
import { DealerService } from '../services/dealer.service';
import { UsersService } from '../services/users.service';
import { DealerPlanService } from '../services/service-plan.service';
import { CommonService } from '../services/common.service';
import { WebsocketService } from '../services/websocket.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Modal } from '../model/modal';
import { Helper } from "../../4services/2helper"

declare var $zoho: any;

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.pug',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit, OnDestroy {
  @ViewChild('search') search_input: ElementRef;
  @ViewChild('searcher') searcher: ElementRef;
  @ViewChild('noticeSelect') noticeSelect: ElementRef;
  @ViewChild('helpSelect') helpSelect: ElementRef;
  me: any = {};
  snd;

  dealerName = '';
  dealerPlan = '';
  dealerPlanType = 1;
  hasViewDealerPlanPermission = false

  isShowDowngradeWarning = false
  nextDealerPlan = ''

  isInit = false;

  search_width = 160;
  search_text = '';

  searched_users = [];
  searched_sites = [];
  searched_devices = [];
  timer: any;
  searchMsg = '';
  isSearching = false;

  isReadNoticeList: any = {
    'automation-rules': false,
    'video-storage-and-cloud-api': false,
    'cloud-ai': false,
  };
  isReadAllNotice = false;
  isOpenNotiMenu = false;
  isOpenHelpMenu = false;
  isShowVideos = false;

  m_profile: Modal = new Modal()
  m_warning: Modal = new Modal()
  m_dealer_plan_modal: Modal = new Modal()
  subscription: any;

  constructor(
    private dealerService: DealerService,
    public users: UsersService,
    public dealerPlanService: DealerPlanService,
    public commonService: CommonService,
    private websocket: WebsocketService,
    public deviceDetectorService: DeviceDetectorService,
    private helper: Helper
  ) {
  }

  ngOnInit() {
    const url = window.location.href;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    let dealer_id = null;
    if (params['dealer_id']) {
      dealer_id = params['dealer_id'];
      this.isInit = false;
    }
    if (this.isInit) return;
    this.users.getMe(dealer_id).subscribe(res => {
      this.me = res;
      this.dealerName = this.me.company_name;
      if (this.me.permissions && this.me.permissions.has_dealer_admin_permission) {
        this.initSalesIQ();
        // this.bootIntercom(this.commonService.getFullName(this.me), this.me.email, new Date(this.me.created).getTime() / 1000);
      } else {
        this.disableSalesIQ();
      }
      this.me.permissions && this.me.permissions.has_payment_permission
        ? this.hasViewDealerPlanPermission = true
        : this.hasViewDealerPlanPermission = false
      this.websocket.mqttInit(this.me.access_token);
      this.websocket.registerFunc('order_comment', payload => {
        setTimeout(() => this.websocketProcess(payload), 300);
      });
      this.isInit = true;
    });
    this.subscription = this.users.getMeChangeEmitter()
      .subscribe(me => {
        this.me = me;
      });
    this.dealerPlanService.dealerPlanType$s.subscribe(val => {
      const planType = val?.current_month_service_plan_type
      const nextMonthPlanType = val?.next_month_service_plan_type

      this.dealerPlanType = val?.current_month_service_plan_type
      this.dealerPlan = this.parseServicePlanTypeToPlanName(planType)

      if(planType > nextMonthPlanType) {
        this.isShowDowngradeWarning = true
        this.nextDealerPlan = this.parseServicePlanTypeToPlanName(nextMonthPlanType)
      }
    })
    this.checkAllReadNotice()
    this.snd = new Audio('assets/effects/chat.mp3');
  }
  parseServicePlanTypeToPlanName(type){
    switch(type){
      case(1) : return 'Basic'
      case(2) : return 'Professional'
      case(3) : return 'Premium'
      case(4) : return 'Reseller'
      default : return 'Basic'
    }
  }

  ngOnDestroy() {
    this.websocket.mqttRelease();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.searcher && !this.noticeSelect && !this.helpSelect) {
      return;
    }
    if (this.searcher) {
      if (!this.searcher.nativeElement.contains(event.target)) {
        // clicked outside => close dropdown list
        // this.isFocusSearch = false;
        this.focusoutSearch();
      }
    }
    if(this.noticeSelect){
      if (!this.noticeSelect.nativeElement.contains(event.target)) {
        this.isOpenNotiMenu = false;
      }
    }
    if (this.helpSelect) {
      if (!this.helpSelect.nativeElement.contains(event.target)) {
        this.isOpenHelpMenu = false;
        this.isShowVideos = false;
      }
    }
  }

  temp = null
  checkAllReadNotice(){
    const localKeyList = Object.keys(this.isReadNoticeList)
    localKeyList.forEach(key => {
      const localKey = 'CHeKTUser_' + key
      let notice = JSON.parse(localStorage.getItem(localKey));
      notice ? this.isReadNoticeList[key] = true : this.isReadNoticeList[key] = false
      this.isReadAllNotice = Object.values(this.isReadNoticeList).every(v => v === true)
    })
  }

  getDealerName() {
    let dealerName = '';
    if (this.users.me) {
      dealerName = this.users.me.company_name;
    }
    return dealerName;
  }

  computedDealerPlan() {
    return this.dealerPlanType === 1
      ? 'current-badge'
      : 'upgrade-badge'
  }


  websocketProcess(payload) {
    // if (!this.chatroomService.getStatus()) {
    //   this.chat_num++;
    //   this.snd.play();
    //   this.toastService.push({
    //     title: 'New Comment',
    //     body: payload.payload.comment,
    //     func: () => {
    //       this.openChatroom(payload.payload.order_id);
    //     }
    //   });
    // }
  }

  openSearch() {
    this.search_text = '';
    this.search_input.nativeElement.focus();
  }

  focusSearch() {
    this.search_width = 320;
  }

  focusoutSearch() {
    setTimeout(_ => {
      this.search_width = 160;
      this.search_text = '';
      this.searched_users = [];
      this.searched_sites = [];
      this.searched_devices = [];
    }, 100);
  }

  searchEvent(event) {
    this.searched_users = [];
    this.searched_sites = [];
    this.searched_devices = [];
    this.searchMsg = '';
    if (this.search_text.length < 3) {
      this.searchMsg = 'Search word is short.';
      clearTimeout(this.timer);
      return;
    } else if (this.search_text.length === 0) {
      this.searchMsg = '';
      clearTimeout(this.timer);
      return;
    }

    // this.searched_users = this.users.search(this.search_text);
    // this.searched_sites = this.sitesService.search(this.search_text);
    // this.searched_devices = this.devicesService.search(this.search_text);

    // if (this.searched_users.length > 4) {
    //   this.searched_users = this.searched_users.slice(0, 4);
    // }
    // if (this.searched_sites.length > 4) {
    //   this.searched_sites = this.searched_sites.slice(0, 4);
    // }
    // if (this.searched_devices.length > 4) {
    //   this.searched_devices = this.searched_devices.slice(0, 4);
    // }

    this.isSearching = true;
    const delay = 500;
    clearTimeout(this.timer);
    this.searchMsg = 'Searching...';
    this.timer = setTimeout(() => {
      if (this.search_text.length === 0) {
        return;
      }
      this.dealerService.search(this.me.dealer_id, this.search_text).subscribe(res => {
        this.searched_users = res.users;
        this.searched_sites = res.sites;
        this.searched_devices = res.devices;
        const count = res.users.length + res.sites.length + res.devices.length;
        if (count > 10) {
          this.searchMsg = 'You have more than 10 search results. Please enter more specific search words.';
        } else if (count === 0) {
          this.searchMsg = 'No result.';
        } else {
          this.searchMsg = '';
        }
        this.isSearching = false;
      }, err => {
        this.isSearching = false;
      });
    }, delay);
  }

  checkResult() {
    if (this.search_text.length < 2) {
      return true;
    }

    if (this.searched_users.length === 0 && this.searched_sites.length === 0 && this.searched_devices.length === 0) {
      return false;
    }
    return true;
  }

  gotoUsers(user) {
    this.focusoutSearch();
    this.helper.router.navigate_to('/settings/users', { user: user.user_id });
  }

  gotoSites(site) {
    this.focusoutSearch();
    this.helper.router.navigate_to('/customers', { id: site.site_id });
  }

  gotoDevices(device) {
    this.focusoutSearch();
    this.helper.router.navigate_to('/customers', { id: device.site_id, device_id: device.device_id });
  }

  goToDealerPlanDescription(){
    if(!this.hasViewDealerPlanPermission) return
    this.helper.router.navigate_to_service_plan();
  }

  bootIntercom(name?: string, email?: string, created_at?: number) {
    // console.log(`intercom service : ${name} ${email} ${created_at}`);
    if (name && email && created_at) {
      window['Intercom']('boot', {
        app_id: 'owfc73xh',
        name: name,
        email: email,
        created_at: created_at
      });
    }
  }

  disableSalesIQ() {
    $zoho.salesiq.ready= function(embedinfo) {
      $zoho.salesiq.floatbutton.visible('hide');
    }
    setTimeout(()=>{
      $zoho.salesiq.floatbutton.visible('hide');
    }, 100);
  }

  initSalesIQ() {
    let me = this.me;
    $zoho.salesiq.ready= ()=> {
      if(me) {
        $zoho.salesiq.visitor.name(`${me.firstname} ${me.lastname}`);
        $zoho.salesiq.visitor.email(me.email);
        setTimeout(() => {
          $zoho.salesiq.floatwindow.visible('hide');
        },100)
      }
    }
  }

  // NOTIFICATION FUNCTION
  goToNoticeDescription(feature){
    this.isOpenNotiMenu = false;
    this.m_dealer_plan_modal.data['feature'] = feature
    this.m_dealer_plan_modal.data['type'] = 'plan-description'
    this.m_dealer_plan_modal.open()

    const localKey = 'CHeKTUser_' + feature;
    let notice = JSON.parse(localStorage.getItem(localKey));
    if(!notice) {
      this.isReadNoticeList[feature] = true // 후에는 모든 알람을 읽어야 변경
      localStorage.setItem(localKey, JSON.stringify(true));
      this.isReadAllNotice = Object.values(this.isReadNoticeList).every(v => v === true)
    }
  }

  onCloseDealerPlanModal(){
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe()
  }

  // HELP FUNCTION
  toggleHelpMenu() {
    this.isOpenHelpMenu = !this.isOpenHelpMenu;
    if(!this.isOpenHelpMenu) this.isShowVideos = false;
  }

  goToDocPage() {
    const url = 'https://support.chekt.com/portal/en/kb/chekt';
    window.open(url, '_blank');
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
  }

  showSalesIQModal() {
    let me = this.me;
    if (!me) return;
    const floatwindow = $zoho.salesiq.floatwindow;
    if (floatwindow) {
      floatwindow.visible('show');
    }
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
  }

  gotoDPOverViewVideo(){
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
    window.open('https://youtu.be/E4ICy_4d3CE?si=uJH_h1okKUkZyrDr', '_blank');
  }
  gotoHowToAddSite(){
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
    window.open('https://youtu.be/EJdF27JsTow?si=pbArB5o2_BJiT7Xl', '_blank');
  }
  gotoHowToAddBridge(){
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
    window.open('https://youtu.be/UouAnzoypnA?si=rHmUOalRrB5mmFUs', '_blank');
  }
  gotoHowToAddCam(){
    this.isOpenHelpMenu = false;
    this.isShowVideos = false;
    window.open('https://youtu.be/AOP5Y2utNU4?si=URUavVI7JDwV62q-', '_blank');
  }

  confirmDialog(header= '', msg= '', icon= 'done', color= 'green', action?) {
    this.m_warning.data = {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color

    };

    this.m_warning.open();
  }
}
