import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from "../../animations";
import { Modal } from '../../../model/modal';

import { UsersService } from '../../../services/users.service';
import { DealerPlanService } from '../../../services/service-plan.service'

@Component({
  selector: 'setting-plan-precautions',
  templateUrl: './plan-precautions.component.pug',
  styleUrls: ['../../common.scss', './plan-precautions.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class PlanPrecautionsComponent implements OnInit {
  @Input() active: boolean;
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() event: EventEmitter<any> = new EventEmitter();

  me: any;
  checked = false;
  selectedPlanTitle = 'Basic'
  selectedPlan = 1;
  selectedPlanFee;

  m_warning = new Modal();
  isLoading = false;

  constructor(
    public usersService: UsersService,
    public dealerPlanService: DealerPlanService
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.parseSelectedPlan()
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.dealerPlanService.getDealerServicePlanPricing(this.me.dealer_id, this.me.type).subscribe()
    });
    this.isLoading = false
  }

  ngOnChanges(changes) {
    if (changes['active'] && this.active) {
      // this.initData();
    }
  }

  parseSelectedPlan(){
    this.selectedPlanTitle = this.dealerPlanService.planDescription[this.modal.data].title
    this.selectedPlan = this.modal.data
    this.convertSelectedPlanIdToPlanName(this.selectedPlan)
  }

  convertSelectedPlanIdToPlanName(selectedPlanId){
    switch(selectedPlanId){
      case(1) :
        this.selectedPlanFee = 'per site fee'
        break
      case(2) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.dealerPlanPriceProfessional}`
        break
      case(3) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.dealerPlanPricePremium}`
        break
      case(4) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.mainDealerPlanPriceReseller}`
        break
      default : 
        this.selectedPlanFee = 'per site fee'
        break
    }
  }

  closeModal(res = false) {
    this.onClose.next(res);
    this.modal.is_active = false
    this.active = false
    this.checked = false
    this.modal.close();
  }
  onClickConfirmPrecaution(){
    this.closeModal(true)
  }
}
