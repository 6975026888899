import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/directives/shared.module";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { c_dtable_app_users_detail_component } from "./c_dtable_app_users_detail_component"

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, MatCheckboxModule],
  declarations: [c_dtable_app_users_detail_component],
  exports: [c_dtable_app_users_detail_component],
})
export class c_dtable_app_users_detail_module {}
