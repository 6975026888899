import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeBackground } from '../../../../app/pages/animation';
import { UsersService } from '@app/services/users.service';
import { CommonService } from '@app/services/common.service';
import { DealerPlanService } from '@app/services/service-plan.service';
import { Helper } from '../../../../4services/2helper';
import { c_components } from '../..';

@Component({
  selector: 'c_card_site_plan_v2_component',
  templateUrl: './c_card_site_plan_v2.component.pug',
  styleUrls: ['../common.scss','./c_card_site_plan_v2.component.scss'],
  animations: [animeBackground]
})
export class c_card_site_plan_v2_component implements OnInit {
  @Input('planType') planType;
  @Input('site') site;
  @Input('currentDealerPlan') currentDealerPlan;
  @Input('thisMonthSitePlan') thisMonthSitePlan;
  @Input('nextMonthSitePlan') nextMonthSitePlan;
  @Output('refreshSitePlan') refreshSitePlan = new EventEmitter();

  me: any;
  isSubDealer = false;
  tab_list = [
    { label: 'Basic', value: 1, color: '#8792A2', isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault', value: 2, color: '#1E88E5', isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault Plus', value: 3, color: '#5469D4', isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault Plus AI', value: 4, color: '#283593', isReady: true, isCurrentPlan: false, isNextPlan: false}, 
  ];
  tab = this.tab_list[0];

  recommendItem = {
    sdCardStorage: null, // 1: unsupported, 2: supported
    videoEventFreeAmount: null, // 1: current, 2: unlimited
    storageDuration: null, // 1: current, 2: 1 year
    supportEventType: [], // 1: disarmed event, 2: Automation event, 3: Disarmed Event, 4: Time lapse event
    aiAnalysis: null, // 1: supported, 2: unlimited
  }
  
  servicePricing = {
    sitePlanFee: 0,
    videoVaultPlanFee: 0,
    videoVaultPlusPlanFee: 0,
    videoVaultPlusAIPlanFee: 0,
    videoEvent: {
      free_package_amount: {
        event: 0,
      },
      paid_package_amount: {
        price: 0,
        event: 0
      },
    }, 
    sum: 0,
  }

  isLoading = false;
  isStandardDealer: boolean
  constructor(
    public usersService: UsersService,
    public commonService: CommonService,
    private dealerPlanService: DealerPlanService,
    private helper: Helper,
    private c_components: c_components
  ) { }

  async ngOnInit() {
    this.initData();
    await this.fetchData();
  }

  ngOnChanges(changes): void {
    if(changes.currentValue || changes.thisMonthSitePlan || changes.nextMonthSitePlan) {
      this.initData()
      this.setCurrentSitePlanTab()
    }
  }

  //////////////////////////////

  /////////////////////////////
  // Plan Case
  isVideoEventChargePlan(planId){
    planId = parseInt(planId)
    return planId === 9 ? true : false
  }
  isAlarmBasedSitePlan(planId){
    planId = parseInt(planId)
    return planId === 15 ? true : false
  }
  isVideoVaultPlan(planId){
    planId = parseInt(planId)
    return planId === 16 ? true : false
  }
  isVideoVaultPlusPlan(planId){
    planId = parseInt(planId)
    return planId === 17 ? true : false
  }
  isVideoVaultPlusAIPlan(planId){
    planId = parseInt(planId)
    return planId === 20 ? true : false
  }
  //////////////////////////////

  initData(){
    this.tab_list = [
      { label: 'Basic', value: 1, color: '#8792A2', isReady: true, isCurrentPlan: false, isNextPlan: false},
      { label: 'Video Vault', value: 2, color: '#1E88E5', isReady: true, isCurrentPlan: false, isNextPlan: false},
      { label: 'Video Vault Plus', value: 3, color: '#5469D4', isReady: true, isCurrentPlan: false, isNextPlan: false},
      { label: 'Video Vault Plus AI', value: 4, color: '#283593', isReady: true, isCurrentPlan: false, isNextPlan: false},
    ];
  }

  async fetchData() {
    this.isLoading = true;
    this.me = await this.helper.me.get_me();
    this.isSubDealer = this.me.type === 3 ? true : false

    await this.getDealerPlanPricing()
    this.checkDealerPlan()
    this.setCurrentSitePlanTab()
    this.isLoading = false
  }

  setCurrentSitePlanTab(){
    // site tab에서만 실행하기
    if(!this.site || !this.site?.site_id) return this.moveToTab(this.tab_list[1])

    const currentSitePlan = this.tab_list.find(tab => tab.value === this.thisMonthSitePlan) ?? this.tab_list[0]
    currentSitePlan.isCurrentPlan = true
    this.tab = currentSitePlan
    this.moveToTab(currentSitePlan)
    if(this.thisMonthSitePlan != this.nextMonthSitePlan) {
      this.tab_list.find(tab => tab.value === this.nextMonthSitePlan).isNextPlan = true
    }
  }

  checkDealerPlan(){
    try {
      this.isStandardDealer = this.currentDealerPlan === 1 ? true : false

      if(this.isStandardDealer)  {
        this.thisMonthSitePlan = 1
        this.nextMonthSitePlan = 1
      }
    } catch(err) {
      this.isLoading = false
      console.debug('fetchDealerPlan:> ',err)
    }
  }

  async getDealerPlanPricing(){
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    try {
      await this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).toPromise()

      const dealerPlanPricingListForStandard = this.dealerPlanService.dealerPlanPricingListForStandard
      const dealerPlanPricingListForProfessional = this.dealerPlanService.dealerPlanPricingListForProfessional
      const dealerPlanPricingListForPremium = this.dealerPlanService.dealerPlanPricingListForPremium
      const mainDealerPlanPricingListForStandard = this.dealerPlanService.mainDealerPlanPricingListForStandard
      const mainDealerPlanPricingListForReseller = this.dealerPlanService.mainDealerPlanPricingListForReseller
      let target = null
      if(this.me.type === 2) {
        this.currentDealerPlan === 0
          ? target = mainDealerPlanPricingListForStandard
          : target = mainDealerPlanPricingListForReseller
      } else {
        if(this.currentDealerPlan === 1) target = dealerPlanPricingListForStandard
        if(this.currentDealerPlan === 2) target = dealerPlanPricingListForProfessional
        if(this.currentDealerPlan === 3) target = dealerPlanPricingListForPremium
      }
      this.parseServicePricing(target)
    } catch(err) {
      this.isLoading = false;
      console.debug(err)
      let title = 'Request Dealer Plan Info';
      this.commonService.showErrorToast(err, title);
    }
  }

  parseServicePricing(pricingList){
    for(const planId in pricingList) {
      const plan = pricingList[planId]
      if(this.isAlarmBasedSitePlan(planId)) {
        this.assignPlanPriceByPlan('sitePlanFee', plan)
      }
      if(this.isVideoVaultPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlanFee', plan)
      }
      if(this.isVideoVaultPlusPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlusPlanFee', plan)
      }
      if(this.isVideoVaultPlusAIPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlusAIPlanFee', plan)
      }
      if(this.isVideoEventChargePlan(planId)) {
        this.assignPlanPriceByPlan('videoEvent', plan)
      }
    }
  }


  assignPlanPriceByPlan(planName, data){
    if(!planName || !data) return

    const targetServicePricing = this.servicePricing

    const conditionalPriceData = data?.conditional_price
    if(conditionalPriceData instanceof Object) {
      targetServicePricing[planName] = {
        free_package_amount: {
          event: this.formatNumbersWithComma(conditionalPriceData?.free_package_amount),
        },
        paid_package_amount: {
          price: this.formatNumbersWithComma(conditionalPriceData?.fee_per_paid_package_amount ),
          event: this.formatNumbersWithComma(conditionalPriceData?.paid_package_amount)
        }
      }
    } else {
      targetServicePricing[planName] = this.formatNumbersWithComma(data?.price)

      const price = data?.price ?? 0
      this.checkUnlimitedOrIncludedPlan('price', price, targetServicePricing, planName)
    }
  }

  ///////////////////////////////////
  // TOOL
  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }
  checkUnlimitedOrIncludedPlan(pricingType, pricingData, targetData, planName){
    if(pricingType === 'conditional') {
      const paidPackageDataIsZero = !pricingData?.fee_per_paid_package_amount && !pricingData?.paid_package_amount
      if(!paidPackageDataIsZero) return

      if(this.isUnlimitedInAllDealerPlan('conditional', planName)) {
        targetData[planName].priceLabel = 'Included'
      } else {
        targetData[planName].priceLabel = 'Unlimited'
      }
    }
    if(pricingType === 'price') {
      if(!pricingData?.price) return

      if(this.isUnlimitedInAllDealerPlan('price', planName)) {
        targetData[planName] = {
          priceLabel : 'Included',
          price : pricingData?.price
        }
      } else {
        targetData[planName] = {
          priceLabel : 'Unlimited',
          price : pricingData?.price
        }
      }
    }
  }

  isUnlimitedInAllDealerPlan(pricingType, planName){
    const standardServicePricing = this.servicePricing

    if(pricingType === 'conditional') {
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.fee_per_paid_package_amount && !standardServicePricing?.[planName]?.paid_package_amount
      return standardPaidPackageDataIsZero ? true : false
    }
    if(pricingType === 'price'){
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.price && !standardServicePricing?.[planName]?.price
      return standardPaidPackageDataIsZero ? true : false
    }
  }

  parseCurrentStorageDuration(){
    if(this.currentDealerPlan === 1) return '30 Days'
    if(this.currentDealerPlan === 2) return '90 Days'
    if(this.currentDealerPlan === 3) return '1 year'
    if(this.currentDealerPlan === 4) return '1 year'
  }

  parseDealerPlanNames(){
    if(this.site.dealer_type === 2) return 'Reseller'
    return 'Professional or Premium'
  }

  // ---------------------------------------
  moveToTab(tab){
    this.tab = tab;
    const currentStorageDuration = this.parseCurrentStorageDuration() === '1 year'

    if(tab.value === 1) {
      this.recommendItem.sdCardStorage = 1
      this.recommendItem.videoEventFreeAmount = 1
      if(this.recommendItem.supportEventType.length != 1) {
        this.recommendItem.supportEventType = [ 1, 2 ]
      }
      currentStorageDuration  
        ? this.recommendItem.storageDuration = 2
        : this.recommendItem.storageDuration = 1
      this.currentDealerPlan === 1
        ? this.recommendItem.aiAnalysis = null
        : this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 2) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 1
      this.recommendItem.supportEventType = [ 1, 2, 3 ]
      this.recommendItem.storageDuration = 2
      this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 3) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 2
      this.recommendItem.supportEventType = [ 1, 2, 3, 4 ]
      this.recommendItem.storageDuration = 2
      this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 4) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 2
      this.recommendItem.supportEventType = [ 1, 2, 3, 4 ]
      this.recommendItem.storageDuration = 2
      this.recommendItem.aiAnalysis = 2
    }
  }

  setTabColorClass(t){
    if(this.tab == t) {
      if(t.value === 1) return ''
      if(t.value === 2) return 'video-vault'
      if(t.value === 3) return 'video-vault-plus'
      if(t.value === 4) return 'video-vault-plus-ai'
    } else {
      return '';
    }
  }

  setRecommendItem(type, value){
    const target = this.recommendItem[type]
    if (type === 'supportEventType') {
      const existedItem = this.recommendItem[type].findIndex(item => item === value)
      if(existedItem > -1) {
        this.recommendItem[type] = this.recommendItem[type].slice(0, existedItem)
      } else {
        this.recommendItem[type].push(value)
      }
  
      this.getRecommendedTab(type)
      return
    }

    if(target === value) {
      this.recommendItem[type] = null   // reset
    } else {
      this.recommendItem[type] = value
    }

    this.getRecommendedTab(type)
  }
  getRecommendedTab(type){
    const target = this.recommendItem[type]

    if(type === 'sdCardStorage') {
      if(target === 1) {
        this.moveToTab(this.tab_list[0])
      } else {
        this.moveToTab(this.tab_list[1])
      }
    }
    if(type === 'videoEventFreeAmount') {
      if(target === 1) {
        this.moveToTab(this.tab_list[1])
      } else {
        this.moveToTab(this.tab_list[2])
      }
    }
    if(type === 'storageDuration') {
      if(target === 1) {
        this.moveToTab(this.tab_list[0])
      } else {
        this.moveToTab(this.tab_list[1])
      }
    }
    if(type === 'supportEventType') {
      if(target.length === 0 || (target.includes(1) || target.includes(2))) {
        this.moveToTab(this.tab_list[0])
      }
      if(target.includes(3)) {
         this.moveToTab(this.tab_list[1])
      }
      if(target.includes(4)) {
         this.moveToTab(this.tab_list[2])
      }
    }
    if(type === 'aiAnalysis') {
      if(target === 1) {
        this.moveToTab(this.tab_list[2])
      } else {
        this.moveToTab(this.tab_list[3])
      }
    }
  }

  // ---------------------------------------
  // ACTION
  async onClickSitePlanSave(planId){
    const isDowngrade = this.thisMonthSitePlan > planId
    this.c_components.dialog.open("warning", {
      header: `Change Site Plan`,
      contents: `
        <p> ${isDowngrade ? 'For downgrades, the changes will take effect from next month.': 'The changes will take effect immediately.'} </p>
      `,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => await this.updateSitePlan(planId),
    })
  }

  async updateSitePlan(planId){
    try {
      await this.helper.sites.update_site_plan(this.site.site_id, { site_plan_id: planId });
      this.openWarningDialog('Success', 'Site Plan has been updated successfully.', 'done', 'green');
      this.refreshSitePlan.emit();
    } catch(err) {
      console.debug('onClickSitePlanSave :>',err)
      this.openWarningDialog('Error', 'Failed to update Site Plan.', 'error', 'red');
    }
  }
  
  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }
}
