import { Component, Inject } from "@angular/core";
import { CommonService } from "@app/services/common.service";
import { DealerService } from "@app/services/dealer.service";
import { SitesService } from "@app/services/sites.service";
import { DevicesService } from "@app/services/devices.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Helper } from "../../../../4services/2helper";
import { Devices } from "@app/model/devices";

@Component({
  templateUrl: "./c_dialog_device_reset_config_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_device_reset_config_component.scss"],
})
export class c_dialog_device_reset_config_component {
  // ------------------------------------------------------------------------

  public isLoading: boolean = false;
  private dealerId: any;
  private site: any;

  private cameraDataFromBridge = null;
  public newCamera: any = null;
  
  cameraBrandTypeList: any[] = [];
  selectedBrandType: number = null;

  apiList: string[] = [];
  selectedApi: string = null;

  isHidePassword = false;
  error = ''

  constructor(
    public dialogRef: MatDialogRef<c_dialog_device_reset_config_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: c_dialog_device_reset_config_input,
    private dealerService: DealerService,
    private devicesService: DevicesService,
    private sitesService: SitesService,
    private helper: Helper
  ) {}

  ngOnInit(){
    this.init();
  }

  private async init() {
    if(!this.data) return this.close_dialog();
    this.isLoading = true;
    
    try {
      this.site = this.sitesService.selSite
      await this.getMe();
      await this.getCameraBrandTypes();
      await this.getVDApiList();
      this.setData();
  
      this.isLoading = false;
    } catch(err) {
      this.isLoading = false;
      console.debug('init', err)
    }
  }

  private async getMe(){
    this.dealerId = await this.helper.me.get_my_dealer_id()
  }
  private async getCameraBrandTypes(){
    const res = await this.dealerService.getCameraTypes(this.dealerId).toPromise()
    res.forEach(cam => {
      cam.manufacturers = JSON.parse(cam.manufacturers);
      cam.manufacturers = cam.manufacturers.map(v => v.toLowerCase());
    })
    this.cameraBrandTypeList = res
  }
  private async getVDApiList() {
    const bridge = this.data.bridge
    const api = 'ckbapiv2/vdclient/apis';
    const body = this.createProxyBody(bridge, 'get', api);
    const dealerId = this.dealerId;
    const siteId = this.site.site_id;
    const deviceId = bridge.device_id;
    const res = await this.devicesService.proxy(dealerId, siteId, deviceId, body).toPromise();
    this.apiList = res;
  }
  setData(){
    const newCamera = this.data.newCamera
    const manufacturer = newCamera['manufacturer'] ? newCamera['manufacturer'].toLowerCase() : '';

    // set new camera data
    this.newCamera = {}
    if (newCamera['model'] === 'unknown' && this.newCamera.apiType == 'EXACQVISION') {
      this.newCamera.model = newCamera['manufacturer'];
    } else {
      this.newCamera.model = newCamera['model'];
    }
    this.newCamera.brand = newCamera['manufacturer'];
    this.newCamera.macAddress = newCamera['macAddress'];
    this.newCamera.ipAddress = newCamera['ipAddress'];
    this.newCamera.port = newCamera['port'] ? newCamera['port'] : 80;
    this.newCamera.firmwareVersion = newCamera['firmwareVersion'];
    
    // api type
    this.apiList = this.filteredBrangAPIListByCameraType();
    const apiType = this.apiList.find(v => v === newCamera.api)
    this.selectedApi = apiType ?? null;
    this.newCamera.api_type = apiType;

    // camera type: Manufactor 기준으로 설정. 없으면 General로 기본 설정
    const cameraType = this.cameraBrandTypeList?.find(v => v.manufacturers.includes(manufacturer))
    if(this.selectedBrandType === 0 && this.newCamera.brand.toLowerCase() != apiType.toLowerCase()) {
      const cameraTypeByAPIType = this.cameraBrandTypeList?.find(v => v.manufacturers.toLowerCase() === apiType.toLowerCase())
      this.selectedBrandType = cameraTypeByAPIType?.id ?? 0;
      this.newCamera.camera_type = cameraTypeByAPIType?.id ?? 0;
    } else {
      this.selectedBrandType = cameraType?.id ?? 0;
      this.newCamera.camera_type = this.selectedBrandType;
    }
    this.newCamera.isOptexCam = cameraType === 1 ? 1 : 0;
    this.newCamera.isAxisCam = cameraType === 4 ? 1 : 0;
  }

  filteredBrangAPIListByCameraType(){
    // 해당 camera type에 대해서만 api type list가 보여지도록 filtering
    const cameraType = this.newCamera.camera_type;
    const cameraBrand = this.newCamera.brand;
    
    return this.apiList.filter((v: string) => {
      if(v?.toLowerCase() === 'onvif') return true;
      if(cameraType === 0) return true;
      else if(cameraBrand?.toLowerCase() === v?.toLowerCase()) return true;
      return false;
    })
  }

  public async close_dialog() {
    this.dialogRef.close({ result: false, newCamera: null, cameraDataFromBridge: null });
  }

  // --------------------------------------------
  isChangedMacAddress(){
    const oldMacAddress = this.data.camera.mac;
    const newMacAddress = this.newCamera?.macAddress;
    return oldMacAddress != newMacAddress;
  }
  isChangedApiType(){
    const oldApiType = this.data.camera['api_type'];
    const newApiType = this.selectedApi;
    return oldApiType != newApiType;
  }
  isChangedUsername(){
    const oldUserName = this.data.camera['config']?.auth?.id;
    const newUserName = this.data.newCamera?.username;
    return oldUserName != newUserName;
  }
  isChangedPassword(){
    const oldPassword = this.data.camera['config']?.auth?.password;
    const newPassword = this.data.newCamera?.password;
    return oldPassword != newPassword;
  }
  isChangedOnlyAuthConfig(){
    const isChangedMacAddress = this.isChangedMacAddress()
    const isChangedApiType = this.isChangedApiType()
    const isChangedUsername = this.isChangedUsername()
    const isChangedPassword = this.isChangedPassword()

    if(isChangedMacAddress) return false;
    if(isChangedApiType) return false;
    if(isChangedUsername || isChangedPassword) return true;
  }

  // --------------------------------------------
  public async submit_changes() {
    this.isLoading = true;
    const result = await this.getVDInfo();
    if(!result) return;

    const newCamerBrand = this.cameraBrandTypeList.find(v => v.id === this.selectedBrandType)?.brand
    // general인 경우는 제외하기 기존의 manufactor 그대로 표시되도록 하기
    if(this.selectedBrandType && this.data.newCamera.brand?.toLowerCase() != newCamerBrand?.toLowerCase()) {
      this.newCamera.brand = newCamerBrand?.toUpperCase() || 'unknown';
    }
    this.newCamera.camera_type = this.selectedBrandType;
    this.newCamera.api_type = this.selectedApi;
    this.isLoading = false;
    this.dialogRef.close({ result: true, newCamera: this.newCamera, cameraDataFromBridge: this.cameraDataFromBridge })
  }

  async getVDInfo() {
    // 선택된 api로 인증이 성공하는지 확인
    this.error = ''
    
    this.isLoading = true;
    const ipAddress = this.data.newCamera.ipAddress;
    const apiType = this.selectedApi;
    const port = this.data.newCamera.port;
    const id = this.data.newCamera.username;
    const pw = encodeURIComponent(this.data.newCamera.password);
    const api = `ckbapiv2/vdclient?ipAddress=${ipAddress}&port=${port}&username=${id}&password=${pw}&api=${apiType}`;
    const body = this.createProxyBody(this.data.bridge, 'get', api);
    const dealerId = this.dealerId;
    const siteId = this.site.site_id;
    const deviceId = this.data.bridge.device_id;
    try {
      const res = await this.devicesService.proxy(dealerId, siteId, deviceId, body, 180, 180).toPromise()
      if (this.isEmpty(res)) {
        this.isLoading = false;
        this.error = 'Device connection error ' + ipAddress;
        return false;
      } else {
        this.cameraDataFromBridge = res;
        return true;
      }
    } catch(err) {
      this.isLoading = false;
      this.error = '';
      if (err.error) {
        if (err.error.message) {
          this.error = err.error.message;
        }
        if (err.status === 500) {
          this.error = 'Bad Request';
        }
        if (err.status === 504) {
          this.error = 'Request Timeout';
        }
        if (err.error.message) {
          if (err.error.message.indexOf('is not pingable') !== -1) {
            this.error = 'Invalid IP address';
          }
          if (err.error.message.indexOf('Connection refused') !== -1) {
            this.error = 'Invalid port number';
          }
        }
      }
      return false;
    };
  }

  // --------------------------------------------
  createProxyBody(device, method, api, param?) {
    const topic = this.makeid();
    const body = {
      headers: {
        method: method,
        path: api,
        mqttResponseTopic: `devices/${device.mac}/0/ckbapiv2/res/${topic}`
      }
    };
    if (method !== "get") {
      body["body"] = param;
    }
    return body;
  }

  makeid() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

}

interface c_dialog_device_reset_config_input {
  camera: Devices
  newCamera: any
  bridge: Devices
}