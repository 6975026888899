import { Component, HostListener, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { Helper } from '../../../../../../4services/2helper'
import { CommonService } from '../../../../../../app/services/common.service'
import { DivisionService } from '../../../../../../app/services/divisions.service'
import ims from '../../../imports'

@Component({
  selector: 'c_dtable_cloud_ai_stat_site',
  templateUrl: './c_dtable_cloud_ai_stat_site.component.pug',
  styleUrls: ['../../../common.scss', '../video-ai-stat.scss']
})
export class c_dtable_cloud_ai_stat_site_component {
  @Input() tab;
  @Input() dealerPlanData;
  @Input() amIMainDealer;
  @Input() pagedItemIsReady;
  @Input() prevSelectedMonth;
  @Input() selectedMonth;
  @Input() selSearchDealer;
  @Input() pagedItems;
  @Input() searchText;
  @Output() setDealerFilterForSiteTab: EventEmitter<any> = new EventEmitter();
  @Output() doFilterSite: EventEmitter<any> = new EventEmitter();
  @Output() sumCloudAIEvents: EventEmitter<any> = new EventEmitter();
  @Output() moveToSiteDetail: EventEmitter<any> = new EventEmitter();
  @Output() doneCallingAllData: EventEmitter<any> = new EventEmitter();
  @Output() updateDivisionList: EventEmitter<any> = new EventEmitter();
  @ViewChildren('siteActionMenus') siteActionMenus: QueryList<any>
  //-----------------------------------------------------------------------------

  isLoading = false;
  me = null;
  dealerId = null;
  dealers = [];
  amIStandardPlan = false;
  isDivisionEnable = false;

  originSites = []
  filteredSites = []

  siteCloudAIDataList = [];
  siteCloudAISkippedDataList = [];
  siteVideoEventsDataList = []

  // sort by header
  sortedColumn: string | null = null;
  isAscending = true;

  siteTableHeader = [
    { name: 'SITE NAME', value: 'name', width: "20%", tooltip: false },
    // { name: 'DIVISIONS', value: 'division', width: "10%", tooltip: false },
    { name: 'TOTAL DEVICES', value: 'device_count', width: "10%", tooltip: false },
    { name: 'AI ENABLED', value: 'videoai_enabled', width: "10%", tooltip: false },
    // { name: 'AI SKIPPED', value: 'ai_skipped_alarm', width: '10%', tooltip: true },
    { name: 'VIDEO EVENTS', value: 'video_events', width: '10%', tooltip: false },
    { name: 'AI TRUE', value: 'ai_true_alarm', width: "10%", tooltip: false },
    { name: 'AI FALSE', value: 'ai_false_alarm', width: "10%", tooltip: false },
    { name: 'AI EVENTS', value: 'ai_request', width: "10%", tooltip: false },
    { name: '', value: null, width: "5%", tooltip: false },
  ]

  constructor(
    public commonService: CommonService,
    public divisionService: DivisionService,
    private helper: Helper
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.siteActionMenus) {
      const siteActionMenus = this.siteActionMenus.toArray();
      siteActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(site=> {
          if (site.site_id === parseInt(actionMenu.nativeElement.id)) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              site.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  async ngOnInit() {
    this.isLoading = true
    await this.checkAmIMainDealer()
    await this.load_site_tab_data()
    await this.fetchDivision()
    await this.checkCanIHaveDivision()
  }

  ngOnChanges(changes): void {
    if(!changes.prevSelectedMonth) return
    if(!changes.selectedMonth) return
    if(!changes.prevSelectedMonth.currentValue) return
    if(changes.prevSelectedMonth.currentValue === changes.selectedMonth.currentValue) return
    this.load_dealer_sites_cloudAI_data()
  }

  async checkAmIMainDealer(){
    this.me = await this.helper.me.get_me()
    this.amIMainDealer = this.me?.type === 2
    this.dealerId = this.me.dealer_id
    if(this.amIMainDealer){
      const dealerNameColumn = { name: 'DEALER NAME', value: 'dealer_name', width: "15%", tooltip: false }
      this.siteTableHeader.splice(1, 0, dealerNameColumn)
    }
  }

  checkCanIHaveDivision() {
    if (!this.dealerPlanData) return
    this.amIStandardPlan = this.dealerPlanData.service_plan_type === 0
    this.isDivisionEnable = this.dealerPlanData.service_plan_type > 2;
    if (this.isDivisionEnable) {
      const divisionColumn = { name: 'DIVISIONS', value: 'division', width: "10%", tooltip: false }
      this.siteTableHeader.splice(2, 0, divisionColumn)
    }
  }

  // ------------------------------------------------------------------------
  // For Site List
  async load_site_tab_data(){
    try {
      this.isLoading = true
      await this.load_partners()
      await this.set_sites_loaded()
    }catch(err) {
      console.debug('load_site_tab_data :>',err)
      this.endForLoadData()
    }
  }

  async load_partners(){
    try {
      if(!this.amIMainDealer) return
      const value = await this.helper.partner.get_partners()
      this.dealers = value
    } catch(err) {
      console.debug('load_partners :>',err)
      this.endForLoadData()
    }
  }

  async set_sites_loaded(){
    try {
      const res = await this.helper.sites.sites_loaded()
      const sites_loaded = res?.filter(v => v.dealer_id == this.dealerId || v.dealer_type == 3)
      return sites_loaded && sites_loaded?.length
        ? await this.parseSiteDataAndCallStatsData(sites_loaded)
        : await this.load_sites()
    } catch(err) {
      console.debug('set_sites_loaded :>',err)
      this.endForLoadData()
    }
  }
  async load_sites() {
    try {
      this.isLoading = true
      const fields = 'site_id&fields=name&fields=dealer_id&fields=dealer_type'
      const res = await this.helper.sites.get_sites(null, fields)
      await this.parseSiteDataAndCallStatsData(res)
    } catch(err) {
      console.debug('load_sites', err)
      this.endForLoadData()
    }
  }
  async load_searched_sites(search_text) {
    try {
      this.isLoading = true
      const res = await this.helper.sites.get_searched_sites(search_text)
      this.parse_searched_sites(res)
    } catch(err){
      console.debug('load_searched_sites :>',err)
      this.endForLoadData()
    }
  }

  async parseSiteDataAndCallStatsData(value){
    if(!Array.isArray(value) || !value?.length) return this.endForLoadData()

    value.forEach(site => {
      if (!site.name) site.name = 'No Name'
      let dealerName = ''
      if(this.amIMainDealer) {
        dealerName = site?.external_dealer_id_with_company_name ?? 'No Name'
      } else {
        dealerName = this.me?.company_name ?? 'No Name'
      }

      site['dealer_name'] = dealerName ?? 'No Name'
      site['ai_request'] = '0'
      site['ai_true_alarm'] = '0'
      site['ai_false_alarm'] = '0'
      site['ai_skipped_alarm'] = '0'
      site['video_events'] = '0'
    });
    value = value.filter(v => v.dealer_id == this.dealerId || v.dealer_type == 3)

    this.originSites = [...value];
    this.filteredSites = [...value];

    this.setDealerFilterForSiteTab.emit(this.selSearchDealer);
    this.doFilterSite.emit({filteredSites: value, originSites: value})

    await this.checkLoadingPagedItemAndDelay()
  }
  parse_searched_sites(value){
    if(!Array.isArray(value) || !value?.length) return this.endForLoadData()
    value.forEach(site => {
      if (!site.name) site.name = 'No Name'
      let dealerName = ''
      if(this.amIMainDealer) {
        dealerName = this.dealers.find(v => v.dealer_id == site.dealer_id)?.external_dealer_id_with_company_name ?? 'No Name'
      } else {
        dealerName = this.me?.company_name ?? 'No Name'
      }

      site['dealer_name'] = dealerName ?? 'No Name'
      site['ai_request'] = '0'
      site['ai_true_alarm'] = '0'
      site['ai_false_alarm'] = '0'
      site['ai_skipped_alarm'] = '0'
      site['video_events'] = '0'
    });
    value = value.filter(v => v.dealer_id == this.dealerId || v.dealer_type == 3)

    this.filteredSites = value;
    this.load_dealer_stats_by_sites_every_100_units();
    this.parseSiteCloudAIData();
  }

  // division------------------------------------------------------------------------
  divisionList:any [] = [];
  async fetchDivision(){
    try {
      const dealerId = this.me.dealer_id
      this.divisionList = await this.divisionService.getMyDivisions(dealerId).toPromise()
      this.updateDivisionList.emit(this.divisionList)
    }
    catch(err){
      console.debug(err)
    }
  }

  findDivisionName(id): string {
  if (!id) return '';
  const result = this.divisionList.find(v => v.id == id)?.name || '';
  return result;
}


  // FOR cloud AI
  async checkLoadingPagedItemAndDelay(){
    if (!this.pagedItemIsReady) {
      setTimeout(() => this.checkLoadingPagedItemAndDelay(), 500);
    } else {
      await this.load_dealer_sites_cloudAI_data()
    }
  }
  async load_dealer_sites_cloudAI_data() {
    this.isLoading = true
    this.initSiteStats()
    await this.load_dealer_stats_by_sites_every_100_units() // 순서 바꿨는데 괜찮나?
    await this.load_dealer_sites_cloudAI_events()
  }

  initSiteStats(){
    this.filteredSites.forEach(site =>{
      site['ai_request'] = '0'
      site['ai_true_alarm'] = '0'
      site['ai_false_alarm'] = '0'
      site['ai_skipped_alarm'] = '0'
      site['video_events'] = '0'
    })
  }

  async load_dealer_stats_by_sites_every_100_units(){
    try {
      const sites_ids = this.filteredSites.map(v => v.site_id)
      let cutArrayForEvery100SiteIds = []
      for(let sliceIdx = 100; sliceIdx <= sites_ids.length + 100; sliceIdx += 100){
        let tempArray = sites_ids.slice(sliceIdx - 100, sliceIdx)
        cutArrayForEvery100SiteIds.push(tempArray)
      }

      for(let i = 0; i < cutArrayForEvery100SiteIds.length; i++){
        const sites_ids = cutArrayForEvery100SiteIds[i]
        await Promise.all([
          // this.load_dealer_sites_skipped_stats(sitesList),
        this.load_dealer_sites_video_events_stats(sites_ids)
        ])
      }
    } catch(err) {
      this.endForLoadData()
      console.debug('load_dealer_stats_by_sites_every_100_units :>>',err)
    }
  }
  async load_dealer_sites_skipped_stats(sites_ids){
    try {
      if(!sites_ids?.length) return this.endForLoadData()
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      const res = await this.helper.dealer.get_dealer_stats_by_sites(stime, etime, sites_ids)
      this.siteCloudAISkippedDataList = res
      this.parseSiteCloudAISkippedData()
    } catch(err) {
      console.debug('load_dealer_sites_skipped_stats :>',err)
      this.endForLoadData()
    }
  }
  async load_dealer_sites_video_events_stats(sites_ids){
    try {
      if(!sites_ids?.length) return this.endForLoadData()
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      const res = await this.helper.dealer.get_dealer_stats_by_sites(stime, etime, sites_ids, 2, 11)
      this.siteVideoEventsDataList = res
      this.parseSiteVideoEventsData()
    } catch(err) {
      console.debug('load_dealer_sites_video_events_stats :>',err)
      this.endForLoadData()
    }
  }

  async load_dealer_sites_cloudAI_events() {
    const month = this.selectedMonth.value
    if(this.siteCloudAIDataList?.length && !month) return this.endForLoadData()
    if(this.amIStandardPlan) return this.endForLoadData()
    try {
      const time = this.calcCurrentMonth(month)
      const res = await this.helper.dealer_cloud_ai.get_dealer_sites_cloudAI_events(time)
      const result = res?.data ?? []
      this.siteCloudAIDataList = result
      this.parseSiteCloudAIData()
    } catch(err) {
      console.debug('load_dealer_sites_cloudAI_events :>',err)
      this.endForLoadData()
    }
  }

  // ------------------------------------------------------------------------

  parseSiteCloudAISkippedData(){
    if(!this.filteredSites?.length || !this.filteredSites) return this.endForLoadData()
    for(let i = 0 ; i < this.filteredSites.length; i++){
      const site = this.filteredSites[i]
      const siteId = site?.site_id ?? ""
      if(!siteId) continue
      const skippedData = this.siteCloudAISkippedDataList[siteId]?.videoai_skipped_count
      if(!skippedData) continue

      const skippedDataNumber = parseInt(skippedData)
      site['ai_skipped_alarm'] = this.formatNumbersWithComma(skippedDataNumber)
    }
  }

  parseSiteVideoEventsData(){
    if(!this.filteredSites?.length || !this.filteredSites) return this.endForLoadData()
    for(let i = 0 ; i < this.filteredSites.length; i++){
      const site = this.filteredSites[i]
      const siteId = site?.site_id ?? ""
      if(!siteId) continue
      const videoEventsData = this.siteVideoEventsDataList[siteId]?.video_event_count
      if(!videoEventsData) continue

      const videoEventsDataNumber = parseInt(videoEventsData)
      site['video_events'] = this.formatNumbersWithComma(videoEventsDataNumber)
    }
  }


  parseSiteCloudAIData(){
    if(!this.filteredSites?.length || !this.filteredSites) return this.endForLoadData()
    for(let i = 0 ; i < this.filteredSites.length; i++){
      const site = this.filteredSites[i]
      const cloudAIData = this.siteCloudAIDataList.filter(v => v.site_id == site.site_id)
      if(!cloudAIData.length) continue

      const request = cloudAIData.reduce((acc, curr) => acc += curr?.count, 0) ?? 0
      const truAlarm = cloudAIData.find(v => v?.ai_filter_matched)?.count ?? 0
      const falseAlarm = cloudAIData.find(v => !v?.ai_filter_matched)?.count ?? 0

      site['ai_request'] = this.formatNumbersWithComma(request)
      site['ai_true_alarm'] = this.formatNumbersWithComma(truAlarm)
      site['ai_false_alarm'] = this.formatNumbersWithComma(falseAlarm)
    }

    this.endForLoadData()
  }

  endForLoadData(){
    this.doFilterSite.emit({filteredSites : this.filteredSites})
    this.sumCloudAIEvents.emit()
    this.doneCallingAllData.emit('sites')
    this.isLoading = false
  }

  // ------------------------------------------------------------------------
  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredSites;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'name':
          return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'device_count':
          return this.compare(a.device_count, b.device_count, isAsc);
        case 'dealer_name':
          return this.compare(a.dealer_name.toLowerCase(), b.dealer_name.toLowerCase(), isAsc);
        case 'division':
          return this.compare(this.findDivisionName(a.division_id) || '', this.findDivisionName(b.division_id) || '',isAsc);
        case 'videoai_enabled':
          return this.compare(a.videoai_enabled, b.videoai_enabled, isAsc);
        case 'video_events':
          return this.compare(this.formatNumberWithCommaToNumber(a.video_events), this.formatNumberWithCommaToNumber(b.video_events), isAsc);
        case 'ai_true_alarm':
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_true_alarm), this.formatNumberWithCommaToNumber(b.ai_true_alarm), isAsc);
        case 'ai_false_alarm':
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_false_alarm), this.formatNumberWithCommaToNumber(b.ai_false_alarm), isAsc);
        case 'ai_skipped_alarm':
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_skipped_alarm), this.formatNumberWithCommaToNumber(b.ai_skipped_alarm), isAsc);
        case 'ai_request':
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_request), this.formatNumberWithCommaToNumber(b.ai_request), isAsc);
        default: return 0;
      }
    });

    this.filteredSites = tmpData;
    this.doFilterSite.emit({filteredSites: tmpData});
  }

  goToSite(siteId){
    this.helper.router.navigate_to(`/customers/devices/cloud-ai`, {id: siteId});
  }

  moveToDetail(site){
    this.moveToSiteDetail.emit(site)
    this.pagedItems.forEach(v => v.isShowMenu = false)
  }

  // ------------------------------------------------------------------------

  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById(id);
    let more = document.getElementById('more-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right - 10;
    const posY = rect.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(site){
    const targetMenu = site.isShowMenu;
    site.isShowMenu = !targetMenu;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value.replace(/,/g, ""))
  }

  // time parse
  utcDate(date?){
    const target = date ? new Date(date) : new Date()
    return ims.moment(target).utc()
  }
  calcCurrentMonth(month?){
    const time = this.utcDate(month).startOf('month').format()
    return time
  }
  isCurrentMonth(){
    const selectedMonth = this.selectedMonth.value
    const stime = this.utcDate(selectedMonth).startOf('day').month()
    const currentMonth = this.utcDate().month()
    return stime === currentMonth
  }
}
