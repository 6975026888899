import { Component,  OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from "../animations";
import { Modal } from '../../model/modal';

import { UsersService } from '../../services/users.service';
import { RolesService } from '../../services/roles.service'
import { DealerPlanService } from '../../services/service-plan.service';

@Component({
  selector: 'dealer-plan-modal',
  templateUrl: './dealer-plan-modal.component.pug',
  styleUrls: ['../common.scss', './dealer-plan-modal.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class DealerPlanModalComponent implements OnInit {
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  me: any;

  m_warning = new Modal();
  isLoading = false;

  m_dealer_plan_notification: Modal = new Modal();
  m_plan_precaution: Modal = new Modal();
  m_plan_payment: Modal = new Modal();
  selectedPlan = null
  modalClass = 'modal'

  constructor(
    private usersService: UsersService,
    private rolesService: RolesService, 
    public dealerPlanService: DealerPlanService,
  ) {}

  ngOnInit() {
    this.usersService.getMe().subscribe(res => {
      this.isLoading = true
      this.me = res;

      // this.modal.data = {
      //   type: '$start component name', 
      //   feature: '$notification feature'
      //   minDealerPlanService: 1 // 1: standard, 2: professional 3: premium 4: reseller
      // }
      this.fetchDealerPlan()
      if(this.modal.data['type'] === 'plan-precuations') {
        return this.gotToPrecaution(this.modal.data['plan'])
      } 
      if(this.modal.data['type'] === 'plan-payment') {
        return this.gotToPayment(this.modal.data['plan'])
      }
      else {
        return this.goToNoticeDescription()
      }
    });
  }
  /////////////////////////////

  closeModal(val?) {
    this.onClose.next(val);
    this.modal.close();
  }

  fetchDealerPlan(){
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe()
    this.isLoading = false
  }

  // NOTIFICATION FUNCTION
  goToNoticeDescription(){
    this.modalClass = 'notification'
    this.m_dealer_plan_notification.data = {
      feature: this.modal.data['feature'],
      minDealerPlanService: this.modal.data['minDealerPlanService'] ?? 2
    }
    this.m_dealer_plan_notification.is_active = true
  }

  gotToPrecaution(plan){
    this.modalClass = ''

    this.rolesService.getRoles(this.me.dealer_id).subscribe(res => {
      const adminRoleId = res.find(v => v.is_admin_role === 1)?.role_id

      const hasPlanUpgradePermission = this.me.role_id === adminRoleId 
      if(!hasPlanUpgradePermission) {
        const header ='Please ask the administrator'
        const msg = `<br/>Plan upgrades are only available for administrators.<br/>Ask your administrator for an upgrade.`
        return this.confirmDialog(header, msg, 'warning', 'orange', true)
      }
      this.selectedPlan = plan
      this.m_plan_precaution['data'] = this.selectedPlan
      this.m_plan_precaution.open()
    }, err => {
      this.isLoading = false
    })
  }

  gotToPayment(plan){
    this.modalClass = 'payment'
    this.m_plan_payment.is_active = true
    this.m_plan_payment['data'] = plan
    this.m_plan_payment.open()
  }
  
  onClosePlanNotice(res){
    const check = res[0]
    const plan = res[1]
    const isHiding = res[2]

    // CASE 1 : DON'T SHOW AGAIN THIS NOTIFICATION
    if(isHiding) {
      const localKey = 'CHeKTUser_standard_dealer_notification';
      localStorage.setItem(localKey, JSON.stringify(true))
    }

    // CASE 2 : OPEN PRECAUTION FOR DEALER PLAN
    this.selectedPlan = plan
    if(check){
      this.modalClass = ''
      this.m_plan_precaution.data = plan
      this.m_plan_precaution.is_active = true
      return
    } else {
      this.closeModal()
    }
  }
  onClosePlanPrecaution(checked){
    this.m_plan_precaution.is_active = false
    if(checked) {
      this.modalClass = 'payment'
      this.m_plan_payment.is_active = true
      this.m_plan_payment['data'] = this.selectedPlan
      this.m_plan_payment.open()
    } else {
      this.closeModal()
    }
  }
  onClosePlan(selectedPlan){
    if(!selectedPlan) return this.closeModal()
    this.isLoading = true
    const { title, contents } = this.setClosePlanModalContents()
    this.confirmDialog(title, contents, 'done', 'green', true);
    this.fetchDealerPlan()
    this.isLoading = false
  }

  setClosePlanModalContents(){
    const beforePlan = this.modal.data['currentPlan']
    const selectedPlan = this.selectedPlan
    if(beforePlan > selectedPlan) {
      // downgrade
      const title = `Account scheduled for downgrade.`
      const contents = `
        <p>
        When downgrading dealer plans, this change will take effect on the first day of the next month.
        </p>
      `
      return { title, contents } 
    }
    if(beforePlan < selectedPlan) {
      // upgrade
      const title = `Now your plan is ${this.dealerPlanService.planDescription[selectedPlan].title}`
      const contents = `
        <p>
        Your account has been upgraded to ${this.dealerPlanService.planDescription[selectedPlan].title}.
        </p>
      `
      return { title, contents } 
    }
  }

  parseServicePlanTypeToPlanName(type){
    switch(type){
      case(1) : return 'Basic'
      case(2) : return 'Professional'
      case(3) : return 'Premium'
      case(4) : return 'Reseller'
      default : return 'Basic'
    }
  }

  /////////////////////////////
  confirmDialog(header= '', msg= '', icon= 'done',color='green', func = null) {
    this.m_warning.data = {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color

    };

    this.m_warning.data['submit_func'] = () => {
      if(func) this.closeModal(true)
    };

    this.m_warning.open();
  }

  errorDialog(err , title) {
    let msg = 'failed.';
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.confirmDialog(title, msg, 'warning', 'orange');
    }, 200);
  }
}