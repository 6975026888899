<div style="overflow-x:auto;overflow-y: hidden;height: 100%;background: #fff;"
*ngIf="hasAccessPermission && isBrowserCheck && !isIpBlocked">
  <app-top-nav *ngIf="me"></app-top-nav>

  <!-- <div class="col"> -->
  <div style="width: 100%;height:calc(100% - 50px); display: flex; flex-direction: row;">
    <app-side-nav></app-side-nav>
    <div class="body" style="height:100%;overflow-x:auto;position: relative;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div style="overflow-x:auto"
*ngIf="!hasAccessPermission && isBrowserCheck && !isIpBlocked" class="loading-background">
<div class="absolute-center-xy">
    <div class="loading-text" style="color: #fff;font-size:24px;">
      <span>Access to your CHeKT Dealer Portal has been disabled.  Please contact CHeKT support for additional information.</span><br>
      <span>{{ownerEmail}}</span>
    </div>
    <div class="loading-text" style="text-align: right;margin-top:32px;">
      <a href="{{apiService.account_url}}">Go to account page</a>
    </div>
  </div>

</div>
<toast time="3000"></toast>

<div *ngIf="is_loading" [style.opacity]="opacity" class="loading-background">
  <div class="loading-info-container absolute-center-xy">
    <div class="loading-text" style="color: #fff;">{{loading_status}}</div>
    <div class="loading-spinner-container">
      <div class="module-load-spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isBrowserCheck && !isIpBlocked" class="loading-background" style="overflow-x:auto">
  <div style="width:100%;height:100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div class="loading-text" style="color: #fff;font-size:24px;">For the best user experience, this site requires the Chome or Safari Internet Browser to work properly.</div>
    <br>
    <div>
      <a href="https://www.google.com/chrome/" style="color:#2383e2;" target="_blank">
        Chrome download link
      </a>
    </div>
    <div>
      <a href="https://www.apple.com/safari/" style="color:#2383e2;" target="_blank">
        Safari download link
      </a>
    </div>
  </div>
</div>

<div *ngIf="isIpBlocked" class="loading-background" style="overflow-x:auto">
  <div style="width:100%;height:100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div class="loading-text" style="color: #fff;font-size:24px;">
      This location has been IP blocked</div>
  </div>
</div>

<app-notice [modal]="m_notice" [tos]="tos" (event)="modalEvent('notice')"  *ngIf="me"></app-notice>
<dealer-plan-modal *ngIf="m_dealer_plan_modal.is_active" [modal]="m_dealer_plan_modal" (onClose)="onCloseDealerPlanModal($event)"></dealer-plan-modal>
<dealer-plan-for-standard-modal *ngIf="m_dealer_plan_modal_v2.is_active" [modal]="m_dealer_plan_modal_v2" (onClose)="onCloseDealerPlanModal($event)" (onUpdate)="showPlanDescriptionModal($event)"></dealer-plan-for-standard-modal>

<subscribers_component></subscribers_component>
