import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { EventsService } from '@app/services/events.service';
import { DomSanitizer } from '@angular/platform-browser';

import ims from '../../../../../imports'
import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'log_box_disarmed_event',
  templateUrl: './disarmed_event.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss'],
})
export class log_box_disarmed_event_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  isBuildReportMode = false;

  snapshotPermission = false;
  isBrokenImage = false;
  isShowPlayBtn = false;
  isShowVideo = false;
  isLoadingVideo = false;

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private eventsService: EventsService,
    private sanitizer: DomSanitizer,
    private helper: Helper,
    private cdr: ChangeDetectorRef
  ) {}
  
  private isClearSelectedLogs$w: Subscription;
  private snapshotPermission$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.snapshotPermission$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }

  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }

  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON

  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 200);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 200);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }
  
  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  // --------------------------------
  // IMAGE / VIDEO
  computedBackground(){
    if(this.log.isLoading) return 'loading-background'
    if(!this.snapshotPermission) return 'no-permission'

    if(!this.isShowVideo) {
      if(this.canIShowImage() === 'need to upgrade plan') return 'no-permission'
      if(this.canIShowImage() === 'expired') return 'no-permission'
      if(this.canIShowImage() === 'privacy mode') return 'no-permission'
      if(this.canIShowImage() === 'broken image') return 'no-permission'
      if(this.canIShowImage() === 'image') return 'full-height'
      if(this.canIShowImage() === 'default') return 'disarmed-event'
    } else {
      if(this.canIShowVideo() === 'need to upgrade plan') return 'no-permission'
      if(this.canIShowVideo() === 'expired') return 'no-permission'
      if(this.canIShowVideo() === 'privacy mode') return 'no-permission'
      if(this.canIShowVideo() === 'broken video') return 'no-permission'
      if(this.canIShowVideo() === 'video') return 'full-height'
      if(this.canIShowVideo() === 'default') return 'disarmed-event'
    }
  }

  canIShowImage(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.isBrokenImage) return 'broken image'
    if(this.log.imageUrl) return 'image'
    return 'default'
  } 
  canIShowVideo(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.log.videoUrl && !this.log.safetyVideoUrl) return 'broken video'
    if(this.log.safetyVideoUrl) return 'video'
    return 'default'
  } 

  toggleToVideo(e){
    e.stopPropagation()
    if(!this.log.videoUrl) return

    this.isShowVideo = !this.isShowVideo
    if(this.isShowVideo) {
      this.log.isLoading = true
      this.getMP4Video(this.log, this.log.videoUrl)
    }
  }

  mouseOverImage(e){
    e.stopPropagation();
    if(!this.log.videoUrl) return
    this.isShowPlayBtn = true
  }
  mouseLeaveImage(e){
    e.stopPropagation();
    if(!this.log.videoUrl) return
    this.isShowPlayBtn = false
  }

  async getMP4Video(log, url) {
    let urlCreator = window.URL;
    try {
      const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
      console.log(res, this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res)))
      log['safetyVideoUrl'] = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res));
      log.isLoading = false 
      this.cdr.detectChanges();  // 변경감지 수행
    } catch(err) {
      log.isLoading = false 
    }
  }

  // ----------------------------------
  // For Tag
  isAutomationRule(){
    const description = this.log.description.toLowerCase()
    const isAutomationRule = description.includes('automation') || this.log.isAutomation
    return isAutomationRule
  }
}
