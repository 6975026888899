import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';

import { c_dialog_app_user_delete_component } from "./c_dialog_app_user_delete_component";

@NgModule({
  imports: [CommonModule, FormsModule, MatCheckboxModule],
  declarations: [c_dialog_app_user_delete_component],
})
export class c_dialog_app_user_delete_module {}
