import { Component, Input } from '@angular/core';
import { SitesService } from '@app/services/sites.service';
import { EventsService } from '@app/services/events.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { DomSanitizer } from '@angular/platform-browser';

import ims from '../../imports'
import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'c_card_event_viewer_time_line_component',
  templateUrl: './c_card_event_viewer_time_line.component.pug',
  styleUrls: ['../common.scss','./c_card_event_viewer_time_line.component.scss'],
})
export class c_card_event_viewer_time_line_component {
  @Input() logs: any[]
  @Input() isMode: string; // 'print' | 'create' | 'view'

  snapshotPermission: boolean = false;

  site = <any>{};
  isLoading = false
  selectedLogs: any[];

  constructor(
    private sitesService: SitesService,
    private eventsService: EventsService,
    private eventViewerService: EventViewerService,
    private helper: Helper,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(){
    this.site = this.sitesService.selSite;
    ims.moment.tz.setDefault(this.site.timezone);
  }

  ngOnChanges(changes): void {
    if(!changes.logs) return
    if(!changes.logs.currentValue) return
    this.isLoading = true
    this.parsedSelectedLogs(this.logs)
  }

  async parsedSelectedLogs(value){
    if(!value || !Array.isArray(value) || !value?.length) return this.isLoading = false
    this.selectedLogs = value

    this.selectedLogs.forEach(async log => {
      let category = this.computedCategory(log)
      if(category === 'sensor') {
        this.isVideoAI(log) ? category = 'videoai' : 'sensor'
      }
      log.category = category

      if(!log['notes']) log['notes'] = '' // for create mode
      log['logIcon'] = { type: '', name: ''}
      log.isLoading = true
      this.setIconName(log)
    })
    this.isLoading = false
    await this.setLogSnapshots()
  }
  computedCategory(log){
    if(!log?.category || typeof log.category != 'string') return 'default'
    const category = log.category
    return category.toLowerCase()
  }
  isVideoAI(log){
    if(!log) return
    if(this.computedCategory(log) != 'video event') return false
    const trigger = log.trigger.toLowerCase()
    return trigger === 'videoai'
  }
  isAutomationRule(log){
    const description = log?.description.toLowerCase() ?? ''
    const isAutomationRule = description.includes('automation') || log?.isAutomation
    return isAutomationRule
  }

  // -------------------------------------------------------
  // FOR IMAGE
  async setLogSnapshots(){
    const dealerId = await this.helper.me.get_my_dealer_id()
    const siteId = this.sitesService.selSite.site_id

    try {
      const tmpEventIds = [];
      let eventIds = '';
      this.selectedLogs.forEach(log=>{
        if(!log?.event_id) return log.isLoading = false
        if(
          !this.isVideoEvent(log) && 
          !this.isAutomationEvent(log) && 
          !this.isTimelapse(log) && 
          !this.isPlaybackEvent(log)
        ) return log.isLoading = false
        tmpEventIds.push(log.event_id);
      })
      eventIds = tmpEventIds.join(',');
  
      const res = await this.eventsService.getEventsReportsInfoSnapshots(dealerId, siteId, eventIds, '3')?.toPromise()
      if(res?.has_download_permission || res?.has_share_download_link_permission) this.eventViewerService.setSnapshotPermission(true)
      if(!res?.events) return

      for(const eventId in res.events) {
        const event = res.events[eventId]
        const log = this.selectedLogs.find(v => v.event_id === eventId)
        if(!log) {
          log.isLoading = false
          continue 
        }

        const targetImg = event[0]
        if(event.length) {
          log['isPrivacy'] = !!targetImg.privacy_enabled
          log['is_expired'] = targetImg.is_expired
          await this.getImageUrl(log, targetImg.url)
        } else {
          await this.getThumbnail(dealerId, siteId, eventId, log)
        }

        log.isLoading = false 
      }
    } catch(err) {
      this.selectedLogs.forEach(log => log.isLoading = false)
      console.debug('setLogSnapshots in time_line',err)
    }
  }

  async getImageUrl(log, url){
    if(!url) return log.isLoading = false 
    let urlCreator = window.URL;
    try {
      const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
      log['imageUrl'] = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res));
      log.isLoading = false 
    } catch(err) {
      console.debug('getImageUrl',err)
      log.isLoading = false 
    }
  }

  async getThumbnail(dealerId, siteId, eventId, log){
    // 이것을 먼저 포함하고 호출하면 최대 30초의 로딩이 걸리는 경우도 생김
    try {
      const thumbnailImgs = await this.eventsService.getEventsReportsInfoSnapshots(dealerId, siteId, eventId, '100')?.toPromise()
      if(!thumbnailImgs?.events) return
  
      const thumbnailEvent = thumbnailImgs.events[eventId]
      const thumbnailLog = log.event_id === eventId
      if(!thumbnailEvent) return
      if(!thumbnailLog) return
      
      const thumbnailImg = thumbnailEvent.find(v => v.is_thumbnail)
      if(thumbnailImg) await this.getImageUrl(log, thumbnailImg?.url)
    } catch(err) {
      console.debug('getThumbnail',err)
      log.isLoading = false
    }
  }


  // -------------------------------------------------------
  // STYLE
  // 1. COLOR
  capitalizeFirstLetter(string) {
    if (string.length === 0) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  computedClassNameForBackgroundColor(log){
    const category = log.category

    if(this.isAutomationRule(log)) return 'automation-background'
    if(category === 'video event') return `alarm-video-background`
    if(category === 'arming') return this.setClassNameForArmingBackgroundColor(log)
    if(category === 'audio') return `${category}-background`
    if(category === 'playback event') return `playback-background`
    if(category === 'relay') return `${category}-background`
    if(category === 'timelapse event') return `timelapse-background`
    if(category === 'verification') return `${category}-background`
    if(category === 'videoai') return `video-ai-background`
    if(category === 'disarmed event') return `disarmed-event-background`
    return `default-background`
  }
  setClassNameForArmingBackgroundColor(log){
    const actionName = this.computedArmingActionName(log.action)
    
    if(!this.checkArmingStatus(log)) return 'default-background'
    return `${actionName}-background`
  }

  computedIconColor(log){
    const category = log.category

    if(this.isAutomationRule(log)) return '#FFA07A'
    if(category === 'video event') return '#FF6B6B'
    if(category === 'arming') return this.armingIconColor(log)
    if(category === 'audio') return '#C34A36'
    if(category === 'playback event') return '#276749'
    if(category === 'relay') return '#FFC75F'
    if(category === 'timelapse event') return '#276749'
    if(category === 'verification') return '#4D96FF'
    if(category === 'sensor') return '#8B8B8B'
    if(category === 'videoai') return `#6578D8`
    if(category === 'disarmed event') return '#5FC0FD'
    return '#8B8B8B'
  }
  armingIconColor(log){
    const action = log.action
    const parsedAction = action.split(' ')[0]
    const lowerCaseAction = parsedAction.toLocaleLowerCase()

    if(!this.checkArmingStatus(log)) return '#8B8B8B'
    if(lowerCaseAction === 'armed') return '#FF7F16'
    if(lowerCaseAction === 'armed away') return '#FF7F16'
    if(lowerCaseAction === 'armed stay') return '#FF7F16'
    if(lowerCaseAction.includes('armed stay')) return '#FF7F16'
    return '#1EA675' // 그 외는 disarmed
  }



  // ---------------------------------------
  // ICONS 
  computedIconSize(log){
    if(log.logIcon.name === 'metabolism') return '22'
    if(log.logIcon.name === 'http') return '22'
    return '16'
  }

  styleClassForGuardTourIcon(log){
    return log.category.toLowerCase() === 'guard tour' && (this.isMode != 'print')
  }

  setIconName(log){
    const category = log.category

    if(category === 'bridge') return this.setBridgeIcon(log)
    if(!log.logIcon.name) this.setIconsByReportCategory(log)
    if(!log.logIcon.name) this.setOtherCategory(log)
  }

  // 1. REPROT CATEGORY - INIT
  setIconsByReportCategory(log){
    const category = log.category

    if(this.isAutomationRule(log)) return log.logIcon = { type: 'comp', name: 'autopay'}
    if(category === 'video event') return log.logIcon = { type: 'material', name: 'videocam'}
    if(category === 'arming') return this.setArmingIcon(log)
    if(category === 'audio') return log.logIcon = { type: 'material', name: 'mic'}
    if(category === 'playback event') return log.logIcon = { type: 'material', name: 'replay'}
    if(category === 'relay') return this.setRelayIcon(log)
    if(category === 'timelapse event') return log.logIcon = { type: 'material', name: 'timelapse'}
    // if(category === 'verification') return log.logIcon = { type: 'material', name: 'verified_user'}
    if(category === 'sensor') return this.setSensorIcon(log)
    // if(category === 'videoai') return log.logIcon = { type: 'material', name: 'blur_on'}
    if(category === 'disarmed event') return log.logIcon = { type: 'comp', name: 'disarmed_event'}
  }

  // ------------------------------
  // ARMING
  // 타입별로 분류해보자
  // - 1. arming status) arming 상태를 표시하는 항목 : armed / disarmed (arming status)
  // - 2. entry / exit delay ) arming 상태를 지연시키는 항목 (arming delay)
  // - 3. others ) arming 상태를 '결정하는 시스템을 변경하는' 항목 : arming settings -
  setArmingIcon(log){
    if(this.checkArmingStatus(log)) return this.setArmingStatusIcon(log)
    if(this.checkArmingDelay(log)) return 

    // other : arming settings - voltage arming / cloud arming
    log.logIcon = { type: 'material', name: 'settings'}
  }
  
  // for arming status
  setArmingStatusIcon(log){
    const iconName = this.computedArmingActionName(log.action)
    log.logIcon = { type: 'comp', name: iconName }
  }
  computedArmingActionName(action: string){
    const parsedAction = action.split(' ')[0]
    return parsedAction.toLowerCase()
  }
  checkArmingStatus(log){
    const status = log.status_text.toLowerCase()
    if(status === 'armed') return true
    if(status === 'disarmed') return true
    if(status === 'armed away') return true
    if(status === 'armed stay') return true
    if(status.includes('armed stay')) return true
    return false
  }

  // entry / exit delay
  checkArmingDelay(log){
    const status = log.status_text.toLocaleLowerCase()
    if(status.includes('end')) {
      log.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    if(status.includes('start')) {
      log.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    return false
  }

  // ------------------------------
  // RELAY
  setRelayIcon(log){
    const meta = log.meta
    if(!meta || typeof meta != 'string') return 'relay'
    const metaData = JSON.parse(meta)
    const iconName = metaData?.info?.relay?.icon ?? 'relay_action'
    return log.logIcon = { type: 'comp', name: iconName}
  }

  // ------------------------------
  // SENSOR
  setSensorIcon(log){
    const category = log.category.toLowerCase()
    const trigger = log.trigger.toLowerCase()

    if(category != 'sensor') return { type: '', name: ''}
    if(trigger.includes('di')) return log.logIcon = { type: 'material', name: 'settings_input_component'}
    if(trigger.includes('oe')) return log.logIcon = { type: 'material', name: 'psychology'}
    if(trigger.includes('le')) return log.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('local')) return log.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('camera')) return log.logIcon = { type: 'comp', name: 'bypass'}
  }
  // BRIDGE
  setBridgeIcon(log){
    const description = log.description.toLowerCase()

    if(description.includes('automation')) return log.logIcon = { type: 'comp', name: 'autopay'}
    return log.logIcon = { type: 'comp', name: 'bridge'}
  }

  // -----------------------------
  // OTHERS
  setOtherCategory(log){
    const category = log.category.toLowerCase()

    switch(category){
      case('alarm'): return log.logIcon = { type: 'material', name: 'notifications_active'}
      // case('end user live view'): return log.logIcon = { type: 'material', name: 'remember_me'}
      case('guard tour'): return log.logIcon = { type: 'comp', name: 'metabolism'}
      case('live video'): return log.logIcon = { type: 'material', name: 'smart_display'}
      case('monitoring portal'): return log.logIcon = { type: 'material', name: 'live_tv'}
      case('boot'): return log.logIcon = { type: 'material', name: 'power_settings_new'}
      // case('periodic test'): return log.logIcon = { type: 'material', name: 'manage_history'}
      // case('playback event'): return log.logIcon = { type: 'material', name: 'replay'}
      case('privacy mode access'): return log.logIcon = { type: 'material', name: 'passkey'}
      case('share download link'): return log.logIcon = { type: 'material', name: 'download'}
      case('share response link'): return log.logIcon = { type: 'material', name: 'reply'}
      case('site procedure action'): return log.logIcon = { type: 'material', name: 'interests'}
      case('site procedure http request'): return log.logIcon = { type: 'material', name: 'http'}
      case('trouble'): return log.logIcon = { type: 'material', name: 'warning'}
      case('timelapse event'): return log.logIcon = { type: 'material', name: 'timelapse'}
      // case('walk test'): return log.logIcon = { type: 'material', name: 'directions_walk'}
      // default : return log.logIcon = { type: '', name: ''}
    }
  }

  // -------------------------------------------
  // TEXTAREA
  isHasImage(log) {
    if(!log) return false;
    return log?.imageUrl || log?.is_expired || log?.isPrivacy
  }

  // -------------------------------------------
  private isVideoEvent(log){
    const category = log.category.toLowerCase()
    return category === 'video event'
  }
  private isPlaybackEvent(log){
    const category = log.category.toLowerCase()
    return category === 'playback event'
  }
  private isTimelapse(log){
    const category = log.category.toLowerCase()
    return category === 'timelapse event' ? true : false
  }
  private isAutomationEvent(log){
    return log.isAutomation
  }
}
