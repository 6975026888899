import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../app/directives/shared.module'
import { c_card_site_plan_v3_component } from './c_card_site_plan_v3.component';

@NgModule({
  imports: [ CommonModule, FormsModule, SharedModule ],
  declarations: [ 
    c_card_site_plan_v3_component, 
  ],
  exports: [c_card_site_plan_v3_component],
})
export class c_card_site_plan_v3_module { }
