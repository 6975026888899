import { Component, HostListener, Input, Output, ViewChild, ViewChildren, ElementRef, QueryList, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { PagerService } from '@app/services/pager.service';
import { CommonService } from '@app/services/common.service';
import { SitesService } from '@app/services/sites.service';
import { Helper } from '../../../../../4services/2helper'
import { c_components } from 'src/3ui/2components';
import ims from '../../imports'

@Component({
  selector: 'c_dtable_cloud_vms_manager_for_site',
  templateUrl: './c_dtable_cloud_vms_manager_for_site.component.pug',
  styleUrls: ['../../common.scss', '../cloud_vms_manager.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class c_dtable_cloud_vms_manager_for_site_component {
  @Input() siteList: any;
  @Input() dealers: any;
  @Input() isLoading: boolean;
  @Output() refresh = new EventEmitter<boolean>();
  @ViewChild('dealerSelect') dealerSelect: ElementRef;
  @ViewChild('statusSelect') statusSelect: ElementRef;
  @ViewChild('planSelect') planSelect: ElementRef;
  @ViewChild('selectDropdown') selectDropdown: ElementRef;
  @ViewChildren('siteActionMenus') siteActionMenus: QueryList<any>
//-----------------------------------------------------------------------------

  isHasViewPermission = false;
  isHasEditPermission = false;
  isSubDealer = false;

  tableHeader = [
    // downgrade 로직 때문에 bulk update는 일단 숨겨두자
    { name: '', value: null, width: "5%", isCheckbox: true, tooltip: false },
    { name: 'SITE NAME', value: 'site_name', width: "20%", isCheckbox: false, tooltip: false },
    { name: 'DEALER NAME', value: 'dealer_name', width: "15%", isCheckbox: false, tooltip: false },
    { name: 'STATUS', value: 'status', width: "15%", isCheckbox: false, tooltip: true },
    { name: 'REQUESTED PLAN', value: null, width: "10%", isCheckbox: false, tooltip: false },
    { name: 'CURRENT SITE PLAN', value: 'site_plan', width: "10%", isCheckbox: false, tooltip: false },
    { name: 'NOTE', value: null, width: "20%", isCheckbox: false, tooltip: false },
    { name: '', value: null, width: "5%", isCheckbox: false, tooltip: false },
  ]

  filteredSiteList = [];
  originalSiteList = [];
  pagedItems = [];

  isAllSelected = false;
  selectedSiteList = [];

  isFocusSearch = false;
  searchText = '';

  basicPlanCount = 0
  videoVaultPlanCount = 0
  videoVaultPlusPlanCount = 0
  videoVaultPlusAICount = 0

  // select box
  isUnfoldPlanDropdown = false;
  sitePlanList = [
    { label: 'Basic', value: 1 },
    { label: 'Video Vault', value: 2 },
    { label: 'Video Vault Plus', value: 3 },
    { label: 'Video Vault Plus AI', value: 4 },
  ]
  selectedSitePlan = this.sitePlanList[0];

  // filter
  // 1. dealer
  isShowDealerSelect = false
  dealerListForFiltering = []
  searchDealerList = [];
  searchDealerTxt = ''
  selectedDealer = null
  originalSelectedDealer = null

  // 2. Status
  isShowStatusSelect = false
  statusList = [
    { label: 'Active', isSelected: true, value: 1 }, 
    { label: 'Requested', isSelected: true, value: 2 }, 
    { label: 'Inactive', isSelected: true, value: 3 }, 
    { label: 'Unavailable', isSelected: true, value: 4 },
  ]
  selectedStatusList = this.statusList.filter(v=>v.isSelected)
  originalStatusList = this.selectedStatusList
  selectedStatusListLabels = 'All'


  // 3. Plan
  isShowPlanSelect = false
  planList = [
    { label: 'Basic', isSelected: true, value: 1 }, 
    { label: 'Video Vault', isSelected: true, value: 2 }, 
    { label: 'Video Vault Plus', isSelected: true, value: 3 }, 
    { label: 'Video Vault Plus AI', isSelected: true, value: 4 },
  ]
  selectedPlanList = this.planList.filter(v=>v.isSelected)
  originalPlanList = this.selectedPlanList
  selectedPlanListLabels = 'All'


  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;

  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    private sitesService: SitesService,
    public c_components: c_components,
    public helper: Helper,
  ) { }

  async ngOnInit() {
    this.originalSiteList = ims._.cloneDeep(this.siteList);
    this.filteredSiteList = ims._.cloneDeep(this.siteList);
    this.selectedSitePlan = this.sitePlanList[0];
    this.isHasViewPermission = this.helper.permission.has('site_plan_read')
    this.isHasEditPermission = this.helper.permission.has('site_plan_edit')

    // for filter
    this.dealerListForFiltering = 
      this.dealers.map(dealer => {
        let dealerName = dealer?.external_dealer_id_with_company_name ?? dealer?.company_name
        return { name: dealerName, checked: false, value: dealer.dealer_id }
      })
      .sort((a, b) => this.compare(a.name, b.name, true))
    this.searchDealerList = this.dealerListForFiltering

    await this.checkTableHeader();
    this.setPage(1)
  }

  ngOnChanges(changes): void {
    if(changes.siteList){
      this.originalSiteList = ims._.cloneDeep(this.siteList);
      this.filteredSiteList = ims._.cloneDeep(this.siteList);
      this.setPage(1)
    }
  }

  async checkTableHeader(){
    this.isSubDealer = await this.helper.me.isSubDealer()
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.siteActionMenus) {
      const siteActionMenus = this.siteActionMenus.toArray();
      siteActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(site=> {
          if (`site-${site.site_id}` === actionMenu.nativeElement.id) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              site.isShowMenu = false;
            }
          }
        })
      })
    }
    if (this.selectDropdown) {
      if (!this.selectDropdown.nativeElement.contains(event.target)) {
        event.stopPropagation()
        this.isUnfoldPlanDropdown = false;
      }
    }
    if (this.dealerSelect) {
      if (!this.dealerSelect.nativeElement.contains(event.target)) {
        this.cancelDealerFilter()
      }
    }
    if (this.statusSelect) {
      if (!this.statusSelect.nativeElement.contains(event.target)) {
        this.cancelStatusFilter()
      }
    }
    if (this.planSelect) {
      if (!this.planSelect.nativeElement.contains(event.target)) {
        this.cancelPlanFilter()
      }
    }
  }

  // --------------------------------------------------------------------------------
  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById('site-'+id);
    let more = document.getElementById('more-site-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect?.right - 10;
    const posY = rect?.bottom - 45;

    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }
  showApproveSelector(site){
    const siteId = site.site_id;
    const isRequested = site.statusValue === 2;
    if(this.isSubDealer) return;
    if(!isRequested) return;

    const docElem = document.documentElement
    let elem = document.getElementById('site-approval-'+siteId);
    let approval = document.getElementById('approval-'+siteId);
    let tooltip = document.getElementById('tooltip-'+siteId);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect?.right - 10;
    const posY = rect?.bottom - 45;

    if(!approval || !tooltip) return;
    approval.style.display = 'flex';
    approval.style.alignItems = 'center';
    approval.style.right = posX + 'px';
    approval.style.top = posY + 'px';

    tooltip.style.right = posX + 'px';
    tooltip.style.top = posY + 'px';
  }
  hideApproveSelector(site){
    const id = site.site_id;
    let approval = document.getElementById('approval-'+id);
    if(!approval) return;
    approval.style.display = 'none';
  }

  toggleMenu(site){
    const targetMenu = site.isShowMenu;
    site.isShowMenu = !targetMenu;
  }

  // Select box
  toggleAllCheckbox(event: Event) {
    // 최대 체크된 수 제한 (100개)
    const maxChecked = this.isSubDealer ? 20 : 100;
    const currentCheckedCount = this.siteList.length;
    const target = event.target as HTMLInputElement;

    if (this.isAllSelected || currentCheckedCount < maxChecked) {
      this.isAllSelected = !this.isAllSelected
      this.siteList.forEach(site => site.isSelected = this.isAllSelected)
      this.filteredSiteList.forEach(site => site.isSelected = this.isAllSelected)
      this.isAllSelected
        ? this.selectedSiteList = ims._.cloneDeep(this.siteList)
        : this.selectedSiteList = []
    } else {
      // 상태 변경을 취소
      event.preventDefault();
      target.checked = this.isAllSelected;
      this.openWarningDialog('Warning', 'You can select up to 100 sites.', 'warning', 'orange');
    }
  }

  toggleCheckbox(site, event: Event) {
    const maxChecked = this.isSubDealer ? 20 : 100;
    const currentCheckedCount = this.selectedSiteList.length;
    const eventTarget = event.target as HTMLInputElement;

    const target = this.siteList.find(item => item?.site_id === site?.site_id);
    if (site.isSelected || currentCheckedCount < maxChecked) {
      site.isSelected = !site.isSelected;
      target.isSelected = !target.isSelected;
    } else {
      event.preventDefault();
      eventTarget.checked = site.isSelected;
      this.openWarningDialog('Warning', 'You can select up to 100 sites.', 'warning', 'orange');
    }
    this.selectedSiteList = this.siteList.filter(site => site.isSelected);
    this.isAllSelected = this.siteList.every(site => site.isSelected);
  }

  toggleSelectDropdown(e){
    e?.stopPropagation()
    this.isUnfoldPlanDropdown = !this.isUnfoldPlanDropdown
  }

  selectSitePlan(event, plan){
    event?.stopPropagation();
    this.selectedSitePlan = plan;
  }

  // STYLES
  checkTotalCount(target){
    this.basicPlanCount = 0
    this.videoVaultPlanCount = 0
    this.videoVaultPlusPlanCount = 0
    this.videoVaultPlusAICount = 0

    target.forEach(site => {
      if(site?.site_plan_id === 1) this.basicPlanCount++
      if(site?.site_plan_id === 2) this.videoVaultPlanCount++
      if(site?.site_plan_id === 3) this.videoVaultPlusPlanCount++
      if(site?.site_plan_id === 4) this.videoVaultPlusAICount++
      if(!site?.site_plan_id) this.basicPlanCount++
    })
  }

  computedPlanBadgeStyle(plan){
    switch(plan){
      case 1: return 'basic-badge'
      case 2: return 'video-vault-plan-badge'
      case 3: return 'video-vault-plus-plan-badge'
      case 4: return 'video-vault-plus-ai-plan-badge'
      default: return 'basic-badge'
    }
  }

  parseSitePlan(sitePlanId){
    switch(sitePlanId){
      case 1: return 'Basic';
      case 2: return 'Video Vault';
      case 3: return 'Video Vault Plus';
      case 3: return 'Video Vault Plus AI';
      default: return 'Basic';
    }
  }

  determineElementToShow(){
    if(this.isLoading) return false
    if(!this.isHasViewPermission) return 'no-permission'
    if(!this.siteList?.length || !this.filteredSiteList?.length) return 'no-data'
    return 'normal'
  } 

  goToDetail(event, site){
    event.stopPropagation();
    this.helper.router.navigate_to(`/customers/vms-service`, {id: site.site_id});
  }

  // -----------------------------------------------------------------------------
  // FILTER
  // dealer
  selectDealer(dealer){
    this.selectedDealer = dealer
    this.isShowDealerSelect = false
    this.searchDealerTxt = ''
    this.doFilter()
  }
  searchFilterDealer(searchDealerTxt) {
    this.searchDealerList = this.dealerListForFiltering.filter(dealer => dealer.name.toLowerCase().indexOf(searchDealerTxt.toLowerCase()) > -1);
  }

  cancelDealerFilter(){
    this.isShowDealerSelect = false
    this.searchDealerTxt = ''
    this.searchDealerList = this.dealerListForFiltering
  }
  clearDealerFilter(){
    this.searchDealerTxt = ''
    this.selectedDealer = null
    this.originalSelectedDealer = null
    this.searchDealerList = this.dealerListForFiltering
    this.doFilter()
  }

  // status
  selectStatus(event, status){
    event.stopPropagation();
    status.isSelected = !status.isSelected
    
    this.changeStatusFilter()
  }
  changeStatusFilter(){
    this.selectedStatusList = this.statusList.filter(v=>v.isSelected)
    this.originalStatusList = this.selectedStatusList
    this.computedSelectedStatusLabels()
    this.doFilter()
  }
  computedSelectedStatusLabels(){
    this.statusList.every(v => v.isSelected) || this.statusList.every(v => !v.isSelected)
      ? this.selectedStatusListLabels = 'All'
      : this.selectedStatusListLabels = this.selectedStatusList.map(v=>v.label).join(', ')
  }

  cancelStatusFilter(){
    this.isShowStatusSelect = false
    this.selectedStatusList = this.originalStatusList
    this.statusList.forEach(v=>v.isSelected = false)
    this.originalStatusList.forEach(v=> {
      const status = this.statusList.find(s=>s.label === v.label)
      status.isSelected = true
    })
    this.computedSelectedStatusLabels()
  }

  clearStatusFilter(){
    this.statusList.forEach(v => v.isSelected = false)
    this.selectedStatusList = []
    this.originalStatusList = []
    this.selectedStatusListLabels = 'All'
    this.doFilter()
  }

  // plan
  selectPlan(event, plan){
    event.stopPropagation();
    plan.isSelected = !plan.isSelected
    
    this.changePlanFilter()
  }
  changePlanFilter(){
    this.selectedPlanList = this.planList.filter(v=>v.isSelected)
    this.originalPlanList = this.selectedPlanList
    this.computedSelectedPlanLabels()
    this.doFilter()
  }
  computedSelectedPlanLabels(){
    this.planList.every(v => v.isSelected) || this.planList.every(v => !v.isSelected)
      ? this.selectedPlanListLabels = 'All'
      : this.selectedPlanListLabels = this.selectedPlanList.map(v=>v.label).join(', ')
  }

  cancelPlanFilter(){
    this.isShowPlanSelect = false
    this.selectedPlanList = this.originalPlanList
    this.planList.forEach(v=>v.isSelected = false)
    this.originalPlanList.forEach(v=> {
      const status = this.planList.find(s=>s.label === v.label)
      status.isSelected = true
    })
    this.computedSelectedPlanLabels()
  }

  clearPlanFilter(){
    this.planList.forEach(v => v.isSelected = false)
    this.selectedPlanList = []
    this.originalPlanList = []
    this.selectedPlanListLabels = 'All'
    this.filteredSiteList = this.originalSiteList 
    this.doFilter()
  }

  doFilter(){
    if(!this.originalSiteList.length) return;

    let result = this.originalSiteList
    const selectedStatusList = this.selectedStatusList.filter(v => v.isSelected).map(v=>v.value)
    const selectedPlanList = this.selectedPlanList.filter(v => v.isSelected).map(v=>v.value)

    const isUsedDealerFilter = this.selectedDealer && this.selectedDealer?.value
    const isUsedStatusFilter = selectedStatusList.length && this.selectedStatusListLabels != 'All'
    const isUsedPlanFilter = selectedPlanList.length && this.selectedPlanListLabels != 'All'

    if(isUsedDealerFilter){ 
      const filteredList = result.filter(site => site.dealer_id === this.selectedDealer?.value)
      result = filteredList
    }
    if(isUsedStatusFilter){
      const filteredList = result.filter(site => selectedStatusList.includes(site.statusValue))
      result = filteredList
    }
    if(isUsedPlanFilter){
      const filteredList = result.filter(site => selectedPlanList.includes(site.site_plan_id))
      result = filteredList
    }

    if(isUsedDealerFilter || isUsedStatusFilter || isUsedPlanFilter) {
      this.filteredSiteList = ims._.uniqBy(result, 'site_id')
    } else {
      this.filteredSiteList = this.originalSiteList
    }

    this.setPage(1)
  }

  // -----------------------------------------------------------------------------
  setSearchFocus() {
    this.isFocusSearch = true;
  }
  
  search(){
    if (this.searchText.length == 0 || this.searchText.length < 3) { 
      this.filteredSiteList = this.originalSiteList
      this.setPage(1)
      return
    }
    this.filteredSiteList = this.searchSite()
    this.setPage(1)
  }
  searchSite(){
    let search_text = this.searchText.toLowerCase();
    return this.originalSiteList.filter(site => {
      let text = site.name.toLowerCase() +
                site.dealer_name.toLowerCase() 

      if(search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  // -----------------------------------------------------------------------------
  // ACTION
  async onClickBulkUpdatePlan(){
    const result = this.isSubDealer
      ? await this.bulkRequestUpdate()
      : await this.bulkUpdate()
    if(!result) return;

    this.refresh.emit();
    this.selectedSiteList = [];
    this.siteList.forEach(site => site.isSelected = false);
    this.setPage(1)

    const msg = this.isSubDealer
      ? 'Site Plan has been requested successfully.'
      : 'Plan has been updated successfully.'
    this.openWarningDialog('Success', msg, 'done', 'green');
    this.isLoading = false;
  }

  async bulkUpdate(){
    try {
      this.isLoading = true;
      const selectedSiteIds = this.selectedSiteList.map(site => site.site_id);
      const data = { site_plan_id: this.selectedSitePlan.value, site_ids: selectedSiteIds };
      await this.helper.dealer.bulk_update_dealer_site_plan(data);
      return true;
    } catch(err) {
      this.isLoading = false;
      this.openWarningDialog('Error', 'Failed to update Site Plan.', 'warning', 'red');
      return false;
    }
  }
  async bulkRequestUpdate(){
    try {
      this.isLoading = true;
      const promise = this.selectedSiteList.map(async site => {
        const siteId = site.site_id;
        const planId = this.selectedSitePlan.value;
        await this.requestUpdateSitePlan(siteId, planId)
      })
      await Promise.all(promise)
      return true;
    } catch(err) {
      this.isLoading = false;
      this.openWarningDialog('Error', 'Failed to request updating Site Plan.', 'warning', 'red');
      return false;
    }
  }
  async requestUpdateSitePlan(siteId, planId){
    try {
      await this.helper.sites.request_site_plan_update(siteId, { site_plan_id: planId });
    } catch(err) {
      console.debug('requestUpdateSitePlan :>',err)
    }
  }

  async onClickApprovePlan(site){
    const siteId = site.site_id;
    const sitePlanId = site.requested_to_change_site_plan_id;
    const data = { site_plan_id: sitePlanId };
    this.c_components.dialog.open("warning", {
      header: `Do you want to approve the update request?`,
      contents: `
        <p>
        Once approved, the site plan will be applied immediately.
        </p>
      `,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => {
        await this.updateRequestedSitePlan(siteId, data)
        await this.deleteRequsestedSitePlan(site.site_id)
        await this.updateAllCamerasTimeSync(site.dealer_id, site.site_id)
        await ims.tool.sleep(500)

        this.refresh.emit();
        this.setPage(1)
        this.openWarningDialog('Success', 'Plan has been updated successfully.', 'done', 'green');
      },
    });
  }

  cancelRequestPlanChange(site){
    const siteId = site.site_id;
    this.c_components.dialog.open("warning", {
      header: `Do you want to cancel the update request?`,
      contents: ``,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => {
        await this.deleteRequsestedSitePlan(siteId)
        await ims.tool.sleep(500)

        this.refresh.emit();
        this.setPage(1)
        this.openWarningDialog('Success', 'Plan has been updated successfully.', 'done', 'green');
      },
    });
  }

  async updateRequestedSitePlan(siteId, data){
    // dealer plan이 standard 아닐 경우에만 Requested가 가능하도록 GUI에서 막아둠.
    await this.helper.sites.approve_site_plan_update(siteId, data);
  }
  async deleteRequsestedSitePlan(siteId){
    // 완료 후에는 삭제해야 함. 
    await this.helper.sites.reject_site_plan_update(siteId);
  }
  async updateAllCamerasTimeSync(dealerId, siteId){
    // 개별 사이트에서는 물어보지만, 이번에는 그냥 적용하기로 함.
    const data = { is_time_sync_enabled: 1 }
    await this.sitesService.updateDevices(dealerId, siteId, data).toPromise()
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string | null, b: number | string | null, isAsc: boolean) {
    if (a === null && b === null) return 0; 
    if (a === null) return isAsc ? -1 : 1; 
    if (b === null) return isAsc ? 1 : -1; 
    
    if (typeof a === 'string') a = a.toLocaleLowerCase();
    if (typeof b === 'string') b = b.toLocaleLowerCase();
    
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  computedNextMonth(){
    return ims.moment().add(1, 'months').startOf('month').format('DD MMM');
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value?.replace(/,/g, ""))
  }

  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredSiteList;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_name': 
          return this.compare(a.name, b.name, isAsc);
        case 'dealer_name': 
          return this.compare(a.dealer_name, b.dealer_name, isAsc);
        case 'status': 
          return this.compare(a.statusValue, b.statusValue, isAsc);
        case 'site_plan': 
          return this.compare(a.site_plan_id, b.site_plan_id, isAsc);
        default: return 0;
      }
    });
    
    this.filteredSiteList = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.filteredSiteList;
    this.checkTotalCount(target)
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }

  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }

}