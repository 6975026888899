import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";

import { c_components } from "../../..";
import { Helper } from "../../../../../4services/2helper";
import { UsersService } from '@app/services/users.service'
import { SitesService } from '@app/services/sites.service';
import { AccessesService } from "@app/services/accesses.service";
import { RolesService } from "@app/services/roles.service";
import { PagerService } from "@app/services/pager.service";

import { isString } from "lodash";

@Component({
  selector: "c_dtable_app_users_detail_component",
  templateUrl: "./c_dtable_app_users_detail_component.pug",
  styleUrls: ["../../common.scss", "./c_dtable_app_users_detail_component.scss"],
})
export class c_dtable_app_users_detail_component {
  @Input() public searchText: string;
  @Input() appUserSites: any[] = [];
  @Output() selectedSitesChange = new EventEmitter<any[]>();
  // ------------------------------------------------------------------------
  me: any;
  dealer_id: number;
  accesses: any[] = [];
  roles: any[] = [];
  divisionRoles: any[] = [];
  isLoading:boolean = false
  filteredAppUsers = [];
  memberRoles: any[] = []
  selectedSites: any[] = []
  filteredSites: any[] = []; // 검색해서 필터링된
  appUser

  pager: any = {};
  curPage = 1;
  totalSite = 0;
  pageOffset = 15;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;
  pagedItems: any[];

  constructor(
    private usersService: UsersService,
    private sitesService: SitesService,
    private accessesService: AccessesService,
    private rolesService: RolesService,
    private pagerService : PagerService,
    private helper: Helper,
  ) {}
  // ------------------------------------------------------------------------
  ngOnInit() {
    this.initData()
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.dealer_id = this.me.dealer_id;
      this.fetchData()
    });
  }

  ngOnChanges(changes) {
    if (changes["appUserSites"] && changes["appUserSites"].currentValue?.length > 0) {
      this.filteredSites = [...this.appUserSites];
      this.setPage(1);
    }

    if (changes['searchText']) {
      const search = (this.searchText || '').toLowerCase().trim();
  
      this.filteredSites = this.appUserSites.filter(site => {
        const siteName = (site.name || '').toLowerCase();
        return siteName.includes(search);
      });
  
      this.setPage(1);
    }
  }

  initData() {

  }

  fetchData() {
    this.fetchSiteMemberRoles()
  }


  fetchSiteMemberRoles() {
    const dealerId = this.me.dealer_id;
    this.sitesService.getSiteMemberRoles(dealerId).subscribe(res => {
      this.memberRoles = res.roles;
    })
  }

  // pagination------------------------------------------------------------------
  setPage(page: number) {
    const source = this.filteredSites || [];

    if (!source.length) {
      this.pagedItems = [];
      this.totalSite = 0;
      this.pager = {};
      this.curPage = 1;
      this.pageOffsetStart = 0;
      this.pageOffsetEnd = 0;
      return;
    }
  
    if (page < 1 || (this.pager.totalPages && page > this.pager.totalPages)) {
      return;
    }
  
    this.pager = this.pagerService.getPager(source.length, page, this.pageOffset);
    this.totalSite = source.length;
    this.pagedItems = source.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    this.pageOffsetStart = this.pager.startIndex + 1;
    this.pageOffsetEnd = Math.min(this.pager.endIndex + 1, this.totalSite);
  }
  
  areAllSitesSelected(change: any) {    
    this.pagedItems.forEach(user => {
      user.isSelected = change.checked;
    });
    
    this.selectedSites = this.pagedItems.filter(user => user.isSelected);
    this.selectedSitesChange.emit(this.selectedSites);
  }

  checkIsSitesSelected(event: any) {
    this.selectedSites = this.appUserSites.filter(site => site.isSelected);
    this.selectedSitesChange.emit(this.selectedSites);
  }
}