import { Component, Input, Output, EventEmitter, ElementRef, ViewChildren, QueryList, HostListener } from "@angular/core";
import { Subscription } from "rxjs";

import { Helper } from "../../../../../4services/2helper";
import { UsersService } from '@app/services/users.service'
import { SitesService } from '@app/services/sites.service';
import { AccessesService } from "@app/services/accesses.service";
import { RolesService } from "@app/services/roles.service";
import { PagerService } from "@app/services/pager.service";
import { c_components } from "../../../../../3ui/2components";

import { isString } from "lodash";

@Component({
  selector: "c_dtable_app_users_list_component",
  templateUrl: "./c_dtable_app_users_list_component.pug",
  styleUrls: ["../../common.scss", "./c_dtable_app_users_list_component.scss"],
})
export class c_dtable_app_users_list_component {
  @ViewChildren('actionMenus', {read: ElementRef}) actionMenus: QueryList<any>
  @Input() public searchText: string;
  @Input() selectedSite: any;
  @Input() selectedSiteMembers: any;
  @Output() selectedUsersChange = new EventEmitter<any[]>();

  me: any;
  dealer_id: number;
  isLoading = false;
  accesses: any[] = [];
  memberRoles: any[] = []

  filteredAppUsers: any[] = []; // site에 속해있는 app user
  selectedAppUsers: any[] = []; // 선택된 app users
  originalAppUsers: any[] = [];

  pager: any = {};
  curPage = 1;
  totalSite = 0;
  pageOffset = 10;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  pagedItems: any[];

  constructor(
    private usersService: UsersService,
    private sitesService: SitesService,
    private accessesService: AccessesService,
    private rolesService: RolesService,
    private pagerService: PagerService,
    private c_components: c_components,
    private helper: Helper,
  ) {}

  // private appUsers$w: Subscription;
  // watch() {
  //   this.appUsers$w = this.helper.app_users.
  // }

  // --------------------------------------------------------------------------------
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.actionMenus) {
      const actionMenus = this.actionMenus.toArray();
      actionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(appUsers=> {
          if (('appUsers-'+appUsers?.user_id) === (actionMenu.nativeElement.id+'')) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              appUsers['isShowMenu'] = false;
            }
          }
        })
      })
    }
  }

  ngOnInit() {
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.dealer_id = this.me.dealer_id;
      this.initData()
    });
  }

  ngOnChanges(changes) {
    if (changes['selectedSite'] || changes['selectedSiteMembers']) {
      this.setPage(1);
    }

    if (changes.searchText && changes.searchText.currentValue !== changes.searchText.previousValue) {
      const searchValue = changes.searchText.currentValue;
      // console.log(searchValue)
      this.filterAppUsersBySearch(searchValue)
    }
  }

  initData() {
    this.fetchSiteMemberRoles()
    this.fetchUserList()
  }

  fetchSiteMemberRoles() {
    const dealerId = this.me.dealer_id;
    this.sitesService.getSiteMemberRoles(dealerId).subscribe(res => {
      this.memberRoles = res.roles;
    })
  }

  fetchUserList() {
    this.usersService.getAppUsers(this.me.dealer_id).subscribe(res=>{
      this.originalAppUsers = res.map(user => ({
        ...user,
        isSelected: false
      }));
      this.filteredAppUsers = [...this.originalAppUsers];
      this.setPage(1);
    }, err=>{
      this.isLoading = false;
    });
  }

  // img
  computedImgError(appUser){
    return appUser.provider_picture = null;
  }

  goToDetail(appUserId){
    this.helper.router.navigate_to(`/settings/app-users/${appUserId}`);
  }

  setAppUsersMorePosition(appUsers) {
    const docElem = document.documentElement
    let elem = document.getElementById('appUsers-'+appUsers?.user_id);
    let more = document.getElementById('appUsers-more-'+appUsers?.user_id);
    let rect = elem.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right + 5
    const posY = rect.top - 22;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }


  toggleMenu(appUsers){
    const targetMenu = appUsers.isShowMenu;
    appUsers.isShowMenu = !targetMenu;
  }

  openRemoveDialog() {
    this.c_components.dialog.open("app_user_remove", this.selectedAppUsers)
  }

  // pagination
  setPage(page: number) {
    // (selectedSite 있으면 selectedSiteMembers, 없으면 filteredAppUsers)
    const dataSource = this.selectedSite ? this.selectedSiteMembers : this.filteredAppUsers;

    if (!dataSource || dataSource.length === 0) {
      this.pagedItems = [];
      this.totalSite = 0;
      this.pager = {};
      this.curPage = 1;
      this.pageOffsetStart = 0;
      this.pageOffsetEnd = 0;
      return;
    }

    const totalPages = Math.ceil(dataSource.length / this.pageOffset);
    page = Math.max(1, Math.min(page, totalPages));

    this.pager = this.pagerService.getPager(
      dataSource.length, 
      page, 
      this.pageOffset
    );

    this.pagedItems = dataSource.slice(
      this.pager.startIndex, 
      this.pager.endIndex + 1
    );

    this.totalSite = dataSource.length;
    this.curPage = this.pager.currentPage;
    this.pageOffsetStart = this.pager.startIndex + 1;
    this.pageOffsetEnd = Math.min(this.pager.endIndex + 1, this.totalSite);
  }

  areAllSitesSelected(change: any) {
    const dataSource = this.selectedSite ? this.selectedSiteMembers : this.filteredAppUsers;
    
    this.pagedItems.forEach(user => {
      user.isSelected = change.checked;
    });
    
    this.selectedAppUsers = dataSource.filter(user => user.isSelected);
    this.selectedUsersChange.emit(this.selectedAppUsers);
  }

  checkIsSitesSelected(event: any) {
    const dataSource = this.selectedSite ? this.selectedSiteMembers : this.filteredAppUsers;
    
    this.selectedAppUsers = dataSource.filter(user => user.isSelected);
    this.selectedUsersChange.emit(this.selectedAppUsers);
  }

  filterAppUsersBySearch(searchValue: string) {
    const search = (searchValue || '').toLowerCase().trim();
  
    this.filteredAppUsers = this.originalAppUsers.filter(user => {
      const email = (user.email || '').toLowerCase();
      return email.includes(search);
    });
  
    this.setPage(1);
  }
}