import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { animeBackground } from '../../../../app/pages/animation';
import { UsersService } from '@app/services/users.service';
import { CommonService } from '@app/services/common.service';
import { SitesService } from '@app/services/sites.service';
import { DealerPlanService } from '@app/services/service-plan.service';
import { Helper } from '../../../../4services/2helper';
import { c_components } from '../..';
import ims from '../../imports'

@Component({
  selector: 'c_card_site_plan_v3',
  templateUrl: './c_card_site_plan_v3.component.pug',
  styleUrls: ['../common.scss','./c_card_site_plan_v3.component.scss'],
  animations: [animeBackground]
})
export class c_card_site_plan_v3_component implements OnInit {
  @ViewChild('resizableDiv', { static: false }) resizableDiv!: ElementRef;
  @ViewChild('planContainer', { static: false }) planContainer!: ElementRef;
  @Input() currentDealerPlan: number;
  @Input() currentSitePlan: number;
  @Input() nextMonthSitePlan: number;
  @Input() servicePricing: any;
  @Input() isTrialPeriod: boolean = false;
  @Output() refreshSitePlan = new EventEmitter();

  me: any;
  site: any;
  isMainDealer = false;
  isHasEditPermission = false;
  resizeObserver!: ResizeObserver;

  isLoading = false;
  isIPad: boolean = false;

  constructor(
    public usersService: UsersService,
    public commonService: CommonService,
    private sitesService: SitesService,
    public dealerPlanService: DealerPlanService,
    private c_components: c_components,
    private helper: Helper,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    const url = window.location.href;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    let dealer_id = null;

    if (params['dealer_id']) dealer_id = params['dealer_id'];
    this.usersService.getMe(dealer_id).subscribe(res => {
      this.me = res;
      this.site = this.sitesService.selSite;
      this.isMainDealer = this.me.type === 2;
      this.isHasEditPermission = this.helper.permission.has('site_plan_update')
    });
  }

  
  // !!!! 이 컴포넌트는 Site에서만 사용할 것 !!! //
  async ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(ims._.debounce(entries => {
      requestAnimationFrame(() => {
        for (let entry of entries) {
          const width = entry.contentRect.width;
          const isIPad = width < 930;

          if (isIPad) {
            this.renderer.removeClass(this.planContainer.nativeElement, 'plan-container');
            this.renderer.addClass(this.planContainer.nativeElement, 'plan-container-vertical');
          } else {
            this.renderer.removeClass(this.planContainer.nativeElement, 'plan-container-vertical');
            this.renderer.addClass(this.planContainer.nativeElement, 'plan-container');
          }
        }
      });
    }, 100));

    if(this.resizableDiv?.nativeElement) this.resizeObserver.observe(this.resizableDiv?.nativeElement);

    // 강제 초기 실행 (현재 사이즈 체크)
    this.checkSize();
  }

  checkSize() {
    if (!this.resizableDiv) return;
    const width = this.resizableDiv.nativeElement.clientWidth;
    const isIPad = width < 930;

    if (isIPad) {
      this.renderer.removeClass(this.planContainer.nativeElement, 'plan-container');
      this.renderer.addClass(this.planContainer.nativeElement, 'plan-container-vertical');
    } else {
      this.renderer.removeClass(this.planContainer.nativeElement, 'plan-container-vertical');
      this.renderer.addClass(this.planContainer.nativeElement, 'plan-container');
    }
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }

  // -------------------------------------------------------
  // ACTIONS
  isShowUpdateButton(plan, type){
    if(this.currentSitePlan === plan && this.nextMonthSitePlan === plan) return false
    if(type === 'edit') {
      return this.isHasEditPermission && (this.currentSitePlan != plan)
    }
    if(type === 'keep')  {
      return this.isHasEditPermission && this.currentSitePlan === plan && this.nextMonthSitePlan != 2
    }
  }

  async onClickSitePlanSave(planId){
    const dealerType = this.me.type
    if(dealerType === 3) {
      this.updateForSubDealer(planId)
    } else {
      this.updateForMainAndOtherDealer(planId)
    }
  }
  
  updateForSubDealer(planId){
    const isBasicDealer = this.currentDealerPlan === 1
    if(isBasicDealer) {
      this.openWarningDialogUpgradeDealerPlan();
      return;
    }

    this.c_components.dialog.open("warning", {
      header: `Would you like to request a plan change?`,
      contents: `
        <p>
          Plan changes require approval from the Central Station.<br/>
          Click 'Confirm' to send your request to the Central Station. 
        </p>
      `,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => await this.requestUpdateSitePlan(planId),
    })
  }
  openWarningDialogUpgradeDealerPlan(){
    this.c_components.dialog.open("warning", {
      header: `Dealer Plan Upgrade Required`,
      contents: `
        <p>
          To use VMS Service, your dealer plan must be Professional or higher.<br/>
          Would you like to upgrade your plan now?
        </p>
      `,
      isConfrim: false,
      submit_btn: "Go to Dealer Plan Settings",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: () => this.goToDealerPlanPage(),
    })
  }
  updateForMainAndOtherDealer(planId){
    // check dealer plan
    const isBasicDealer = this.currentDealerPlan === 1
    if(isBasicDealer) {
      this.openWarningDialogUpgradeDealerPlan();
      return;
    }

    // site plan update modal
    const isDowngrade = this.currentSitePlan > planId
    const isTrialPeriod = this.isTrialPeriod

    let message = ''
    if (!isDowngrade) {
      message = `The changes will take effect immediately.`
    }
    if (isDowngrade) { 
      if(isTrialPeriod && planId === 1) message = `The downgrade is applied immediately, and the Trial Period ends.<br/>
        <b>Billing start immediately without a trial period.<b/>
      `
      else message = `For downgrades, the changes will take effect from next month.`
    }

    this.c_components.dialog.open("warning", {
      header: `Change Site Plan`,
      contents: `
        <p> ${message} </p>
      `,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => await this.updateSitePlan(planId),
    })
  }

  async requestUpdateSitePlan(planId){
    try {
      await this.helper.sites.request_site_plan_update(this.site.site_id, { site_plan_id: planId });
      this.openWarningDialog('Success', 'Site Plan has been requested successfully.', 'done', 'green');
      this.refreshSitePlan.emit();
    } catch(err) {
      console.debug('onClickSitePlanSave :>',err)
    }
  }
  async updateSitePlan(planId){
    try {
      await this.helper.sites.update_site_plan(this.site.site_id, { site_plan_id: planId });
      this.openWarningDialog('Success', 'Site Plan has been updated successfully.', 'done', 'green');
      this.refreshSitePlan.emit();
      this.openConfirmOfTimeSync();
    } catch(err) {
      console.debug('onClickSitePlanSave :>',err)
    }
  }
  async updateAllCamerasTimeSync(){
    const data = { is_time_sync_enabled: 1 }
    this.isLoading = true

    const dealerId = this.me.dealer_id
    const siteId = this.site.site_id
    try {
      await this.sitesService.updateDevices(dealerId, siteId, data).toPromise()
    } catch(err) {
      this.isLoading = false;
      console.debug('updateAllCamerasTimeSync:>',err)
    }
    this.isLoading = false;
  }

  openConfirmOfTimeSync(){
    const siteDevices = this.sitesService.siteDevices
    const cameras = siteDevices.filter(device => device.type === 12)
    const isAllEnabledTimeSync = cameras.every(camera => camera.is_time_sync_enabled === 1)
    if(isAllEnabledTimeSync) return;

    this.c_components.dialog.open("warning", {
      header: `Do you want to enable Time Sync on all cameras now?`,
      contents: `
        <p> 
        When retrieving continuous recording from camera storage it's recommended that CHeKT's Time Sync function is enabled on cameras.
        </p>
      `,
      submit_btn: "Change all cameras",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => await this.updateAllCamerasTimeSync(),
    })
  }


  ///////////////////////////////////
  // TOOL
  parseDealerPlanNames(){
    if(this.me.type === 2) return 'Reseller'
    return 'Professional or Premium'
  }

  goToDealerPlanPage(){
    if (this.isMainDealer) {
      if(this.me.dealer_id === this.site.dealer_id ) {
        this.helper.router.navigate_to("/settings/reseller-plan")
      } else {
        this.helper.router.navigate_to("/wcs-sub-dealers/info", { retailDealerId: this.site.dealer_id })
      }
    } else {
      this.helper.router.navigate_to("/settings/dealer-plan")
    }
  }
  goToSitePlanPage(){
    this.helper.router.navigate_to('/settings/site-plan')
  }

  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }
}
