import { Component, Inject } from "@angular/core";
import { CommonService } from "@app/services/common.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_site_plan_settings_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_site_plan_settings_component.scss"],
})
export class c_dialog_site_plan_settings_component {
  isLoading: boolean = false;

  // ------------------------------------------------------------------------
  constructor(
    public dialogRef: MatDialogRef<c_dialog_site_plan_settings_component>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private c_components: c_components,
    private helper: Helper
  ) {}

  ngOnChanges(changes): void {
    console.log('c_dialog_site_plan_settings_component :>', changes);
  }

  close_dialog(result: boolean) {
    this.dialogRef.close(result);
    return result;
  }

  refresh(){
    this.close_dialog(true);
  }
}