import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../../app/directives/shared.module'

import { c_card_event_viewer_box_component } from './c_card_event_viewer_box.component';
import { log_tool_bar } from './tool-bar/log_tool_bar.component';
import { log_box_alarm_video_component } from './log-box/alarm-video/alarm_video.component'
import { log_box_arming_component } from './log-box/arming/arming.component'
import { log_box_audio_component } from './log-box/audio/audio.component'
import { log_box_default_template_component } from './log-box/default_template/default_template.component'
import { log_box_playback_component } from './log-box/playback/playback.component'
import { log_box_relay_component } from './log-box/relay/relay.component'
import { log_box_verification_component } from './log-box/verification/verification.component'
import { log_box_disarmed_event_component } from './log-box/disarmed-event/disarmed_event.component'
// import { log_box_video_ai_component } from './log-box/video-ai/video_ai.component'
import { log_box_timelapse_component } from './log-box/timelapse/timelapse.component'

import { LogRendererDirective } from './log-renderer.directive'
import { LogObserverDirective } from './log-observer.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    c_card_event_viewer_box_component,
    log_tool_bar,
    log_box_alarm_video_component,
    log_box_arming_component,
    log_box_audio_component,
    log_box_default_template_component,
    log_box_playback_component,
    log_box_relay_component,
    log_box_verification_component,
    log_box_disarmed_event_component,
    // log_box_trouble_component,
    log_box_timelapse_component,
    LogRendererDirective,
    LogObserverDirective,
  ],
  exports: [c_card_event_viewer_box_component],
  entryComponents: [
    log_box_alarm_video_component,
    log_box_arming_component,
    log_box_audio_component,
    log_box_default_template_component,
    log_box_playback_component,
    log_box_relay_component,
    log_box_verification_component,
    // log_box_video_ai_component,
    log_box_timelapse_component
  ]
})
export class c_card_event_viewer_box_module { }
