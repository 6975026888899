
import {of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { UsersService } from './users.service';
import { DealerService } from './dealer.service';

@Injectable()
export class SitesService {

  sites: any;
  selSite= <any>new Object();

  site$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  site$w = this.site$s.asObservable()

  sites$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sites$w = this.sites$s.asObservable()

  searchedSites$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  searchedSites$w = this.searchedSites$s.asObservable()

  divisionSites$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  divisionSites$w = this.divisionSites$s.asObservable()

  sitePlan$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sitePlan$w = this.sitePlan$s.asObservable()

  filter :any;

  site: EventEmitter<boolean> = new EventEmitter();
  fetchSites: EventEmitter<boolean> = new EventEmitter();
  siteCreated = false;
  curSiteTab = 'customers/general' // default genenal tab
  curSubSiteTab = '';

  siteDIEventCodes: any = {}; // site level

  siteMembers = [];
  siteMember = <any>{};
  siteRules = [];

  memberRoles = [];

  procedureList = [];
  siteDevices = []
  sitePartners = [];

  initSiteInfo() {
    this.siteMembers = [];
    this.siteMember = <any>{};
  }

  constructor(
    private api: ApiService,
    private commonService: CommonService,
    private usersService: UsersService,
    private dealerService: DealerService
    ) {}

  async get_site_partners() {
    await this.load_site_partners();
    this.sitePartners = this.dealerService.partners$s.getValue();
  }
  async load_site_partners(): Promise<boolean> {
    try {
      const dealer_id = this.usersService.me.dealer_id;
      if(!dealer_id) return false;

      await this.dealerService.getSitePartners(dealer_id).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

  // sub dealer의 사이트인 경우 external_dealer_id와 함께 company_name를 표시하기 위한 함수
  // 이미 dealer list에서 가져온 정보를 사용
  parseExternalDealerIdWithCompanyName(site){
    const dealerInfo = this.sitePartners.find(partner => partner.dealer_id === site.dealer_id)
    site.external_dealer_id_with_company_name = dealerInfo?.external_dealer_id_with_company_name ?? site.company_name
  }

  get(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}`).pipe(
      switchMap(async res => {
        await this.get_site_partners()
        this.parseExternalDealerIdWithCompanyName(res)
        return res;
      })
    );
  }

  fetch(dealer_id: number, partnerId?, fields?): Observable<any> {
    let url = `/dealers/${dealer_id}/sites?sort=name&dir=asc`;
    if (partnerId) {
      url += `&filter=site.dealer_id,${partnerId},eq`
    }
    if (fields){
      url += `&fields=${fields}`;
    }
    return this.api.get(url).pipe(
    switchMap(async (res) => {
      await this.get_site_partners()
      res.forEach((site) => this.parseExternalDealerIdWithCompanyName(site))
      this.sites = res;
      this.sites$s.next(res)
      return res;
    }));
  }

  public getSites(dealer_id: number, refresh?): Observable<any> {
    if (this.sites && !refresh) {
      return observableOf(this.sites);
    }
    return this.fetch(dealer_id);
  }

  getOfflineSites(dealerId): Observable<any> {
    const url = `/dealers/${dealerId}/sites/offline_devices`;
    return this.api.get(url);
  }

  getSite(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}`).pipe(
      switchMap(async res => {
        await this.get_site_partners()
        this.parseExternalDealerIdWithCompanyName(res)
        this.site$s.next(res)
        return res
        }
      )
    )
  }

  public search(str: string) {
    if(!this.sites)
      return [];

    let search_text = str.toLowerCase();
    return this.sites.filter(site => {
      let text = site.name.toLowerCase() +
                 site.address1.toLowerCase() +
                 site.address2.toLowerCase() +
                 site.email;

      if(search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  siteSearch(dealerId, query, filter= 'site') : Observable<any> {
    let encodedQuery = encodeURIComponent(query);
    return this.api.get(`/dealers/${dealerId}/search?query=${encodedQuery}&filter=${filter}`).pipe(
      map(res => {
        res.forEach((site) => this.parseExternalDealerIdWithCompanyName(site))
        this.searchedSites$s.next(res)
        return res;
      }));
  }

  public getDivisionSites(dealerId, divisionId){
    let url = `/dealers/${dealerId}/sites?sort=name&dir=asc&filter=site.division_id,${divisionId}`;
    return this.api.get(url).pipe(
    map(res => {
      this.sites = res;
      this.divisionSites$s.next(res)
      return res;
    }));
  }

  public getSiteContacts(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/contacts`).pipe(
      map(res => {
        return res;
      }));
  }

  public getSiteContact(dealerId: number, siteId: number, contactId): Observable<any> {
    return this.api
      .get(`/dealers/${dealerId}/sites/${siteId}/contacts/${contactId}`)
      .map(res => {
        return res;
      });
  }

  public getSitePartners(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/partners`).pipe(
      map(res => {
        return res;
      }));
  }

  public getSiteDevices(dealer_id: number, site_id: number, filter?): Observable<any> {
    let apiPath = `/dealers/${dealer_id}/sites/${site_id}/devices`
    if (filter) {
      apiPath += filter;
    }
    return this.api.get(apiPath).pipe(
      map(res => {
        this.siteDevices = res;
        return res;
      }));
  }

  public getSiteOrders(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/orders`).pipe(
      map(res => {
        return res;
      }));
  }

  createSiteMember(dealerId, siteId, memberData): Observable<any> {
    const data = {
      email: memberData.email,
      firstname: memberData.firstname,
      lastname: memberData.lastname,
      role: memberData.role
    }
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/members`, data);
  }

  public getSiteMembers(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/members`).pipe(
      map(res => {
        return res;
      }));
  }

  updateSiteMember(dealerId, siteId, memberId, memberData): Observable<any> {
    const data = {
      // email: memberData.email,
      // firstname: memberData.firstname,
      // lastname: memberData.lastname,
      role: memberData.role
    }
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/members/${memberId}`, data);
  }

  deleteSiteMember(dealerId, siteId, memberId): Observable<any> {
    let apiPath = `/dealers/${dealerId}/sites/${siteId}/members/${memberId}`
    return this.api.delete(apiPath);
  }

  getSiteMemberRoles(dealer_id: number): Observable<any> {
    if (this.memberRoles.length !== 0) {
      return observableOf(this.memberRoles);
    }
    return this.api.get(`/dealers/${dealer_id}/site_member_roles`).pipe(
    map(res => {
      this.memberRoles = res;
      return res;
    }));
  }

  // Site member Config
  updateSiteMemberConfig(dealerId, siteId, configData): Observable<any> {
    const url = `/dealers/${dealerId}/sites/${siteId}/members/apps/config`
    return this.api.put(url, configData);
  }

  getSiteMemberNotificationConfig(dealerId, siteId, memberId): Observable<any> {
    const url = `/dealers/${dealerId}/sites/${siteId}/members/${memberId}/apps/notifications`;
    return this.api.get(url);
  }

  updateSiteMemberNotificationConfig(dealerId, siteId, memberId, data): Observable<any> {
    const url = `/dealers/${dealerId}/sites/${siteId}/members/${memberId}/apps/notifications`;
    return this.api.put(url, data);
  }

  setSiteMember(member) {
    this.siteMember = member;
  }

  initSiteMember() {
    this.siteMember = {};
  }

  public getVirtualZones(dealer_id: number, site_id: number) {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/zones`).pipe(
      map(res => {
        return res;
      }))
  }

  public update(dealer_id: number, site_id: number, data: any): Observable<any> {
    let put_data = {};
    const keys = Object.keys(data)
    if(keys.includes('email')) put_data['email'] = data.email;
    if(keys.includes('name')) put_data['name'] = data.name;
    if(keys.includes('address1')) put_data['address1'] = data.address1;
    if(keys.includes('address2')) put_data['address2'] = data.address2;
    if(keys.includes('lat')) put_data['lat'] = data.lat;
    if(keys.includes('lng')) put_data['lng'] = data.lng;
    if(keys.includes('country_code')) put_data["country_code"] = data.country_code;
    if(keys.includes('zipcode')) put_data['zipcode'] = data.zipcode;
    if(keys.includes('timezone')) put_data['timezone'] = data.timezone;
    if(data.timezone_offset !== undefined) put_data['timezone_offset'] = data.timezone_offset;
    if(keys.includes('division_id')) put_data['division_id'] = data.division_id;
    if(keys.includes('customer_account_number')) put_data['customer_account_number'] = data.customer_account_number;
    if(data.auto_verification_hold_off_time !== undefined) put_data['auto_verification_hold_off_time'] = parseInt(data.auto_verification_hold_off_time) * 60;
    if(data.is_auto_verification !== undefined) put_data['is_auto_verification'] = data.is_auto_verification;
    if(keys.includes('verification_duration')) put_data['verification_duration'] = parseInt(data.verification_duration);
    if(keys.includes('shared_site_mp_setting_editable')) put_data['shared_site_mp_setting_editable'] = data.shared_site_mp_setting_editable;
    if(keys.includes('is_tts_enabled')) put_data['is_tts_enabled'] = data.is_tts_enabled;

    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}`, put_data);
  }

  //programming
  getSiteProgrammingInfo(dealerId: number, siteId: number) {
    const url = `/dealers/${dealerId}/sites/${siteId}/programming`;
    return this.api.get(url);
  }
  putProgramming(dealer_id: number, site_id: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/programming`, data);
  }

  public customUpdate(dealer_id: number, site_id: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}`, data);
  }

  public invite(dealer_id: number, site_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/invite`);
  }

  public inviteSiteMember(dealer_id: number, site_id: number, memberId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/members/${memberId}/invite`);
  }

  createSite(dealer_id: number, data: any, isSync?): Observable<any> {
    let url = `/dealers/${dealer_id}/sites`
    let post_data = {
      'customer_account_number': data.customer_account_number,
      'name': data.name,
      'address1': data.address1,
      'address2': data.address2 ? data.address2 : "",
      'lat' : data.lat,
      'lng' : data.lng,
      'timezone_offset': data.timezone_offset,
      'timezone': data.timezone,
      'country_code': data.country_code,
      'zipcode': data.zipcode || null,
    }

    if(data.email)
      post_data['email'] = data.email;

    if (data.partner_id) {
      post_data['partner_id'] = data.partner_id;
    }

    if (data.arming_source) {
      post_data['arming_source'] = data.arming_source;
    }

    if (data.division_id) {
      post_data['division_id'] = data.division_id;
    }

    if (isSync) {
      url += '?sync=1'
    }

    return this.api.post(url, post_data);
  }

  createSiteContact(dealer_id: number, site_id: number, data: any): Observable<any> {
    const post_data = {
      name: data.name,
      slot_number: data.slot_number,
      title: data.title,
      phone: data.phone,
      email: data.email,
      is_verification_contact: data.is_verification_contact,
      is_send_email: data.is_send_email ? 1 : 0
    };

    return this.api.post(`/dealers/${dealer_id}/sites/${site_id}/contacts`, post_data);
  }

  createSitePartner(dealerId, siteId, data) : Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/partners`, data);
  }

  updateSiteContact(dealerId: number, siteId: number, contactId: number, data: any): Observable<any> {
    const put_data = {
      name: data.name,
      slot_number: data.slot_number,
      title: data.title,
      phone: data.phone,
      email: data.email,
      is_verification_contact: data.is_verification_contact,
      is_send_email: data.is_send_email
    };

    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/contacts/${contactId}`, put_data);
  }

  updateSiteAccount(dealerId: number, siteId: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/account`, data);
  }

  updateSiteAccountByOwner(dealerId: number, siteId: number, partnerId, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/partners/${partnerId}`, data);
  }

  updateSiteAccountByPartner(dealerId: number, siteId: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/partner`, data);
  }

  deleteSiteContact(dealer_id: number, site_id: number, contact_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/sites/${site_id}/contacts/${contact_id}`);
  }

  uploadBlueprint(dealer_id: number, site_id: number, data: any, content_type: string) {
    let result = new BehaviorSubject<any>(null);

    let ext = content_type.split('/')[1];
    this.api.put(`/dealers/${dealer_id}/sites/${site_id}/blueprint`, {format: ext}).subscribe(
      res => {
        let uploadUrl = res['url'];
        // let header = new Headers({'content-type': content_type});
        // let options = new RequestOptions({headers: header});
        let header = new HttpHeaders({
          'content-type': content_type
        });
        let options = { headers: header, responseType: 'text' };
        this.api.put_custom(uploadUrl, data, options).subscribe(
          res => {
            result.next({res: true, url: uploadUrl});
          }, err => {
            result.next({ res: false, err });
          }
        );
      }, err => {
        result.next({ res: false, err });
      }
    );

    return result;
  }

  deleteBlueprint(dealer_id: number, site_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/sites/${site_id}/blueprint`);
  }


  deleteSite(dealer_id: number, site_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/sites/${site_id}`);
  }

  deleteSitePartner(dealerId: number, siteId: number, partnerId:number): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/partners/${partnerId}`);
  }

  deleteSitePartnerByOwner(dealerId: number, siteId: number, partnerId:number): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/partners/${partnerId}`);
  }

  deleteSitePartnerByPartner(dealerId: number, siteId: number): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/partner`);
  }

  //site signals
  createSiteEventFormatCode(dealerId, siteId, data) {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/event_format_codes`, data);
  }
  getSiteEventFormatCodes(dealerId, siteId, filter?) {
    let url = `/dealers/${dealerId}/sites/${siteId}/event_format_codes`;
    if (filter) {
      url += filter;
    }
    return this.api.get(url);
  }
  updateSiteEventFormatCode(dealerId, siteId, codeId, data) {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/event_format_codes/${codeId}`, data);
  }
  deleteSiteEventFormatCode(dealerId, siteId, codeId) {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/event_format_codes/${codeId}`);
  }

  updateEventFormatCode(dealer_id, siteId, eventFormatId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/sites/${siteId}/event_formats/${eventFormatId}/codes/${codeId}`, data);
  }

  //site zones
  getSiteZoneCodes(dealer_id, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${siteId}/zone_codes`);
  }

  //site events
  //site events
  getSiteAllEvents(dealerId, siteId, offset=0, limit=20, id?): Observable<any> {
    if (id) {
      return this.api.get(`/dealers/${dealerId}/sites/${siteId}/events?filter=event_id,${id},lt&sort=id&dir=desc&offset=0&limit=${limit}`)
    } else {
      return this.api.get(`/dealers/${dealerId}/sites/${siteId}/events?sort=id&dir=desc&offset=${offset}&limit=${limit}`)
    }
  }

  getSiteAllEventsHistory(dealerId, siteId, offset=0, limit=20, id?, filter?): Observable<any> {
    if (id) {
      return this.api.get(`/dealers/${dealerId}/sites/${siteId}/events/all/historys?${filter}&filter=id,${id},lt&sort=id&dir=desc&offset=0&limit=${limit}`)
    } else {
      return this.api.get(`/dealers/${dealerId}/sites/${siteId}/events/all/historys?${filter}&sort=id&dir=desc&offset=${offset}&limit=${limit}`)
    }
  }

  getSiteEventHistory(dealerId, siteId, eventId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/events/${eventId}/historys`)
  }

  //site notes
  createSiteNotes(dealerId, siteId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/notes`, data);
  }
  getSiteNotes(dealerId, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/notes`);
  }
  updateSiteNotes(dealerId, siteId, noteId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/notes/${noteId}`, data);
  }
  deleteSiteNotes(dealerId, siteId, noteId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/notes/${noteId}`);
  }

  //site plan
  getSiteCharges(dealerId, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/charges`);
  }
  putSiteCharges(dealerId, siteId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/charges`, data);
  }

  //site tts
  // assgin tts modal에서만 사용
  createTTS(dealerId, siteId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/ttss`, data);
  }
  getTTSList(dealerId, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/ttss`);
  }

  // 이건 site tts에서만 사용됨.
  updateTTSUseType(dealerId, siteId, ttsId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/ttss/${ttsId}/use-type`, data);
  }
  //
  getTTS(dealerId, siteId, ttsId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/ttss/${ttsId}`);
  }
  deleteTTS(dealerId, siteId, ttsId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/ttss/${ttsId}`);
  }
  linkTTS(dealerId, siteId, data): Observable<any> { //tts_ids
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/ttss/link`, data);
  }

  uploadTTSFile(
    dealer_id: number,
    site_id: number,
    fileData: any,
    content_type: string,
    format: string,
    description: string
  ) {
    let result = new BehaviorSubject<any>(null);
    const ttsData = {
      text: description,
      format: format,
      type: 1 // 0: text tts, 1: audio file tts, 2: google tts
    }
    this.api
      .post(`/dealers/${dealer_id}/sites/${site_id}/ttss`, ttsData)
      .subscribe(
        res => {
          let uploadUrl = res["upload_url"];
          const ttsId = res['id']
          // let header = new Headers({'content-type': content_type});
          // let options = new RequestOptions({headers: header});
          let header = new HttpHeaders({
            "content-type": content_type
          });
          let options = { headers: header, responseType: "text" };
          this.api.put_custom(uploadUrl, fileData, options).subscribe(
            res => {
              result.next({ res: true, url: uploadUrl, ttsId: ttsId});
            },
            err => {
              result.next({ res: false, err, ttsId: ttsId });
            }
          );
        },
        err => {
          result.next({ res: false , err});
        }
      );

    return result;
  }

  getTTSFile(ttsUrl) {
    let headers = new HttpHeaders({
      // 'Content-Type': 'image/jpeg',
      // 'Accept': '*/*',
    });
    return this.api.getUrl(ttsUrl, {headers, withCredentials: false, responseType: 'blob' });
  }

  getTTSFileV2(ttsUrl) {
    let headers = new HttpHeaders({
      // 'Content-Type': 'image/jpeg',
      // 'Accept': '*/*',
    });
    return this.api.getUrl(ttsUrl, {headers, withCredentials: false, responseType: 'blob', observe: 'response' });
  }

  testGoogleTTS(dealerId, siteId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/ttss/googleTest`, data);
  }

  //TTS set
  getTtsSet(dealerId, siteId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/sites/${siteId}/ttss/sets`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.get(URL);
  }

  updateTtsSet(dealerId, siteId, data, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/sites/${siteId}/ttss/sets`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.put(URL, data);
  }

  //site invoices
  getActivateSites(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/invoices/activation_period`, 'json','v1.2');
  }

  // Site Infosheet
  getSampleInfoSheet(dealerId, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/infosheets/sample`)
  }
  getInfoSheets(dealerId, siteId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/infosheets?sort=id&dir=desc`)
  }
  getInfoSheet(dealerId, siteId, infoSheetId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/infosheets/${infoSheetId}`)
  }
  createInfoSheet(dealerId, siteId, name, data): Observable<any> {
    const createData = {
      name: name,
      data: data
    }
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/infosheets/`, createData)
  }
  updateInfoSheet(dealerId, siteId, data): Observable<any> {
    const updateData = {
      name: data.name,
      data: data.data
    }
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/infosheets/`, updateData)
  }
  deleteInfoSheet(dealerId, siteId, infoSheetId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/infosheets/${infoSheetId}`)
  }

  uploadInfoSheetThumbnil(thumbnailUrl, data: any, content_type: string) {
    let header = new HttpHeaders({
      "content-type": content_type
    });
    let options = { headers: header, responseType: "text" };
    return this.api.put_custom(thumbnailUrl, data, options);
  }

  getSnapshotFromUrl(url): Observable<any> {
    let headers = new HttpHeaders({
      // 'Content-Type': 'image/jpeg',
      // 'Accept': '*/*',
    });

    return this.api.getUrl(url, {headers, withCredentials: false, responseType: 'blob' })
  }

  // Site Armimg
  putSiteArming(dealerId, siteId, armingMode) {
    /*
    1: Always Arming(Away Arming)
    2: Always Disarming(Disarming)

    Allowed values: 1, 2
    */
    const updateData = {
      arming_mode: armingMode
    }
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/arming/`, updateData)
  }

  getSitesArmStatus(dealerId, siteIds) {
    let url = `/dealers/${dealerId}/sites/arming_status`;
    url += `?site_ids=${siteIds}`
    return this.api.get(url)
  }

  //Site Audio devices
  getAllAudioDevices(dealerId, siteId, filter?) {
    let url = `/dealers/${dealerId}/sites/${siteId}/audios`;
    if (filter) {
      url += '?' + filter
    }
    return this.api.get(url);
  }
  getAudioDevice(dealerId, siteId, audioDeviceId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/audios/${audioDeviceId}`
    return this.api.get(url);
  }
  createAudioDevice(dealerId, siteId, bridgeId, channel, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/audios`
    let postData = data;
    postData['device_id'] = bridgeId;
    postData['channel'] = channel;
    return this.api.post(url, data);
  }
  updateAudioDevice(dealerId, siteId, audioDeviceId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/audios/${audioDeviceId}`
    return this.api.put(url, data);
  }
  deleteAudioDevice(dealerId, siteId, audioDeviceId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/audios/${audioDeviceId}`
    return this.api.delete(url);
  }
  sendAudioInput(dealerId, siteId, mode, ttsId, txt?) {
    const url = `/dealers/${dealerId}/sites/${siteId}/audios/all/output`
    const data = {
      mode: mode,
      tts_id: ttsId
    }
    if (txt) {
      data['text'] = txt;
    }
    return this.api.post(url, data);
  }

  //SMS verify
  // requestVefifySMS(dealerId, siteId, phone, expired?) {
  //   const url = `/dealers/${dealerId}/sites/${siteId}/phone_number_verify`
  //   let data: any = {
  //     phone: phone
  //   };
  //   if (expired) {
  //     data = {
  //       expired: expired
  //     }
  //   }
  //   return this.api.post(url, data);
  // }

  getAllVerifySMS(dealerId, siteId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/phone_number_verify`
    return this.api.get(url);
  }

  getVerifySMS(dealerId, siteId, uuid) {
    const url = `/dealers/${dealerId}/sites/${siteId}/phone_number_verify?filter=uuid,${uuid},eq`
    return this.api.get(url);
  }

  requestVerifySMS(dealerId, siteId, contactId, expired?, isResend?) {
    const url = `/dealers/${dealerId}/sites/${siteId}/contacts/${contactId}/verify`
    let data: any = {};
    if (expired) {
      data['expired'] = expired
    }
    if (isResend) {
      data['is_resend'] = 1;
    }
    return this.api.post(url, data);
  }

  getVerifyErrLog(dealerId, siteId, contactId, verifyId): Observable<any> {
    const url = `/dealers/${dealerId}/sites/${siteId}/contacts/${contactId}/verify/${verifyId}/logs`
    return this.api.get(url);
  }

  // Site Activation
  updateSiteActivation(dealerId, siteId, isActivated) {
    const url = `/dealers/${dealerId}/sites/${siteId}/activation`;
    const data = {
      is_activated: isActivated ? 1 : 0
    }
    return this.api.put(url, data);
  }
  goToSiteActionTab(siteId) {
    this.commonService.emitSiteTab([{name: 'Site Settings', link: 'setting/actions'}, 'actions']);
  }

  //Site Statistics
  // - for ranking
  getSiteStatistics(dealerId, siteId, startDate, endDate,types=null, rank=null) {
    let url = `/dealers/${dealerId}/sites/${siteId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (rank) url += `&rank=${rank}`;
    return this.api.get(url);
  }

  getSiteDeviceScores(dealerId, siteId, deviceId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/event_motion_scores?limit=50&sort=created_at&dir=desc`;
    return this.api.get(url);
  }

  getSiteDevicesEventMetrics(dealerId, siteId, stime, etime, type?, mode?){
    /**
     * type
      null : all
      11: video event count
      12: alarm signal count

    * mode
      0: all sum, 1: per hour sum
      Default value: 0
     */
    let url = `/dealers/${dealerId}/sites/${siteId}/metrics/device_events?stime=${stime}&etime=${etime}`
    if(type) url += `&types=${type}`
    if(mode) url += `&mode=${mode}`
    return this.api.get(url)
  }

  // Site arming Schedule
  getAllArmingSchedule(dealerId, siteId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/arming_schedules`;
    return this.api.get(url);
  }
  addArmingSchedule(dealerId, siteId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/arming_schedules`;
    return this.api.post(url, data);
  }
  updateArmingSchedule(dealerId, siteId, scheduleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/arming_schedules/${scheduleId}`;
    return this.api.put(url, data);
  }
  deleteArmingSchedule(dealerId, siteId, scheduleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/arming_schedules/${scheduleId}`;
    return this.api.delete(url);
  }
  updateSiteSchdeulEanbled(dealerId, siteId, scheduleEnabled) {
    let url = `/dealers/${dealerId}/sites/${siteId}`;
    const data = {arming_schedule_enabled: scheduleEnabled}
    return this.api.put(url, data);
  }

  //Site Automation Rule
  createSiteAutomationRule(dealerId, siteId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules`;
    return this.api.post(url, data);
  }
  getAllSiteAutomationRule(dealerId, siteId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules`;
    return this.api.get(url).pipe(
      map(res => {
        this.siteRules = res;
        return res;
      }));
  }
  updateSiteAutomationRule(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}`;
    return this.api.put(url, data);
  }
  deleteSiteAutomationRule(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}`;
    return this.api.delete(url);
  }

  //Site Automation Rule Trigger
  createSiteAutomationRuleTrigger(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger`;
    return this.api.post(url, data);
  }
  updateSiteAutomationRuleTrigger(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger`;
    return this.api.put(url, data);
  }
  deleteSiteAutomationRuleTrigger(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleTriggerMP(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger/mp`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleTriggerArming(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger/arming`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleTriggerCameras(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/trigger/cameras`;
    return this.api.delete(url);
  }

  //Site Automation Rule Condition
  createSiteAutomationRuleCondition(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition`;
    return this.api.post(url, data);
  }
  updateSiteAutomationRuleCondition(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition`;
    return this.api.put(url, data);
  }
  deleteSiteAutomationRuleCondition(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleConditionAllTime(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition/times`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleConditionTime(dealerId, siteId, ruleId, timeConditionId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition/times/${timeConditionId}`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleConditionArming(dealerId, siteId, ruleId, armingConditionId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/condition/arming/${armingConditionId}`;
    return this.api.delete(url);
  }

  //Site Automation Rule Action
  createSiteAutomationRuleAction(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action`;
    return this.api.post(url, data);
  }
  updateSiteAutomationRuleAction(dealerId, siteId, ruleId, data) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action`;
    return this.api.put(url, data);
  }
  deleteSiteAutomationRuleAction(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionAllRecords(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/records`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionRecord(dealerId, siteId, ruleId, recordActionId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/records/${recordActionId}`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionAllRelays(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/relays`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionRelay(dealerId, siteId, ruleId, relayActionId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/relays/${relayActionId}`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionAllTalkdowns(dealerId, siteId, ruleId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/talkdowns`;
    return this.api.delete(url);
  }
  deleteSiteAutomationRuleActionTalkdown(dealerId, siteId, ruleId, talkdownActionId) {
    let url = `/dealers/${dealerId}/sites/${siteId}/automation_rules/${ruleId}/action/talkdowns/${talkdownActionId}`;
    return this.api.delete(url);
  }

  // Virtual guard tour
  updateSiteVirtualGuardTourInfo(dealerId, siteId, scheduleEnabled) {
    let url = `/dealers/${dealerId}/sites/${siteId}`;
    const data = {guard_tour_schedule_enabled: scheduleEnabled}
    return this.api.put(url, data);
  }

  // timelapse
  updateSiteTimelapse(dealerId, siteId, enabled) {
    let url = `/dealers/${dealerId}/sites/${siteId}`;
    const data = {guard_tour_schedule_timelapse_enabled: enabled}
    return this.api.put(url, data);
  }

  // Procedure
  getAllSiteProcedures(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/procedures`).map(res => {
      this.procedureList = res
      return res
    })
  }

  // redeployment
  redeploySite(dealerId, siteId, data) {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/redeployment`, data)
  }

  // site plan - video vault / video vault plus
  getSitePlan(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/plan`).map(res => {
      this.sitePlan$s.next(res)
      return res;
    })
  }

  updateSitePlan(dealerId, siteId, data){
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/plan`, data)
  }

  requestSitePlanUpdate(dealerId, siteId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/site-plan-update-request`, data);
  }
  approveSitePlanUpdate(dealerId, siteId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/site-plan-update-approval`, data);
  }
  rejectSitePlanUpdate(dealerId, siteId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/site-plan-update-request`);
  }

  // update all devices
  updateDevices(dealerId, siteId, data): Observable<any> {
    return this.api.patch(`/dealers/${dealerId}/sites/${siteId}/devices`, data);
  }

  // use when test https request
  // testSiteAutomationRuleHttpRequest(data) {
  //   let auth = null;
  //   let credential = null;
  //   let contentType = null;
  //   let url = data.url
  //   let header = null

  //   if (data.auth === 'noauth') {
  //     auth = 'No Auth';
  //   }
  //   else if (data.auth === 'basic') {
  //     credential = btoa(`${data.username}:${data.password}`);
  //     auth = 'Basic ' + credential;
  //   }
  //   else if (data.auth === 'digest') {
  //     credential = btoa(`${data.username}:${data.password}`);
  //     auth = 'Digest ' + credential;
  //   }

  //   if (data.contentType == 'json') contentType = 'application/json'
  //   else if (data.contentType == 'text') contentType = 'text/plain'
  //   else if (data.contentType == 'custom') contentType = data.contentTypeCustom

  //   header = new HttpHeaders({
  //     'Authorization' : auth,
  //     'Content-Type' : contentType,
  //     'Access-Control-Allow-Origin' : '*',
  //     // 'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
  //     // 'Access-Control-Allow-Methods': 'PUT'
  //   });

  //   const options = { headers: header, responseType: "json" };

  //   if (data.method === 'get') return this.api.getTestAutomationRuleHttpRequest(url, options);
  // }
}
