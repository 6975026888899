import { of as observableOf, Observable, BehaviorSubject } from "rxjs";

import { map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable()
export class UsersService {
  me: any;
  users: any;
  users$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  users$w = this.users$s.asObservable();

  my_ip$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  my_ip$w = this.my_ip$s.asObservable();

  public isTemporaryLogin = false;
  public tsId = -1;

  meChange: EventEmitter<any> = new EventEmitter();
  constructor(private api: ApiService) {}

  public getMe(dealer_id?): Observable<any> {
    if ((this.me && !dealer_id) || dealer_id == this.tsId) {
      return observableOf(this.me);
    }
    let urlPath = "/users/me/dealer";
    if (dealer_id) {
      urlPath = urlPath + "?dealer_id=" + dealer_id;
    }
    return this.api.get(urlPath).pipe(
      map((res) => {
        this.me = res[0];
        if (this.me.has_access_dealer === 1 && dealer_id) {
          this.isTemporaryLogin = true;
          this.tsId = dealer_id;
        }
        localStorage.setItem("curUser", JSON.stringify(this.me));
        return this.me;
      })
    );
  }

  public isTempoLogin() {
    return this.isTemporaryLogin;
  }

  fetchMe(dealer_id?): Observable<any> {
    let urlPath = "/users/me/dealer";
    if (dealer_id) {
      urlPath = urlPath + "?dealer_id=" + dealer_id;
    }
    return this.api.get(urlPath).pipe(
      map((res) => {
        this.me = res[0];
        if (this.me.has_access_dealer === 1 && dealer_id) {
          this.isTemporaryLogin = true;
          this.tsId = dealer_id;
        }
        localStorage.setItem("curUser", JSON.stringify(this.me));
        return this.me;
      })
    );
  }

  public getMyIp(): Observable<any> {
    return this.api.get("/users/me/ip").map(res => {
      this.my_ip$s.next(res);
      return res
    })
  }

  fetch(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/members`).pipe(
      map((res) => {
        this.users = res;
        this.users$s.next(res);
        return this.users;
      })
    );
  }

  emitMeChangeEvent(data) {
    this.meChange.emit(data);
  }
  getMeChangeEmitter() {
    return this.meChange;
  }

  public getUsers(dealer_id: number): Observable<any> {
    return this.fetch(dealer_id);
  }

  public getUserOrders(dealer_id: number, user_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/members/${user_id}/orders`);
  }

  getIpMembers(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/ip_members/`);
  }

  getIpMember(dealer_id: number, memberId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/ip_members/${memberId}`);
  }

  public search(str: string) {
    if (!this.users) {
      return [];
    }

    const search_text = str.toLowerCase();
    return this.users.filter((user) => {
      if (user.email === null || user.firstname === null || user.lastname === null) {
        return false;
      }
      let text = user.email.toLowerCase();
      text += user.firstname ? user.firstname.toLowerCase() : "";
      text += user.lastname ? user.lastname.toLowerCase() : "";

      if (search_text) {
        return text.search(search_text) > -1;
      } else {
        return false;
      }
    });
  }

  public createUsers(dealer_id: number, data: any): Observable<any> {
    const post_data = {
      email: data.email,
      role_id: data.role_id,
      access_id: data.access_id,
      is_division_user: data?.is_division_user //!! TODO
    };

    return this.api.post(`/dealers/${dealer_id}/members`, post_data);
  }

  public createIpUser(dealer_id: number, data: any): Observable<any> {
    let post_data = {
      ip: data.ip,
      name: data.username,
      access_id: data.access_id,
    };

    return this.api.post(`/dealers/${dealer_id}/ip_members`, post_data);
  }

  public inviteUsers(dealer_id: number, user_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/members/${user_id}/invite`);
  }

  public deleteUsers(dealer_id: number, user_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/members/${user_id}`);
  }

  public deleteIpUser(dealer_id: number, user_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/ip_members/${user_id}`);
  }

  update(dealer_id: number, user_id: number, data: any): Observable<any> {
    const put_data = {};
    if (data.role_id) {
      put_data["role_id"] = data.role_id;
    }
    if (data.access_id) {
      put_data["access_id"] = data.access_id;
    }
    put_data["is_division_user"] = data.is_division_user ? 1 : 0;
    put_data["has_access_dealer_app"] = data.has_access_dealer_app;
    put_data["has_access_mobile_app"] = data.has_access_mobile_app;
    put_data["has_access_monitoring_app"] = data.has_access_monitoring_app;

    return this.api.put(`/dealers/${dealer_id}/members/${user_id}`, put_data);
  }

  updateIpUser(dealer_id: number, user_id: number, data: any): Observable<any> {
    let put_data = {};
    put_data["name"] = data.name;
    put_data["ip"] = data.ip;
    put_data["access_id"] = data.access_id;
    if (data.role_id) {
      put_data["role_id"] = data.role_id;
    }
    return this.api.put(`/dealers/${dealer_id}/ip_members/${user_id}`, put_data);
  }

  getUserWorkday(dealer_id: number, user_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/members/${user_id}/workdays`);
  }

  updateUserWorkday(dealer_id: number, user_id: number, workday_id: number, data: any): Observable<any> {
    const put_data = {
      enable: data.enable,
      time_from: data.time_from,
      time_to: data.time_to,
    };
    return this.api.put(`/dealers/${dealer_id}/members/${user_id}/workdays/${workday_id}`, put_data);
  }

  createUserWorkday(dealer_id: number, user_id: number): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/members/${user_id}/workdays`, {});
  }

  sendResetPasswordUrl(dealerId, memberId) {
    let apiPath = `/dealers/${dealerId}/members/${memberId}/password_reset`;
    return this.api.put(apiPath, {});
  }

  sendSiteMemberResetPasswordUrl(dealerId, siteId, memberId) {
    let apiPath = `/dealers/${dealerId}/sites/${siteId}/members/${memberId}/password_reset`;
    return this.api.put(apiPath, {});
  }

  getAppUsers(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/site-members`)
  }

  getAppUser(dealer_id: number, site_member_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/site-members/${site_member_id}`)
  }
}
