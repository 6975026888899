import { Component, HostListener, Input, Output, ViewChild, ViewChildren, ElementRef, QueryList, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { PagerService } from '@app/services/pager.service';
import { CommonService } from '@app/services/common.service';
import { Helper } from '../../../../../4services/2helper'
import { c_components } from 'src/3ui/2components';
import ims from '../../imports'

@Component({
  selector: 'c_dtable_cloud_vms_manager_for_site',
  templateUrl: './c_dtable_cloud_vms_manager_for_site.component.pug',
  styleUrls: ['../../common.scss', '../cloud_vms_manager.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class c_dtable_cloud_vms_manager_for_site_component {
  @Input() siteList: any;
  @Input() isLoading: boolean;
  @Output() refresh = new EventEmitter<boolean>();
  @ViewChild('selectDropdown') selectDropdown: ElementRef;
  @ViewChildren('siteActionMenus') siteActionMenus: QueryList<any>
//-----------------------------------------------------------------------------

  tableHeader = [
    // downgrade 로직 때문에 bulk update는 일단 숨겨두자
    // { name: '', value: null, width: "5%", isCheckbox: true },
    { name: 'SITE NAME', value: 'site_name', width: "20%", isCheckbox: false },
    { name: 'DEALER NAME', value: 'dealer_name', width: "20%", isCheckbox: false },
    { name: 'NUMBER OF CAMERAS', value: 'number_of_cameras', width: "10%", isCheckbox: false },
    { name: 'VIDEO EVENTS', value: 'video_events', width: "10%", isCheckbox: false },
    { name: 'SITE PLAN', value: 'site_plan', width: "30%", isCheckbox: false },
    { name: '', value: null, width: "5%", isCheckbox: false },
  ]

  filteredSiteList = [];
  originalSiteList = [];
  pagedItems = [];

  isAllSelected = false;
  selectedSiteList = [];

  isFocusSearch = false;
  searchText = '';

  basicPlanCount = 0
  videoVaultPlanCount = 0
  videoVaultPlusPlanCount = 0
  videoVaultPlusAICount = 0

  // select box
  isUnfoldPlanDropdown = false;
  sitePlanList = [
    { label: 'Basic', value: 1 },
    { label: 'Video Vault', value: 2 },
    { label: 'Video Vault Plus', value: 3 },
    { label: 'Video Vault Plus AI', value: 4 },
  ]
  selectedSitePlan = this.sitePlanList[0];

  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;

  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    public c_components: c_components,
    public helper: Helper,
  ) { }

  ngOnInit() {
    this.originalSiteList = ims._.cloneDeep(this.siteList);
    this.filteredSiteList = ims._.cloneDeep(this.siteList);
    this.selectedSitePlan = this.sitePlanList[0];
    this.setPage(1)
  }

  ngOnChanges(changes): void {
    if(changes.siteList){
      this.originalSiteList = ims._.cloneDeep(this.siteList);
      this.filteredSiteList = ims._.cloneDeep(this.siteList);
      this.setPage(1)
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.siteActionMenus) {
      const siteActionMenus = this.siteActionMenus.toArray();
      siteActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(site=> {
          if (`site-${site.site_id}` === actionMenu.nativeElement.id) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              site.isShowMenu = false;
            }
          }
        })
      })
    }
    if (this.selectDropdown) {
      if (!this.selectDropdown.nativeElement.contains(event.target)) {
        event.stopPropagation()
        this.isUnfoldPlanDropdown = false;
      }
    }
  }

  // --------------------------------------------------------------------------------
  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById('site-'+id);
    let more = document.getElementById('more-site-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect?.right - 10;
    const posY = rect?.bottom - 45;

    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(site){
    const targetMenu = site.isShowMenu;
    site.isShowMenu = !targetMenu;
  }

  // Select box
  toggleAllCheckbox(event: Event) {
    // 최대 체크된 수 제한 (100개)
    const maxChecked = 100;
    const currentCheckedCount = this.siteList.length;
    const target = event.target as HTMLInputElement;

    if (this.isAllSelected || currentCheckedCount < maxChecked) {
      this.isAllSelected = !this.isAllSelected
      this.siteList.forEach(site => site.isSelected = this.isAllSelected)
      this.filteredSiteList.forEach(site => site.isSelected = this.isAllSelected)
      this.isAllSelected
        ? this.selectedSiteList = ims._.cloneDeep(this.siteList)
        : this.selectedSiteList = []
    } else {
      // 상태 변경을 취소
      event.preventDefault();
      target.checked = this.isAllSelected;
      this.openWarningDialog('Warning', 'You can select up to 100 sites.', 'warning', 'orange');
    }
  }

  toggleCheckbox(site, event: Event) {
    const maxChecked = 100;
    const currentCheckedCount = this.selectedSiteList.length;
    const eventTarget = event.target as HTMLInputElement;

    const target = this.siteList.find(item => item?.site_id === site?.site_id);
    if (site.isSelected || currentCheckedCount < maxChecked) {
      site.isSelected = !site.isSelected;
      target.isSelected = !target.isSelected;
    } else {
      event.preventDefault();
      eventTarget.checked = site.isSelected;
      this.openWarningDialog('Warning', 'You can select up to 100 sites.', 'warning', 'orange');
    }
    this.selectedSiteList = this.siteList.filter(site => site.isSelected);
    this.isAllSelected = this.siteList.every(site => site.isSelected);
  }

  toggleSelectDropdown(e){
    e?.stopPropagation()
    this.isUnfoldPlanDropdown = !this.isUnfoldPlanDropdown
  }

  selectSitePlan(event, plan){
    event?.stopPropagation();
    this.selectedSitePlan = plan;
  }

  // STYLES
  checkTotalCount(target){
    this.basicPlanCount = 0
    this.videoVaultPlanCount = 0
    this.videoVaultPlusPlanCount = 0
    this.videoVaultPlusAICount = 0

    target.forEach(site => {
      if(site?.site_plan_id === 1) this.basicPlanCount++
      if(site?.site_plan_id === 2) this.videoVaultPlanCount++
      if(site?.site_plan_id === 3) this.videoVaultPlusPlanCount++
      if(site?.site_plan_id === 4) this.videoVaultPlusAICount++
      if(!site?.site_plan_id) this.basicPlanCount++
    })
  }

  computedPlanBadgeStyle(plan){
    switch(plan){
      case 1: return 'basic-badge'
      case 2: return 'video-vault-plan-badge'
      case 3: return 'video-vault-plus-plan-badge'
      case 4: return 'video-vault-plus-ai-plan-badge'
      default: return 'basic-badge'
    }
  }

  parseSitePlan(sitePlanId){
    switch(sitePlanId){
      case 1: return 'Basic';
      case 2: return 'Video Vault';
      case 3: return 'Video Vault Plus';
      case 3: return 'Video Vault Plus AI';
      default: return 'Basic';
    }
  }

  determineElementToShowInMPDirection(){
    if(this.isLoading) return false
    if(!this.siteList?.length) return 'no-data'
    return 'normal'
  } 

  goToDetail(event, site){
    event.stopPropagation();
    this.helper.router.navigate_to(`/customers/vms-service`, {id: site.site_id});
  }
  // -----------------------------------------------------------------------------
  setSearchFocus() {
    this.isFocusSearch = true;
  }
  
  search(){
    if (this.searchText.length == 0 || this.searchText.length < 3) { 
      this.filteredSiteList = this.originalSiteList
      this.setPage(1)
      return
    }
    this.filteredSiteList = this.searchSite()
    this.setPage(1)
  }
  searchSite(){
    let search_text = this.searchText.toLowerCase();
    return this.originalSiteList.filter(site => {
      let text = site.name.toLowerCase() +
                site.dealer_name.toLowerCase() 

      if(search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  // -----------------------------------------------------------------------------
  // ACTION
  async onClickBulkUpdatePlan(){
    try {
      this.isLoading = true;
      const selectedSiteIds = this.selectedSiteList.map(site => site.site_id);
      const data = { site_plan_id: this.selectedSitePlan.value, site_ids: selectedSiteIds };
      await this.helper.dealer.bulk_update_dealer_site_plan(data);
      this.refresh.emit();

      this.selectedSiteList = [];
      this.siteList.forEach(site => site.isSelected = false);
      this.setPage(1)
      this.openWarningDialog('Success', 'Plan has been updated successfully.', 'done', 'green');
      this.isLoading = false;
    } catch(err) {
      this.isLoading = false;
      this.openWarningDialog('Error', 'Failed to update Device Plan.', 'warning', 'red');
    }
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string | null, b: number | string | null, isAsc: boolean) {
    if (a === null && b === null) return 0; 
    if (a === null) return isAsc ? -1 : 1; 
    if (b === null) return isAsc ? 1 : -1; 
    
    if (typeof a === 'string') a = a.toLocaleLowerCase();
    if (typeof b === 'string') b = b.toLocaleLowerCase();
    
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value?.replace(/,/g, ""))
  }

  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredSiteList;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_name': 
          return this.compare(a.name, b.name, isAsc);
        case 'dealer_name': 
          return this.compare(a.dealer_name, b.dealer_name, isAsc);
        case 'number_of_cameras': 
          return this.compare(a.device_count, b.device_count, isAsc);
        case 'video_events': 
          return this.compare(this.formatNumberWithCommaToNumber(a.video_events), this.formatNumberWithCommaToNumber(b.video_events), isAsc);
        case 'site_plan': 
          return this.compare(a.site_plan_id, b.site_plan_id, isAsc);
        default: return 0;
      }
    });
    
    this.filteredSiteList = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.filteredSiteList;
    this.checkTotalCount(target)
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }

  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }

}