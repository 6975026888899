import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { c_dialog_basic_component } from "./basic/c_dialog_basic_component";
import { c_dialog_dealer_role_component } from "./dealer_role/c_dialog_dealer_role_component";
import { c_dialog_dealer_division_component } from "./dealer_division/c_dialog_dealer_division_component";
import { c_dialog_dealer_division_add_sites_component } from "./dealer_division/add_sites/c_dialog_dealer_division_add_sites_component";
import { c_dialog_dealer_division_add_users_component } from "./dealer_division/add_users/c_dialog_dealer_division_add_users_component";
import { c_dialog_dealer_division_clear_component } from "./dealer_division/clear_division/c_dialog_dealer_division_clear_component";
import { c_dialog_dealer_member_add_user_component } from "./dealer_member/c_dialog_dealer_member_add_user_component";
import { c_dialog_dealer_payment_sepa_component } from "./dealer_payment/sepa/c_dialog_dealer_payment_sepa_component";
import { c_dialog_event_storage_region_component } from "./event-storage-region/c_dialog_event_storage_region_component";
import { c_dialog_score_event_viewer_component } from "./score_event_viewer/c_dialog_score_event_viewer_component";
import { c_dialog_share_incident_report_email_component } from "./share-incident-report-email/c_dialog_share_incident_report_email_component";
import { c_dialog_share_incident_report_log_component } from "./share-incident-report-log/c_dialog_share_incident_report_log_component";
import { c_dialog_mp_direction_procedure_component } from "./mp-direction-procedure/c_dialog_mp_direction_procedure_component";
import { c_dialog_mp_direction_copy_paste_component } from "./mp-direction-copy-paste/c_dialog_mp_direction_copy_paste_component";
import { c_dialog_mp_settings_bulk_update_component } from "./mp_settings_bulk_update/c_dialog_mp_settings_bulk_update_component";
import { c_dialog_mp_settings_bulk_update_confirm_component } from "./mp_settings_bulk_update_confirm/c_dialog_mp_settings_bulk_update_confirm_component";
import { c_dialog_arming_mode_after_schedule_component } from "./arming_mode_after_schedule/c_dialog_arming_mode_after_schedule_component";
import { c_dialog_site_default_settings_component } from "./site-default-settings/c_dialog_site_default_settings_component";
import { c_dialog_site_plan_settings_component } from "./site_plan_settings/c_dialog_site_plan_settings_component";
import { c_dialog_device_reset_config_component } from "./device_reset_config/c_dialog_device_reset_config_component";
import { c_dialog_app_user_copy_component } from "./app_user/copy_appUser/c_dialog_app_user_copy_component";
import { c_dialog_app_user_delete_component } from "./app_user/delete_appUser/c_dialog_app_user_delete_component";
import { c_dialog_app_user_remove_component } from "./app_user/remove_appUser/c_dialog_app_user_remove_component";

import { c_dialog_warning_component } from "./warning/c_dialog_warning_component";


import { isString } from "lodash";

@Injectable()
export class c_dialog {
  constructor(private matDialog: MatDialog) {}

  public open(dialog_name: string, data: any, func?: any): MatDialogRef<any> {
    return this.open_and_return_dialog(dialog_name, data);
  }

  public open_and_return_dialog(dialog_name: string, data: any, func?:any): MatDialogRef<any> {
    const component_to_open = this.get_dialog(dialog_name);
    const dialogRef = this.matDialog.open(component_to_open, {
      panelClass: "c_dialog_container",
      autoFocus: false,
      data,
    });

    return dialogRef
  }

  public open_and_wait_result(dialog_name: string, data: any): Promise<any> {
    return this.open(dialog_name, data).afterClosed().toPromise();
  }

  private get_dialog(dialog_name: string): any {
    dialog_name = this.sanitize_dialog_name(dialog_name);
    if (dialog_name === "c_dialog_basic_component") return c_dialog_basic_component;
    if (dialog_name === "c_dialog_dealer_role_component") return c_dialog_dealer_role_component;
    if (dialog_name === "c_dialog_dealer_division_component") return c_dialog_dealer_division_component;
    if (dialog_name === "c_dialog_dealer_division_add_sites_component") return c_dialog_dealer_division_add_sites_component;
    if (dialog_name === "c_dialog_dealer_division_add_users_component") return c_dialog_dealer_division_add_users_component;
    if (dialog_name === "c_dialog_dealer_division_clear_component") return c_dialog_dealer_division_clear_component;
    if (dialog_name === "c_dialog_dealer_member_add_user_component") return c_dialog_dealer_member_add_user_component;
    if (dialog_name === "c_dialog_dealer_payment_sepa_component") return c_dialog_dealer_payment_sepa_component;
    if (dialog_name === "c_dialog_event_storage_region_component") return c_dialog_event_storage_region_component;
    if (dialog_name === "c_dialog_score_event_viewer_component") return c_dialog_score_event_viewer_component;
    if (dialog_name === "c_dialog_share_incident_report_email_component") return c_dialog_share_incident_report_email_component;
    if (dialog_name === "c_dialog_share_incident_report_log_component") return c_dialog_share_incident_report_log_component;
    if (dialog_name === "c_dialog_mp_direction_procedure_component") return c_dialog_mp_direction_procedure_component;
    if (dialog_name === "c_dialog_mp_direction_copy_paste_component") return c_dialog_mp_direction_copy_paste_component;
    if (dialog_name === "c_dialog_mp_settings_bulk_update_component") return c_dialog_mp_settings_bulk_update_component;
    if (dialog_name === "c_dialog_mp_settings_bulk_update_confirm_component") return c_dialog_mp_settings_bulk_update_confirm_component;
    if (dialog_name === "c_dialog_arming_mode_after_schedule_component") return c_dialog_arming_mode_after_schedule_component;
    if (dialog_name === "c_dialog_site_default_settings_component") return c_dialog_site_default_settings_component;
    if (dialog_name === "c_dialog_site_plan_settings_component") return c_dialog_site_plan_settings_component;
    if (dialog_name === "c_dialog_device_reset_config_component") return c_dialog_device_reset_config_component;
    if (dialog_name === "c_dialog_app_user_copy_component") return c_dialog_app_user_copy_component;
    if (dialog_name === "c_dialog_app_user_delete_component") return c_dialog_app_user_delete_component;
    if (dialog_name === "c_dialog_app_user_remove_component") return c_dialog_app_user_remove_component;

    if (dialog_name === "c_dialog_warning_component") return c_dialog_warning_component;
    return c_dialog_basic_component;
  }

  private sanitize_dialog_name(dialog_name: string): string {
    if (!isString(dialog_name)) dialog_name = "";
    dialog_name = this.add_prefix_on_dialog_name_if_not_provided(dialog_name);
    dialog_name = this.add_suffix_on_dialog_name_if_not_provided(dialog_name);
    return dialog_name;
  }

  private add_prefix_on_dialog_name_if_not_provided(dialog_name: string): string {
    const prefix = "c_dialog_";
    if (dialog_name.startsWith("c_dialog_")) return dialog_name;
    return `${prefix}${dialog_name}`;
  }

  private add_suffix_on_dialog_name_if_not_provided(dialog_name: string): string {
    const suffix = "_component";
    if (dialog_name.endsWith("_component")) return dialog_name;
    return `${dialog_name}${suffix}`;
  }
}
