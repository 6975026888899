
import {throwError as observableThrowError, of as observableOf,  BehaviorSubject ,  Subscription ,  Observable } from 'rxjs';

import {delay, catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from './api.service';
import { Devices } from '../model/devices';

import auth0_auth from "../../2loaders/auth0_auth"

@Injectable()
export class DevicesService {

  devices: any;
  curDeviceArmState = '';

  devices$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  devices$w = this.devices$s.asObservable()

  device$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  device$w = this.device$s.asObservable()

  onOpenBridgeRegister: EventEmitter<boolean> = new EventEmitter();

  public optex = {
    defaultUserId: 'root',
    defaultUserPw: 'OPTEX'
  };

  public axis = {
    defaultUserId: 'root',
    defaultUserPw: 'pass'
  };

  public chektCam = {
    defaultUserId: 'admin',
    defaultUserPw: 'admin'
  };

  constructor(private api: ApiService, private sanitizer: DomSanitizer) { }

  makeid() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  public getDevices(dealer_id: number, filter?,isForce= false): Observable<any> {
    if (this.devices && !isForce) {
      return observableOf(this.devices);
    }
    let query = `/dealers/${dealer_id}/devices`;
    if (filter) {
      query += '?' + filter;
    }
    return this.api.get(query).pipe(
      map(res => {
        this.devices = res;
        this.devices$s.next(res);
        return res;
      }));
  }

  public getAllAudios(dealer_id: number, filter?): Observable<any> {
    let query = `/dealers/${dealer_id}/audio_devices`;
    if (filter) {
      query += '?' + filter;
    }
    return this.api.get(query);
  }

  public getDevice(dealer_id: number, site_id: number, device_id: string): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}`).map(res => {
      this.device$s.next(res)
      return res
    })
  }

  public search(str: string) {
    if (!this.devices)
      return [];

    let search_text = str.toLowerCase();
    return this.devices.filter(device => {
      let text = '';
      if (device.name) text += device.name.toLowerCase();
      if (device.mac) text += device.mac.toLowerCase();

      if (search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  // device Migration
  public migrate(dealer_id: number, site_id: number, device_id: number, destination_site_id: number): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}/migration`, {destination_site_id})
  }

  getSnapshotInfo(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/snapshot_url`);
  }

  getSnapshotFromStreamServer(url: string, access_token: string): Observable<any> {
    let header = new HttpHeaders({});
    let options = { headers: header};

    return this.api.get_custom_fullpath(url + `?access_token=${access_token}`, options);
  }


  //OLD
  createSiteDevice(dealer_id: number, site_id: number, data: any): Observable<any> {
    let post_data = { 'name': data.name, 'model': data.model, 'type': data.type, 'is_activated': 1 };
    return this.api.post(`/dealers/${dealer_id}/sites/${site_id}/devices`, post_data);
  }
  mountSiteDevice(dealer_id: number, site_id: number, deviceId: any, data: any): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/sites/${site_id}/devices/${deviceId}/mount`, data);
  }
  unmountSiteCamera(dealerId: number, siteId: number, deviceId: any): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/umount`);
  }
  deleteSiteDevice(device: Devices): Observable<any> {
    return this.api.delete(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`);
  }

  getSnapshot(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/snapshot`);
  }

  getSnapshotV2(snapshot_url): Observable<any> {
    return this.api.getSnasphot(snapshot_url);
  }

  setDoOnOff(device: Devices, index: number, on: boolean): Observable<any> {
    let query = `/dealers/${device.dealer_id}/devices/${device.device_id}/proxy?expand=`;
    query = query + encodeURIComponent("/nvc-cgi/admin/param.cgi?action=update&group=DIDO.Do.CH" + index + "&trig=" + (on ? "on" : "off"));
    return this.api.get(query, 'text');
  }

  getDeviceConfig(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config`);
  }

  getDeviceSensorConfig(device:Devices, bridgeId: any): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/device_groups/${bridgeId}/devices/${device.device_id}/sensors`);

  }

  getAlarmStatus(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/alarms`);
  }
  getSensorStatus(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/sensors`);
  }

  registerDevice(dealer_id: number, site_id: number, device_id: string, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}/register`, data);
  }

  updateDevice(device: Devices, data): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, data);
  }

  unregisterDevice(device: Devices): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/unregister`, {});
  }

  updateConfigVcaZone(device: Devices, zone: any, index: number): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/vca/zones/${index}`, zone);
  }

  updateConfigVcaRule(device: Devices, rule: any, index: number): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/vca/rules/${index}`, rule);
  }

  updateConfigTamperZone(device: Devices, tamper: any): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/vca/tamper`, tamper);
  }

  updateConfigSensor(device: Devices, data: any, index: number): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/sensors/${index}`, data);
  }

  updateConfigAuth(device: Devices, data: any): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/auth`, data);
  }

  updateConfigEvent(device: Devices, data: any): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/event`, data);
  }

  updateConfigEvtRules(device: Devices, data: any, index: number): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/config/event/rules/${index}`, data);
  }


  updateDeviceZone(device: Devices, data): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, data);
  }

  updateDeviceName(device: Devices, name: string): Observable<any> {
    let put_data = {
      'name': name
    }

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceZoneNumber(device: Devices, newVirtualZone: string): Observable<any> {
    let put_data = {
      'virtual_zone': newVirtualZone
    }

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceVideoStream(device: Devices, profile_token: string): Observable<any> {
    let put_data = {
      'video_profile_token': profile_token
    }

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceEntryDelay(device: Devices, entryDelay: number): Observable<any> {
    let put_data = {
      'entry_delay': entryDelay
    };

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceExitDelay(device: Devices, exitDelay: number): Observable<any> {
    let put_data = {
      'exit_delay': exitDelay
    };

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceBellDuration(device: Devices, bellDuration: number): Observable<any> {
    let put_data = {
      'bell_duration': bellDuration
    };

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceSpeakerVolume(device: Devices, volume: number): Observable<any> {
    let put_data = {
      'speaker_volume': volume
    };

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  updateDeviceCapabilities(device: Devices, capabilities): Observable<any> {
    let put_data = capabilities

    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  putDeviceSensorConfig(device:Devices, bridgeId: any, data): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/device_groups/${bridgeId}/devices/${device.device_id}/sensors`, data);
  }

  putDeviceArmingType(device: Devices, armingType, normalState): Observable<any>  {
    let put_data = {
      'arming_type': armingType,
      'normal_state': normalState // nc, no
    };
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, put_data);
  }

  putDeviceArm(device: Devices, armingType, normalState?): Observable<any>  {
    let armData = {
      'arming_type': armingType,
    };
    if (normalState) {
      armData['normal_state'] = normalState;
    }
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/arming`, armData);
  }

  replaceCamera(dealer_id: number, site_id: number, deviceId: any, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/devices/${deviceId}/replace`, data);
  }

  getBridgeDevices(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/groups`);
  }

  addDeviceGroup(bridge: Devices, device_id, group_channel, channel): Observable<any> {

    let data = {
      'group_channel': group_channel,
      'channel': channel
    };

    return this.api.post(`/dealers/${bridge.dealer_id}/sites/${bridge.site_id}/devices/${device_id}/groups/${bridge.device_id}`, data);
  }

  delDeviceGroup(bridge: Devices, device_id): Observable<any> {
    return this.api.delete(`/dealers/${bridge.dealer_id}/sites/${bridge.site_id}/devices/${device_id}/groups/${bridge.device_id}`);
  }

  rebootDevices(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/reboot`);
  }

  discoveryDevices(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/discovery/device`);
  }

  discoveryStart(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/discovery`);
  }

  updateFirmware(device: Devices, firmware_id: number, auto_update: number): Observable<any> {
    let data = { 'firmware_id': firmware_id, 'auto_update': auto_update };
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/firmware`, data);
  }

  getDeviceMac(dealer_id: number, mac: string): Observable<any> {
    let site_id = 0;
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/devices/${mac}/exist`);
  }

  getAuth(device: Devices, username, password, ip): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/auth?username=${username}&password=${password}&ip=${ip}`);
  }

  getTmpSnapshot(device: Devices, username, password, ip, channel): Observable<any> {
    let header = new HttpHeaders({ 'Authorization': 'Bearer ' + auth0_auth.idToken });
    let options = { headers: header, responseType: 'blob'};

    var urlCreator = window.URL;

    return this.api.get_custom(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/tmpsnapshot?username=${username}&password=${password}&ip=${ip}&channel=${channel}`, options).pipe(
      map(res => {
        return new Blob([res.blob()], { type: "image/jpeg" });
      }),
      map(blob => {
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      }),
      catchError(err => observableThrowError(err)),);
  }

  getEndpointFirmware(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/firmwares`);
  }

  getEndpointFirmwares(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/firmwares`);
  }

  getReservedZoneNumbers(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/reserved_zones`);
  }

  getAllPlans(dealer_id: number, type?: string): Observable<any> {
    if (type)
      return this.api.get(`/dealers/${dealer_id}/invoices/plans/${type}`);
    else
      return this.api.get(`/dealers/${dealer_id}/invoices/plans`);
  }

  getCharges(device: Devices): Observable<any> {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/charges`);
  }

  createCharges(device: Devices, data ) {
    return this.api.post(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/charges`, data);
  }

  updateCharges(device: Devices, charge_id: number, data) {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/charges`, data);
  }

  updatePrivacyEnable(device: Devices): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/privacy_enable`, {});
  }

  uploadPrivacyBackground(device: Devices, data: any, content_type: string) {
    let result = new BehaviorSubject<boolean>(null);

    let ext = content_type.split('/')[1];
    this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/privacybg`, { format: ext }).subscribe(
      res => {
        // let uploadUrl = res['url'];

        // let header = new Headers({ 'content-type': content_type });
        // let options = new RequestOptions({ headers: header });
        let uploadUrl = res['url'];
        let header = new HttpHeaders({
          'content-type': content_type
        });
        let options = { headers: header, responseType: 'text' };
        this.api.put_custom(uploadUrl, data, options).subscribe(
          res => {
            setTimeout(_ => {
              result.next(true);
            }, 1000);
          }, err => {
            result.next(false);
          }
        )
      }, err => {
        result.next(false);
      }
    )

    return result;
  }

  deletePrivacyBackground(device: Devices): Observable<any> {
    return this.api.delete(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/privacybg`);
  }

  updateDeviceMetadata(dealer_id: number, site_id: string, device_id: string, position_metadata: string): Observable<any> {
    let put_data = { position_metadata: position_metadata };
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}`, put_data);
  }

  updateDevicePosition(dealer_id: number, site_id: string, device_id: string, position_metadata: string): Observable<any> {
    let put_data = { position_metadata: position_metadata };
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}/position`, put_data);
  }

  updateDeviceSensorsMeta(dealer_id: number, site_id: number, device_id: string, sensors_meta: string): Observable<any> {
    let put_data = { sensors_meta: sensors_meta};
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}`, put_data);
  }

  updateDeviceVideoAIAreaFilterMeta(device: any, data: any): Observable<any> {
    return this.api.put(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}`, data);
  }


  getEventStatistics(device: Devices, stime, etime) {
    return this.api.get(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/events/all/statistics/stime/${stime}/etime/${etime}`);
  }

  /**
   * proxy API - CKBAPI V2
   * max timeout = 60 sec.
   */
  proxy(dealerId, siteId, deviceId, data, timeout= 10, getHttpTimeout?, apiversion='v1.1', type='json') {
    let httpTimeout = 60 * 1000;
    if (getHttpTimeout) {
      httpTimeout = getHttpTimeout * 1000;
    }
    return this.api.proxyPut(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/proxy?timeout=${timeout}`, data, httpTimeout, apiversion, type);
  }

  asyncProxy(device, data, timeout= 20, getHttpTimeout?, apiversion='v1.1', type='json') {
    let httpTimeout = 60 * 1000;
    if (getHttpTimeout) {
      httpTimeout = getHttpTimeout * 1000;
    }
    return this.api.proxyPut(`/dealers/${device.dealer_id}/sites/${device.site_id}/devices/${device.device_id}/proxy?timeout=${timeout}`, data, httpTimeout, apiversion, type).pipe(delay(100));
  }

  //versions
  getBridgeVersion(dealer_id: number, site_id: number, device_id: string) {
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/devices/${device_id}/versions?sync=1`);
  }

  //voltage Preset
  getVoltagePresets(dealer_id: number){
    return this.api.get(`/dealers/${dealer_id}/voltage/presets`);
  }

  //mount status
  getInitMountStatus() {
    const initMountStatusLog = [
      {
        "description": "It is to discover the video device automatically by using various discovery methods.\n\t\tThis step is skipped if IP address of the video device is manully configured.\n\t\tWhen this step fails, the bridge will try mount the device by using the cached video stream URL if there is any.",
        "title": "Auto Discovery",
        "statusText": "Proceeding…",
        "isSuccess": true,
        "isSkipped": false,
        "endTimestamp": 0,
        "beginTimestamp": 0,
        "isStatus": 2
      },
      {
        "description": "It is to obtain necessary video device information via API handshakes.\n\t\tThe information includes: MAC address, Video sources, Video encoder configuration options, Stream URL and etc...\n\t\tWhen this step fails, the bridge will try mount the device by using the cached video stream URL if there is any.\n\t\tIt tests setting the primary video stream if the secondary video stream is not available on the video device.",
        "title": "API Connection",
        "statusText": "Waiting",
        "isSuccess": true,
        "isSkipped": false,
        "endTimestamp": 0,
        "beginTimestamp": 0
      },
      {
        "description": "It is to analyze the secondary stream by retrieving videos from the video stream URLs.\n\t\tIn this step, it will get necessary information(codec, resolution, and frame rate) of each video stream from the video stream (not via API).\n\t\tThe information can be obtained only when a video stream is connectable. So the video stream is not connectable if this step is failed.",
        "title": "Testing Stream",
        "statusText": "Waiting",
        "isSuccess": true,
        "isSkipped": false,
        "endTimestamp": 0,
        "beginTimestamp": 0
      },
      {
        "description": "It is to check compatibility of the secondary video stream by reviewing result from all previous steps.",
        "title": "Result",
        "statusText": "Waiting",
        "isSuccess": true,
        "isSkipped": false,
        "endTimestamp": 0,
        "beginTimestamp": 0
      }
    ];
    return initMountStatusLog;
  }

  //relay control
  controlDeviceRelay(dealerId, siteId, deviceId, relayCh, data) {
    // timeout, delay_times(momentary,timer), mode(latch,momentary,timer), with_snapshot(default-false)
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/relays/${relayCh}/control`, data);
  }

  getDeviceRelays(dealerId, siteId, deviceId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/relays`);
  }

  updateDeviceRelay(dealerId, siteId, deviceId, relayCh, data) {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/relays/${relayCh}`, data);
  }

  // audio control
  updateDeviceAudio(dealerId, siteId, deviceId, data) {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/audios/${deviceId}/position`, data);
  }

  ///cloud connection status
  getDeviceCloudConnectionStatus(dealerId, siteId, deviceId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/cloud_connection_status`);
  }

  //swinger bypass
  updateSwingerBypass(dealerId, siteId, deviceId, channel, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/channels/${channel}/swinger_bypass`;
    return this.api.put(url, data);
  }

  //Master bridge
  updateSiteMasterBridge(dealerId, siteId, deviceId, isEnable=true) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/master`;
    const data = {
      is_main_device: isEnable ? 1 : 0
    }
    return this.api.put(url, data);
  }

  //Firwamre Info
  getDeviceFirmwareInfo(dealerId, siteId, deviceId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/firmware_details`;
    return this.api.get(url);
  }

  // ONVIF setting
  getDeviceOnvifTopics(dealerId, siteId, deviceId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/onvif_rules`
    return this.api.get(url).map(
      res => {
        res.forEach(item => {
          item.onvif_topic = JSON.parse(item.onvif_topic).join(', ') // Array -> string
          item.onvifTopic = this.removeNamespace(item.onvif_topic);
          item.onvifTopicTxt = this.removeNamespace(item.onvif_topic)
        })
        res.sort((a, b) => this.compare(a.onvifTopic, b.onvifTopic, true))
        return res;
      }, err => {
        console.debug('getBrandOnvifTopics error', err);
      }
    );
  }

  updateDeviceOnvifTopic(dealerId, siteId, deviceId, ruleId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/onvif_rules/${ruleId}`
    return this.api.put(url, data);
  }

  resetDeviceOnvifTopic(dealerId, siteId, deviceId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/onvif-topics/reset`
    return this.api.put(url, data);
  }

  getBrandOnvifTopics(dealerId, cameraType) {
    const url = `/dealers/${dealerId}/onvif_topics/${cameraType}`
    return this.api.get(url).map(
      res => {
        res.forEach(item => {
          item.onvif_topic = JSON.parse(item.onvif_topic).join(', ') // Array -> string
          item.onvifTopic = this.removeNamespace(item.onvif_topic);
          item.onvifTopicTxt = this.removeNamespace(item.onvif_topic)
        })
        res.sort((a, b) => this.compare(a.onvifTopic, b.onvifTopic, true))
        return res;
      }, err => {
        console.debug('getBrandOnvifTopics error', err);
      }
    )
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  removeNamespace (input) {
    // tns1:RuleEngine/FieldDetector/ObjectsInside" -> "RuleEngine/FieldDetector/ObjectsInside"
    const regex = /\w+:/g;
    return input.replace(regex, '');
  }

  createDevoceOnvifTopic(dealerId, siteId, deviceId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/onvif_rules/`
    return this.api.post(url, data);
  }

  //site member camera notification
  updateSiteMemberCameraNotification(dealerId, siteId, memberId, deviceId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/members/${memberId}/devices/${deviceId}/apps/notifications`;
    return this.api.put(url, data);
  }

  // Local API Service
  getLocalApiService(dealerId, siteId, deviceId) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/apikey`;
    return this.api.get(url)
  }

  updateLocalApiService(dealerId, siteId, deviceId, data) {
    const url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/apikey`;
    return this.api.put(url, data)
  }

  // Video Events
  getStatisticsForDevice(dealerId, siteId, deviceId, stime, etime){
    let url = `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/event_statistics?stime=${stime}&etime=${etime}&type=di`;
    return this.api.get(url)
  }
}
