
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UsersService } from './users.service';

@Injectable()
export class DealerService {
  wcs: any;
  isSoftBlock: boolean = false;

  dealer_info$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_info$w = this.dealer_info$s.asObservable();
  partners$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  partners$w = this.partners$s.asObservable();
  dealer_sites_receivers$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_sites_receivers$w = this.dealer_sites_receivers$s.asObservable();
  dealer_site_plans$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_site_plans$w = this.dealer_site_plans$s.asObservable();
  dealer_site_plan_requests$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_site_plan_requests$w = this.dealer_site_plan_requests$s.asObservable();

  constructor(private api: ApiService, private usersService: UsersService) { }

  search(dealerId, query, filter= 'device,site,user'): Observable<any> {
    const encodedQuery = encodeURIComponent(query);
    return this.api.get(`/dealers/${dealerId}/search?query=${encodedQuery}&filter=${filter}`).pipe(
      map(res => {
        return res;
      }));
  }

  public getStatus(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/status`);
  }

  public getEventStats(dealer_id: number, stime: number, etime: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_stats/stime/${stime}/etime/${etime}`);
  }

  getDealerInfo(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}`).map(res =>{
      res.forEach(dealer => {
        if(dealer.is_overdue_invoice_payment) this.isSoftBlock = true;
        this.parseExternalDealerIdWithCompanyName(dealer)
      })
      this.dealer_info$s.next(res)
      return res
    });
  }

  getAllDealers(): Observable<any> {
    return this.api.get(`/dealers/`);
  }

  // sub dealer의 사이트인 경우 external_dealer_id와 함께 company_name를 표시하기 위한 함수
  parseExternalDealerIdWithCompanyName(dealer){
    const me = this.usersService.me;

    dealer.external_dealer_id_with_company_name = dealer.company_name
    if(dealer.dealer_id == me.dealer_id) return dealer.external_dealer_id_with_company_name = dealer.company_name
    if(dealer.type === 3) {
      dealer?.external_dealer_id
        ? dealer.external_dealer_id_with_company_name = `${dealer.external_dealer_id} - ${dealer.company_name}`
        : dealer.external_dealer_id_with_company_name = dealer.company_name
      return
    }
  }

  getSitePartners(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/site_partners`).map(res => {
      res.forEach(partner => this.parseExternalDealerIdWithCompanyName(partner))
      this.partners$s.next(res)
      return res
    });
  }

  getPartners(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/partners`).map(res => {
      res.forEach(partner => this.parseExternalDealerIdWithCompanyName(partner))
      return res
    });
  }

  getSharedDealers(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/shared_dealers`);
  }

  getDealerSitesReceivers(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/sites/receiver_info`).map(res => {
      this.dealer_sites_receivers$s.next(res)
      return res
    });
  }

  // site plan
  getDealerSitePlans(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/site-plans`).map(res => {
      this.dealer_site_plans$s.next(res)
      return res
    });
  }

  bulkUpdateDealerSitePlans(dealerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/bulk-site-plans`, data);
  }

  getSitePlanUpdateRequests(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/site-plan-update-requests`).map(res => {
      this.dealer_site_plan_requests$s.next(res)
      return res;
    });
  };
  // ----------------------------

  updateDealerInfo(dealerId, data):Observable<any> {
    return this.api.put(`/dealers/${dealerId}`, data)
  }

  /*** LOGO */
  updateLogo(dealerId, data, content_type): Observable<any> {
    // return this.api.put(`/dealers/${dealerId}/logo`, data);
    let result = new BehaviorSubject<any>(null);

    let ext = content_type.split('/')[1];
    this.api.put(`/dealers/${dealerId}/logo`, {format: ext}).subscribe(
      res => {
        let uploadUrl = res['url'];
        let header = new HttpHeaders({
          'content-type': content_type
        });
        let options = { headers: header, responseType: 'text' };
        this.api.put_custom(uploadUrl, data, options).subscribe(
          res => {
            result.next({res: true, url: uploadUrl});
          }, err => {
            console.log(err);
            result.next({res: false});
          }
        );
      }, err => {
        result.next(false);
      }
    );

    return result;
  }

  deleteLogo(dealerId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/logo`);
  }

  updateParnter(dealerId, partnerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/partners/${partnerId}`, data);
  }

  // all sites verification duration change
  syncAllSiteVerificationDuration(dealerId, data):Observable<any> {
    return this.api.put(`/dealers/${dealerId}/verification_duration_sync`, data)
  }

  //ISP info
  getISP(dealerId, publicIP, siteId):Observable<any> {
    return this.api.get(`/dealers/${dealerId}/iplookup?ip=${publicIP}&site_id=${siteId}`)
  }

  //TTS
  createTTS(dealerId, data):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss`;
    return this.api.post(URL, data);
  }
  getTTSList(dealerId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.get(URL);
  }

  updateTTS(dealerId, TTSId, data):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/${TTSId}`;
    return this.api.put(URL, data);
  }

  deleteTTS(dealerId, TTSId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/${TTSId}`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.delete(URL);
  }

  linkTTS(dealerId, data, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/link`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.post(URL, data);
  }

  getTTS(dealerId, ttsId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/${ttsId}`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.get(URL);
  }

  uploadTTSFile(dealerId: number, format: string, description: string, fileSize: number, duration: number, partner_id?, is_default = 0): Observable<any> {
    const ttsData = {
      text: description,
      format: format,
      type: 1, // 0: text tts, 1: audio file tts, 2: google tts
      is_default: is_default,
      size: fileSize,
      playing_time: duration
    }
    if (partner_id) {
      ttsData['partner_id'] = partner_id;
    }
    return this.api.post(`/dealers/${dealerId}/ttss`, ttsData);
  }

  uploadAudioFileToS3(uploadUrl, data: any, content_type: string): Observable<any> {
    let header = new HttpHeaders({
      "content-type": content_type
    });
    let options = { headers: header, responseType: "text",reportProgress: true, observe: 'events' };
    return this.api.put_custom(uploadUrl, data, options);
  }

  getTTSFile(ttsUrl) {
    let headers = new HttpHeaders({
      // 'Content-Type': 'image/jpeg',
      // 'Accept': '*/*',
    });
    return this.api.getUrl(ttsUrl, {headers, withCredentials: false, responseType: 'blob' });
  } 

  testGoogleTTS(dealerId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/ttss/googleTest`, data);
  }

  //TTS set
  getTtsSet(dealerId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/sets`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.get(URL);
  }

  updateTtsSet(dealerId, data, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/sets`;
    if (partnerId) {
      URL += `?partner=${partnerId}`
    }
    return this.api.put(URL, data);
  }

  syncDefaultTts(dealerId, partnerId?):Observable<any> {
    let URL = `/dealers/${dealerId}/ttss/sets/sync`;
    let data = {};
    if (partnerId) {
      data['partner_id'] = partnerId;
    }
    return this.api.put(URL, data);
  }

  createAudioUploadURL(dealerId, mimeType?):Observable<any> {
    let URL = `/dealers/${dealerId}/audios/upload_info`;
    return this.api.post(URL, {mime_type: mimeType});
  }

  uploadAudioDataToCloud(audioUploadUrl?, audioData?, mimeType?):Observable<any> {
    let URL = audioUploadUrl;
    let data = audioData
    return this.api.put_with_cloud_url(URL, data, mimeType);
  }

  requestConvertAudioData(dealerId, audioUploadId?, mimeType?):Observable<any> {
    let URL = `/dealers/${dealerId}/audios/converter`;
    let data = {
      audio_upload_id: audioUploadId,
      mime_type: mimeType,
    }
    return this.api.post(URL, data);
  }

  //Currency
  getCurrencyList(dealerId, type='json', version='v1.2'): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/invoices/currency`,type, version)
  }

  // Camera Types
  getCameraTypes(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/device_brands`)
  }
  
  getIncommingNumber(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/incomming_phonenumbers`)
  }

  //Dealer Statistics
  // - for Report tabs
  dealer_stats$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_stats$w = this.dealer_stats$s.asObservable();
  dealer_stats_by_dealer$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_stats_by_dealer$w = this.dealer_stats_by_dealer$s.asObservable();
  dealer_stats_by_dealers$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_stats_by_dealers$w = this.dealer_stats_by_dealers$s.asObservable();
  dealer_stats_by_sites$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_stats_by_sites$w = this.dealer_stats_by_sites$s.asObservable();
  dealer_stats_by_devices$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealer_stats_by_devices$w = this.dealer_stats_by_devices$s.asObservable();

  getDealerStatistics(dealerId, startDate, endDate,sub_dealer_id=null, period_type=2, types=17, rank=0) {
    let url = `/dealers/${dealerId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (period_type) url += `&period_type=${period_type}`;
    if (rank != null) url += `&rank=${rank}`;
    if (sub_dealer_id) url += `&sub_dealer_id=${sub_dealer_id}`;
    return this.api.get(url).map(res => {
      this.dealer_stats$s.next(res)
      return res
    });
  }

  getStatisticsByDealer(dealerId, startDate, endDate, sub_dealer_id=null, period_type=2, types=17, rank=0) {
    let url = `/dealers/${dealerId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (period_type) url += `&period_type=${period_type}`;
    if (rank != null) url += `&rank=${rank}`;
    if (sub_dealer_id) url += `&sub_dealer_id=${sub_dealer_id}`;
    return this.api.get(url).map(res => {
      this.dealer_stats_by_dealer$s.next(res)
      return res
    });
  }

  getStatisticsByDealers(dealerId, startDate, endDate, sub_dealer_ids=null, period_type=2, types=17, rank=0) {
    let url = `/dealers/${dealerId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (period_type) url += `&period_type=${period_type}`;
    if (rank != null) url += `&rank=${rank}`;
    if (sub_dealer_ids) url += `&sub_dealer_ids=${sub_dealer_ids}`;
    return this.api.get(url).map(res => {
      this.dealer_stats_by_dealers$s.next(res)
      return res
    });
  }

  getStatisticsBySites(dealerId, startDate, endDate, site_ids=null, period_type=2, types=17, rank=0) {
    let url = `/dealers/${dealerId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (period_type) url += `&period_type=${period_type}`;
    if (rank != null) url += `&rank=${rank}`;
    if (site_ids) url += `&site_ids=${site_ids}`;
    return this.api.get(url).map(res => {
      this.dealer_stats_by_sites$s.next(res)
      return res
    });
  }

  getStatisticsByDevices(dealerId, startDate, endDate, device_ids=null, period_type=2, types=17, rank=0) {
    let url = `/dealers/${dealerId}/metrics/events?stime=${startDate}&etime=${endDate}`;
    if (types) url += `&types=${types}`;
    if (period_type) url += `&period_type=${period_type}`;
    if (rank != null) url += `&rank=${rank}`;
    if (device_ids) url += `&device_ids=${device_ids}`;
    return this.api.get(url).map(res => {
      this.dealer_stats_by_devices$s.next(res)
      return res
    });
  }
}
